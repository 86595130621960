
import * as types from "../constants";

const initialState = {
  name: '',
  texts : '',
  landlord: ''
};

export default function reducer(state = initialState, actions) {

  switch (actions.type) {
    case types.TENANT_SETTINGS:
      return {
        ...state,
        name: actions.payload.name,
        landlord : actions.payload.landlord
      };
    case types.TENANT_APP_TEXTS:
      return {
        ...state,
        texts : actions.payload,
      }

    default:
      return state;
  }
}
