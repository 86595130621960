export function toggleTheme(name) {
  detachStylesheets();
  insertStylesheet(name);
}

function detachStylesheets() {
  Array.from(document.querySelectorAll('link[rel="stylesheet"]')).forEach(
    style => {
      style.parentNode.removeChild(style);
    }
  );
}

function insertStylesheet(name) {
  var link = document.createElement("link");
  link.href = "/css/" + name + ".css";
  link.type = "text/css";
  link.rel = "stylesheet";
  document.getElementsByTagName("head")[0].appendChild(link);
}

export function toggleStyle(style) {

  insertStyle(style);
}

function detachStyle()
{
  Array.from(document.querySelectorAll('style[id="custom"]')).forEach(
    style => {
      style.parentNode.removeChild(style);
    }
  );
}
export function insertStyle(style) {
    detachStyle();
  var css = 'body { background: blue; }',
    head = document.head || document.getElementsByTagName('head')[0],
    styleElement = document.createElement('style');
    styleElement.id = 'custom'

  head.appendChild(styleElement);
  styleElement.type = 'text/css';
  if (styleElement.styleSheet) {
    // This is required for IE8 and below.
    styleElement.styleSheet.cssText = css;
  } else {
    styleElement.appendChild(document.createTextNode(css));
  }
}
