import _ from 'lodash';
const fixedColors = [
    "#496ff9",
    "#4f784d",
    "#943ec5",
    "#a8d52f",
    "#e6a300",
    "#2b4ca9",
    "#34a0ff",
    "#b92300",
    "#01d4c5",
    "#e00051",
    "#60df72",
    "#b0008f",
    "#00a439",
    "#009648",
    "#ff68d5",
    "#447f00",
    "#c08eff",
    "#e2c542",
    "#949fff",
    "#cc6500",
    "#01b6e8",
    "#e02334",
    "#8bd886",
    "#cb0068",
    "#006522",
    "#ff536e",
    "#017c4f",
    "#c1003c"]

export const _randomColor = () => {
    var hex = "#";
    const hexValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F'];
    for (var i = 0; i < 6; i++) {
        const index = Math.floor(Math.random() * hexValues.length)
        hex += hexValues[index];
    }

    return hex;
}

export const getColors = (count) => {
    const colors = fixedColors.filter((c, i) => i < count);
    if (colors.length < count) {
        const missingCount = count - colors.length

        for (var i = 0; i < missingCount; i++) {
            colors.push(_randomColor())
        }
    }
    return colors;
}

    export const flattenObject = (ob, divider = ".") => {
        let result = {};
        for (const i in ob) {
            if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
                const temp = flattenObject(ob[i]);
                for (const j in temp) {
                    result[i + divider + j] = temp[j];
                }
            }
            else {
                result[i] = ob[i];
            }
        }
        return result;
    };
    
 export const unFlattenObject = (obj) => {
    const newObj = {}
    Object.keys(obj).forEach((key) => {
        _.set(newObj, key, obj[key])
    })
    return newObj
  }

export const splitCamelCase = (text) => {
    return text.replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => { return str.toUpperCase(); })
} 

export const getProperty = (key, data, def = undefined) => {
    const _checkDefeault = (value, def) => value === null ? def : value;
    const value = _checkDefeault(_.get(data, `${key}`), def);
    return value ? value : def;
  }

export const stringFormat = (replacements, str) => {
    const output = str.replace(/\$\{(.*?)\}/g, (placeholder, name) => {
        return replacements[name] || placeholder;
    });
    return output;
}

export const CollectionItem = {
    boats: 'Boat',
    trucks: 'Truck',
    cabinets: 'Cabinet',
    trackers: "Tracker",
    facilities: "Facility",
    parcels: "Parcel",
    tanks: 'Tank',
    machines : 'Machine',
}

export const CollectionPath = {
    Boat: 'assets/boats',
    Parcel: "assets/parcels",
    Truck: 'trucks',
    Cabinet: 'cabinets',
    Tracker: "trackers",
    Facility: "facilities",
    Tank: 'tanks',
    Machine : 'machines',
}