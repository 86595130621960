import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import history from "../utils/history";
import { landing as landingRoutes, auth as authRoutes, tempRoutes } from "./default";
import mainRoutes from './main'
import adminRoutes from './_adminRoutes'

import DashboardLayout from "../layouts/Dashboard";
import LandingLayout from "../layouts/Landing";
import AuthLayout from "../layouts/Auth";
import AdminLayout from "../layouts/Admin";
import Page404 from "../pages/auth/Page404";

import ScrollToTop from "../components/ScrollToTop";
import Loader from "../components/Loader";
import TenantTheme from "../tenants/_base/Themes/TenantTheme";
import { I18n } from 'react-polyglot';
import { default as applicationTexts } from '../tenants/_base/Text/ApplicationTexts';
import PrivateRoute from "../components/Youmoni/PrivateRoute";
import { useApiClient } from '../utils/api';
import { PermissionService } from "../services/permissionService";
import { SettingsProvider, useSettings } from "../utils/settings";
import Landing from "../tenants/_base/Landing/Landing";
import _ from 'lodash';
import { notificationRoutes } from "./_notificationRoutes";
import { deviceRoutes } from "./_deviceRoutes";
import { assettoRoutes } from "./_assettoRoutes";
import { bookingRoutes } from "./_bookingRoutes";
import { serviceRequestsRoutes } from "./_serviceRequestRoutes";
import { locationRoutes } from "./_locationRoutes";
import { hasAccess, ACTION, MATCHER } from "../utils/accessControl";

const locale = window.locale || 'en';
const childRoutes = (Layout, routes, props) => {
  const { tenant, landlord } = props !== undefined && Object.keys(props).length > 0 ? props : {}
  return routes.map(({ children, path, facets, component: Component }, index) =>
    children ? (children.map(({ path, facets, component: Component }, index) => (
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout navigation={routes}>
            <ChildSettingsWrapperComponent {...props} component={Component} facets={facets} tenant={tenant} landlord={landlord} />
          </Layout>
        )}
      />
    ))
    ) : (<Route
      key={index}
      path={path}
      exact
      render={props => (
        <Layout navigation={routes}>
          <ChildSettingsWrapperComponent {...props} component={Component} facets={facets} tenant={tenant} landlord={landlord} />
        </Layout>
      )}
    />
    )
  );
}

const privateRoutes = (Layout, routes, rest) => {
  const { isAdmin, isAuthenticated, apiClient, tenant, landlord, navigation } = rest
  return routes.map(({ children, path, facets, component: Component }, index) =>
    children ? (children.map(({ path, component: Component }, index) => (
      <PrivateRoute
        key={index}
        path={path}
        exact
        render={props => (
          <Layout isAdmin={isAdmin} tenant={tenant} landlord={landlord} isAuthenticated={isAuthenticated} navigation={navigation}>
            <PrivateSettingsWrapperComponent {...props} component={Component} facets={facets} tenant={tenant} apiClient={apiClient} />
          </Layout>
        )}
      />
    ))
    ) : (<PrivateRoute
      key={`private_route_${index}`}
      path={path}
      exact
      render={props => (
        <Layout isAdmin={isAdmin} {...props} tenant={tenant} landlord={landlord} isAuthenticated={isAuthenticated} navigation={navigation}>
          <PrivateSettingsWrapperComponent {...props} {...rest} component={Component} facets={facets} tenant={tenant} apiClient={apiClient} />
        </Layout>
      )}
    />
    )
  );
}

const PrivateSettingsWrapperComponent = (props) => {
  const { component: Component, tenant, apiClient, settings } = props;
  return <Component {...props} tenant={tenant} settings={settings} apiClient={apiClient} />
}

const ChildSettingsWrapperComponent = (props) => {
  const { component: Component, tenant, apiClient, settings } = props;
  return <Component {...props} tenant={tenant} settings={settings} apiClient={apiClient} />
}

const Routes = (props) => {

  const { apiClient, isAuthenticated, settings } = props;
  const [state, setState] = useState({ isLoading: true });

  const getLoginTriggerRoutes = () => {

    const tempRoutes = []
          const route = assettoRoutes({ name: '', path: undefined, icon: undefined });
          tempRoutes.push(route);
    const _assettoRoutes = _.flatten(tempRoutes)
    const routes =  [...mainRoutes, ...locationRoutes, ..._assettoRoutes, ...notificationRoutes, ...deviceRoutes, ...serviceRequestsRoutes, ...bookingRoutes, ...adminRoutes];
    return routes
  }

  useEffect(() => {
    if (isAuthenticated && apiClient) {
      const permissionService = new PermissionService(apiClient)
      permissionService.getPermissions().then((response) => {
        const { tenant, landlord, facets, user } = response;

        const _landingRoutes = landingRoutes.map((landing) => {
          landing.facets = facets || {};
          landing.tenant = tenant;
          landing.dominoUserUid = user;
          return landing;
        });


        const tempRoutes = []
        if (settings && settings?.collections && Array.isArray(settings?.collections)) {
          const collections = Array.isArray(settings.collections) ? settings.collections : [];
          collections.filter((element) => element?.inNav).forEach((element) => {
            if (element?.collection?.id && element?.collection?.name) {
              const route = assettoRoutes({ name: element?.collection?.name, path: element?.collection?.id, icon: element.icon });
              tempRoutes.push(route);
            }
          })
        }

        const _assettoRoutes = _.flatten(tempRoutes)
        const _locationRoutes = settings?.locations ? locationRoutes : [];
        const _serviceRequestRoutes = settings?.serviceRequests ? serviceRequestsRoutes : []
        const _mainRoutes = [...mainRoutes, ..._locationRoutes, ..._assettoRoutes, ...notificationRoutes, ...deviceRoutes, ..._serviceRequestRoutes, ...bookingRoutes].map((r) => {
          r.facets = facets || {}
          r.tenant = tenant;
          r.dominoUserUid = user;
          return r;
        });


        const _availableMainRoutes = _mainRoutes?.filter((r) => ((r.hidden === false || r.hidden === undefined))) || []
        const _navigation = _availableMainRoutes?.filter((r) => hasAccess(r?.facet, ACTION.ANY, facets, MATCHER.START))        

        const _isAdmin = hasAccess('admin', ACTION.ANY, facets, MATCHER.START);        
        const _adminRoutes = []
        if (_isAdmin) {
          adminRoutes.forEach((route) => {
            
            route.tenant = tenant;
            route.facets = facets;
            route.dominoUserUid = user;
            _adminRoutes.push(route)
          });
        }



        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
            tenant: tenant,
            landlord: landlord,
            landingRoutes: _landingRoutes,
            mainRoutes: _mainRoutes,
            adminRoutes: _adminRoutes,
            isAdmin: _isAdmin,
            navigation: _navigation,
            dominoUserUid: user
          }
        })

      }).catch((error) => {
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
            tenant: window.location.host.split('.').shift(),
            landingRoutes: landingRoutes,
          }
        })
      })
    }
    else {
      setState((prevState) => {
        return {
          ...prevState,
          isLoading: false,
          tenant: window.location.host.split('.').shift(),
          landlord: window.location.host.split('.').shift()?.replace(/-.+/g, ''),
          landingRoutes: landingRoutes,
          mainRoutes: [...getLoginTriggerRoutes()],
        }
      })
    }

  }, [isAuthenticated, apiClient, settings])

  return <>
    <I18n locale={locale} messages={applicationTexts}>
      <Router history={history}>
        <TenantTheme tenant={window.location.host.split('.').shift()} landlord={state?.landlord} texts={applicationTexts}>
          {state?.isLoading && <Loader></Loader>}
          <ScrollToTop>
            <Switch>
              {childRoutes(LandingLayout, state?.landingRoutes || [], {
                tenant: state?.tenant,
                landlord: state?.landlord,
                dominoUserUid: state?.dominoUserUid
              })}
              {privateRoutes(DashboardLayout, state?.mainRoutes || [], {
                isAdmin: state?.isAdmin,
                isAuthenticated: isAuthenticated,
                apiClient: apiClient,
                tenant: state?.tenant,
                landlord: state?.landlord,
                settings:settings,
                navigation: state?.navigation || [],
                dominoUserUid: state?.dominoUserUid
              })}
              {privateRoutes(LandingLayout, tempRoutes || [], {
                isAdmin: state?.isAdmin,
                isAuthenticated: isAuthenticated,
                apiClient: apiClient,
                tenant: state?.tenant,
                landlord: state?.landlord,
                settings:settings,
                dominoUserUid: state?.dominoUserUid
              })}

              {privateRoutes(AdminLayout, state?.adminRoutes || [], {
                isAdmin: state?.isAdmin,
                isAuthenticated: isAuthenticated,
                apiClient: apiClient,
                tenant: state?.tenant,
                landlord: state?.landlord,
                settings:settings,
                navigation: state?.navigation || [],
                dominoUserUid: state?.dominoUserUid
              })}
              {childRoutes(AuthLayout, authRoutes)}
              {state?.isLoading === false && <Route
                render={() => (
                  <> 
                   {window.location.href.indexOf('/dashboard') > -1 && <Landing tenant={state?.tenant} landlord={state?.landlord} facets={{}}></Landing>}
                    {window.location.href.indexOf('/dashboard') === -1 && <AuthLayout>
                      <Page404 />
                    </AuthLayout>}
                  </>
                )}
              />}
            </Switch>
          </ScrollToTop>
        </TenantTheme>
      </Router>
    </I18n>
  </>
}

const RoutesWrapper = (props) => {
  const { apiClient, isAuthenticated } = props;
  const { isLoading, settings } = useSettings();


  return <>
    {!isLoading && <>
      {isAuthenticated === true && apiClient && <Routes {...props} apiClient={apiClient} settings={settings}></Routes>}
    </>
    }
  </>
}

const Console = (props) => {
  const { apiClient } = useApiClient();
  const config = apiClient?._tenant ? apiClient?._tenant : undefined;
  const { isAuthenticated } = props;


  if (!isAuthenticated) {
    return <Routes {...props} ></Routes>
  }
  else if (isAuthenticated && apiClient) {
    return <SettingsProvider apiClient={apiClient} config={config}>
      <RoutesWrapper apiClient={apiClient} isAuthenticated={isAuthenticated}></RoutesWrapper>
    </SettingsProvider>
  }
  return <Loader></Loader>
}

export default Console;