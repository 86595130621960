import qs from 'qs';
export class EchoService {

    constructor(apiClient) {
        this._apiClient = apiClient;
    }

    getNotifications(params = {}) {
        const query = qs.stringify(params, { skipEmptyStrings: true, arrayFormat:'comma' });
        const queryString = query !== '' ? `?${query}` : '';
        
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`echo/notifications${queryString}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getNotification(id) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`echo/notifications/${id}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }


    addNotification(body) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.post(`echo/notifications`, body).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    deleteNotification(uid) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.delete(`echo/notifications/${uid}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    updateNotification(id, body) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.patch(`echo/notifications/${id}`, body).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    updateNotifications(body) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.patch(`echo/notifications`, body).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getChannels() {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get('echo/channels').then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getChannel(id) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`echo/channels/${id}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }
    
    addChannel(body) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.post(`echo/channels`, body).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    patchChannel(id, body) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.patch(`echo/channels/${id}`, body).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    deleteChannel(id, body) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.delete(`echo/channels/${id}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }
}