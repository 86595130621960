export const texts = {
    
    //Nav
    "navigation.locations": "Location",
    "navigation.devices": "Devices",
    "navigation.notifications": "Notification",
    "navigation.dashboard" : "Dashboard",
    "navigation.subjects" : "Subjetcs",

    //Pages
    "page.dashboard.headline": "Dashboard",
    "page.dashboard.leadtext": "Use left menu to list devices an view sensor data",
    "page.dashboard.sensor.headline": "Sensor Notifications",
    "page.dashboard.device.headline": "Device Notifications",

    "page.devices.headline": "Devices",
    "page.devices.list.headline": "Devices",
    "page.devices.leadtext": "All devices registrated in youmoni.com account",

    "page.subjects.headline" : "Subjects",
    "page.subjects.leadtext": "All registrated subjects in youmoni account",
    "page.subjects.list.headline" : "Subjects",


    "page.subject.details.headline" : "Details",

    "page.locations.headline": "Locations",

    "page.operator.headline" :"Drivers",
    "page.operator.leadtext":" In listing below are all registrated drivers in your youmoni account",
    "page.operator.list.headline":"Drivers list",

    //Admin
    "page.newtenant.headline": "Add tenant",
    "page.edittenant.headline": "Edit tenant",

    "page.usermanagement.headline" : "User management",

    //ConnectedDevice
    "page.connectdevice.headline" : "Connect",
    "page.connecteddevice.subject.headline" : "Connected Subject",
    "page.connecteddevice.subject.leadtext" : "Currently connected subject to this device ",
    "page.connecteddevice.subjects.headline" : "Available Subjects",
    "page.connecteddevice.subjects.leadtext" : "Select one subject to this device",

    "page.connectsubject.headline" : "Connect",
    "page.connectedsubject.device.headline" : "Connected Devices",
    "page.connectedsubject.device.leadtext" : "Currently connected devices to this subject ",
    "page.connectedsubject.devices.headline" : "Available Devices",
    "page.connectedsubject.devices.leadtext" : "Select devices to this subject",

    "btn_show_on_map": "Show on map", 
    "btn.details": "Details",
    "btn.cancel" : "Cancel",
    "btn.save" : "Save",
    "btn.delete": "Delete",

    "placeholder.search" : "Search...",

    "data.nodata": "No results...",

    "user.added.headline" : "User added",
    "user.added.text": "The user was succefully added to your youmoni.com tenant",

    "user.deleted.headline" : "User deleted",
    "user.deleted.text": "The user was succefully deleted from your youmoni.com tenant",
}
export default texts;