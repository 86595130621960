import React from "react";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Settings from "../components/Settings";

const Admin = ({ children, isAuthenticated, tenant,landlord, navigation, isAdmin }) => {
  return (<React.Fragment>
    {
      isAuthenticated &&
      <Wrapper>
        <Sidebar tenant={tenant} landlord={landlord} routes={navigation} />
        <div className="main bg-white">
          <Navbar />
          {children}
        </div>
        {isAdmin &&
          <Settings></Settings>
        }
      </Wrapper>
    }
  </React.Fragment>
  );
}

export default Admin;
