import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Row
} from "reactstrap";
import ProfileNav from "../../../components/Youmoni/ProfileNav";
import AccessControl, { ACTION, hasAccess, MATCHER } from "../../../utils/accessControl";
import Settings from "../../../components/Settings";

const Navigation = (props) => {
  const { permissions } = props;

  const links = [
    { moduleName: 'dashboard', path: '/dashboard', name: 'Dashboard' },
    { moduleName: 'devices', path: '/devices', name: 'Devices' },
    { moduleName: 'admin', path: '/admin', name: 'Admin' }
  ];
  const linksWithPermission = links.filter((link) => hasAccess(permissions, link.moduleName, 'GET'));
  return (
    <Navbar dark expand className="navbar-landing">
      <Nav className="ml-auto" navbar>
        {
          linksWithPermission.map((link, index) => {
            return <NavItem key={`nav_item_${index}`} className="d-none d-md-inline-block">
              <NavLink tag={Link} to={`${link.path}`} active>
                {link.name}
              </NavLink>
            </NavItem>
          })
        }
        <ProfileNav></ProfileNav>
      </Nav>
    </Navbar>
  );
}

class Intro extends React.Component {
  constructor(props) {
    super(props)
    this.state = { path: undefined }
  }

  componentDidMount() {
    const { tenant, landlord } = this.props;

    const promises = []
    if (tenant) {
      promises.push(this._getTenantLogo(`/logos/${tenant}.png`));
    }

    if (landlord) {
      promises.push(this._getTenantLogo(`/logos/${landlord}.png`));
    }

    Promise.allSettled(promises).then(([tenantResponse, landlordResponse]) => {
      const isTennatSuccessfull = tenantResponse && tenantResponse.status === 'fulfilled';
      const isLandlordSuccessFull = landlordResponse && landlordResponse.status === 'fulfilled';
      const path = isTennatSuccessfull ? tenantResponse.value.path : isLandlordSuccessFull ? landlordResponse.value.path : undefined;
      if (path) {
        this.setState({ path: path });
      }
    });
  }

  _getTenantLogo = (path) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ path, ok: true })
      };
      img.onerror = () => {
        reject({ path, ok: false });
      }
      img.src = path;
    });
  }

  render() {
    const { facets } = this.props
    const { path } = this.state;
    return <section className="py-5">
      <Container>
        <Row>
          <Col md="12" lg="9" xl="12" className="mx-auto">
            <Row>
              <Col xl="5">
                {path && <span style={{ height: '50px', width: '100%', display: 'inline-block', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundImage: `url(${path})` }}></span>}
                <h1 className="text-white my-4">
                  Start monitoring your assets and devices together with Youmoni.
                </h1>
                <AccessControl
                  facets={facets}
                  facet='dashboard'
                  matcher={MATCHER.START}
                  actions={ACTION.ANY}>
                  <div className="my-4">
                    <Link
                      to="/dashboard"
                      color="light"
                      size="lg"
                      className="btn-button mr-2">
                      <Button outline={true}>
                        Dashboard
                      </Button>
                    </Link>
                  </div>
                </AccessControl>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  }
}

const Footer = () => (
  <section className="py-5">
    <Container className="text-center">
      <h2 className="mb-0">
      </h2>
    </Container>
  </section>
);

class Landing extends React.Component {
  render() {
    const { facets } = this.props;
    return (
      <React.Fragment>
        <div style={{ minHeight: '50vh' }} className="landing-intro">
          <Navigation {...this.props} />
          <Intro {...this.props} />
          <AccessControl
            facets={facets}
            facet="admin"
            matcher={MATCHER.START}
            actions={ACTION.ANY}>
            <Settings></Settings>
          </AccessControl>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default connect(store => ({
}))(Landing)
