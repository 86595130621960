export class ResourceService {
  constructor(apiClient) {
    this._apiClient = apiClient;
  }
  getResource(url) {
    return new Promise((resolve, reject) => {
      this._apiClient.get(`${url}`).then((response) => {
        if (response.ok) {
          return response;
        }
        else {
          reject(response.status)
        }
      })
        .then(response => response.blob())
        .then(blob => {
          resolve({
            type: blob.type,
            src: URL.createObjectURL(blob)
          })

        })
        .catch(err => { reject(err) });
    })
  }

  getResourceData(url) {
    return new Promise((resolve, reject) => {
      this._apiClient.get(`${url}`).then((response) => {
        if (response.ok) {
          return response;
        }
        else {
          reject(response.status)
        }
      })
        .then(response => response.blob())
        .then(blob => {

          const fr = new FileReader()
          fr.onload = (evt) => {
            resolve({
              data: evt.currentTarget.result
            })
          }

          fr.onerror = (evt) => {
            reject(undefined)
          }

          fr.readAsText(blob)
        })
        .catch(err => { reject(err) });
    })
  }
}