import React from "react";
import async from "../components/Async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";

const Booking = async(() => import("../tenants/_base/Booking/Booking"));

const routes = [{
    path: "/booking",
    name: "Bookings",
    icon: <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>,
    component: Booking,
    facet:'booking'
  },
]

 export const bookingRoutes = routes ;