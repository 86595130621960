import React, { useState, useEffect, useContext } from 'react';
import ApiClient from '../services/_api/ApiClient'
import { useAuth0 } from './auth0';

export const ApiContext = React.createContext();
export const useApiClient = () => useContext(ApiContext);
export const ApiProvider = ({
  children,
}) => {
  const [apiClient, setApiClient] = useState();
  const {getIdTokenClaims } = useAuth0();

  useEffect(() => {
    const initApi = async () => {
      getIdTokenClaims().then((response) => {
        if (response) {
          let apiKey = response.__raw;
          if (window.location.host.indexOf('.') > -1) {
            let tenant = window.location.host.substr(0, window.location.host.indexOf('.'));
            if (tenant) {
              const apiFromHook = new ApiClient(apiKey, tenant);
              setApiClient(apiFromHook);
            }
          }
          else {
            const apiFromHook = new ApiClient(apiKey, 'root');
            setApiClient(apiFromHook);
          }
        }
      });

    };
    initApi();
  }, [getIdTokenClaims]);

  return (
    <ApiContext.Provider
      value={{ apiClient }}>
      {children}
    </ApiContext.Provider>
  );
};