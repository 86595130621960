import { faColumns, faEllipsisH, faFileDownload, faGripHorizontal, faArrowUpLong, faArrowDownLong, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, useRef } from 'react'
import { useFilters, useGlobalFilter, useAsyncDebounce, useTable, usePagination, useSortBy, useColumnOrder, useRowSelect } from 'react-table'
import { Col, Row, Table, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, UncontrolledCollapse, ListGroup, ListGroupItem, DropdownItem } from 'reactstrap'
import { useWindowSize } from '../../../utils/windowSize'
import RightPanel from '../RightPanel'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const PagerList = (props) => {
    const { pageCount, currentPage, nextPage, previousPage, gotoPage, canPreviousPage, canNextPage } = props;

    const getPagerPages = (pageCount, currentPage, maxPages = 5) => {

        let totalPages = pageCount
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }


        let startPage;
        let endPage;
        if (totalPages <= maxPages) {
            startPage = 1;
            endPage = totalPages;
        } else {
            // total pages more than max so calculate start and end pages
            let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
            let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
            if (currentPage <= maxPagesBeforeCurrentPage) {
                // current page near the start
                startPage = 1;
                endPage = maxPages;
            } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                // current page near the end
                startPage = totalPages - maxPages + 1;
                endPage = totalPages;
            } else {
                // current page somewhere in the middle
                startPage = currentPage - maxPagesBeforeCurrentPage;
                endPage = currentPage + maxPagesAfterCurrentPage;
            }
        }

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        return pages
    }

    const pageNumbers = getPagerPages(pageCount, currentPage, 5)
    return <ul className='pagination'>
        {canPreviousPage && pageNumbers.find((n) => n === 1) === undefined && <li className='page-item'><button className='page-link' onClick={() => gotoPage(0)}>{'<<'}</button></li>}
        {canPreviousPage && <li className='page-item'><button className='page-link' onClick={() => previousPage()}>{'<'}</button></li>}
        {pageNumbers.map((number, index) => {
            return <li key={`li_${index}`} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                <button onClick={() => gotoPage(number - 1)} className='page-link'>{number}</button>
            </li>
        })}

        {canNextPage && <li className='page-item'> <button className='page-link' onClick={() => nextPage()}>{'>'}</button></li>}
        {canNextPage && pageNumbers.find((n) => n === pageCount - 1) === undefined && <li className='page-item'> <button className='page-link' onClick={() => gotoPage(pageCount - 1)}>{'>>'}
        </button></li>}
    </ul>
}

const PagerSize = (props) => {
    const { sizes, currentPageSize, setPageSize } = props;
    return <ul className='pagination'>
        {sizes.map((size, index) => {
            return <li key={`li_${index}`} className={`page-item ${size === currentPageSize ? 'active' : ''}`}>
                <button onClick={() => setPageSize(size)} className='page-link'>{size}</button>
            </li>
        })}
    </ul>
}

const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
    return (
        <input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined)
            }}
            placeholder={`Search`}
        />
    )
}

const DefaultTable = ({ title, columns, hideHeader, data, striped, actions, hover, columnSettings, search, onRowClick, onExport, exportFormatter, pagerSizes, pagerSize, filters }) => {
    const tableFilters = filters || [];
    const tableRef = useRef(null);
    const defaultColumn = React.useMemo(() => ({
        Filter: DefaultColumnFilter,
    }), [])


    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true
            })
        }
    }), [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        allColumns,
        prepareRow,
        setColumnOrder,
        page,
        rows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        setAllFilters,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: pagerSize !== undefined && Number.isSafeInteger(pagerSize) ? pagerSize : 25  },
            defaultColumn,
            filterTypes,
            autoResetFilters: false
            
        },
        useColumnOrder,
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
    )

    const [isColumnsPanelOpen, toggleColumnsPanel] = React.useState(false);
    const [items, setItems] = React.useState(allColumns.filter((column) => (typeof column.Header === 'string')));
    const size = useWindowSize()

    const hasHover = hover !== undefined && typeof (hover) === 'boolean' ? hover : true;
    const hasSearch = search !== undefined && typeof (search) === 'boolean' ? search : true;
    const hasColumnSettings = columnSettings !== undefined && typeof (columnSettings) === 'boolean' ? columnSettings : false
    const isStriped = striped !== undefined && typeof (striped) === 'boolean' ? striped : true
    const hasHeader = hideHeader !== undefined && typeof (hover) === 'boolean' ? !hideHeader : true;


    const colSpan = columns.length > 0 ? columns[0]?.columns?.length ? columns[0].columns.length : 0 : 0;
    const tableActions = Array.isArray(actions) ? actions : [];

    const _export = () => {
        const data = [];
        const rowColumnKeys = [];
        headerGroups.forEach(headerGroup => {
            const headerRow = [];
            if (headerGroup.headers) {
                headerGroup.headers.forEach(column => {
                    if ((typeof column.Header === 'string' && column.Header !== '' && column.id !== 'image')) {
                        headerRow.push(column.Header)
                        rowColumnKeys.push(column.id)
                    }
                });
            }
            if (headerRow.length > 0)
                data.push(headerRow);

        });

        if (rows.length > 0) {
            rows.forEach((row) => {
                let rowData = [];
                rowColumnKeys.forEach((key) => {
                    if (row?.values.hasOwnProperty(key)) {
                        if (exportFormatter && exportFormatter.hasOwnProperty(key)) {
                            const value = exportFormatter[key]({ value: row.values[key] });
                            rowData.push(value);
                        }
                        else {
                            rowData.push(row.values[key]);
                        }
                    }
                })
                data.push(rowData);
            });
        }

        if (onExport) {
            onExport(data);
        }
    }

    const _reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const _onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const newitems = _reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(newitems);
        setColumnOrder(newitems.map((item) => item.id))
    }

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        background: isDragging ? "#f6f6f6" : "#fff",
        borderTop: isDragging ? "1px solid #ccc" : '1px solid #fff',
        ...draggableStyle
    });


    const _resetAllFilters = () => {
        setAllFilters([])
    }

    const _onNextPage = (props) => {
        nextPage(props)
        _scrollToTop();
    }

    const _onGoToPage = (props) => {
        gotoPage(props)
        _scrollToTop();
    }

    const _onPreviousPage = (props) => {
        previousPage(props)
        _scrollToTop();
    }

    const _scrollToTop = () => {
        if (tableRef?.current) {
            tableRef.current.scrollIntoView()
        }
    }


    return (
        <div ref={tableRef}>
            {hasColumnSettings && <RightPanel isOpen={isColumnsPanelOpen} dismiss={() => toggleColumnsPanel(!isColumnsPanelOpen)}>
                <h3>Columns Settings</h3>
                <p>Mark which columns that will be shown in the listview. If you want to change the order, drag and drop the item in your prefered order.</p>
                <DragDropContext onDragEnd={_onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <ul style={{ paddingBottom: 50 }} className='list-group list-group-flush'
                                {...provided.droppableProps}
                                ref={provided.innerRef}>
                                {items.map((column, index) => (
                                    <Draggable key={column.id} draggableId={column.id} index={index}>
                                        {(provided, snapshot) => (
                                            <li className='list-group-item'
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                                <div className='d-flex'>
                                                    <div className='flex-fill align-self-center'>
                                                        <label className='mb-0'>
                                                            <input className='mr-2 align-self-center' type="checkbox" {...column.getToggleHiddenProps()} />
                                                            {column.Header}
                                                        </label>
                                                    </div>
                                                    <div className='align-self-center' key={column.id}><FontAwesomeIcon className='float-right' icon={faGripHorizontal}></FontAwesomeIcon></div>
                                                </div>
                                            </li>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </RightPanel>}
            <Row>
                <Col>
                    {size.width > 768 &&
                        <Table striped={isStriped} responsive hover={hasHover} {...getTableProps()} style={{ tableLayout: 'auto' }}>
                            {hasHeader === true && <thead>
                                {headerGroups.map((headerGroup, index) => (
                                    <tr {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map(column => (
                                            <th {...column.getHeaderProps([{
                                                className: column.className,
                                                style: index === 0 ? { ...column.style, padding: 0, borderTopWidth: 0 } : { ...column.style }
                                            },
                                            column.getSortByToggleProps()])} >
                                                {index === 0 && <Row className='mb-1 py-1 no-gutters'>
                                                    {title && <Col xs={12} sm={{ size: 'auto' }} className='d-flex'><h4 className='mb-0 align-self-end'>{title}</h4></Col>}
                                                    {tableFilters && tableFilters?.length > 0 && <Col className='p-0'> {headerGroups.filter((h, i) => i !== 0).map((headerGroup, index) => {
                                                        return (
                                                            <span key={`group_${index}`} {...headerGroup.getHeaderGroupProps()}>
                                                                {headerGroup.headers.map((column, index) => {

                                                                    return <Fragment key={`header_${index}`}>
                                                                        {
                                                                            column.canFilter && tableFilters?.indexOf(column?.id) > -1 && <span key={`header_${index}`} className='mr-2' {...column.getHeaderProps()}>
                                                                                {column.render('Filter')}
                                                                            </span>
                                                                        }
                                                                    </Fragment>
                                                                }
                                                                )}
                                                            </span>
                                                        )
                                                    })}
                                                    {/* <Button className='shadow-none' color='primary' outline={true} onClick={() => _resetAllFilters()}><FontAwesomeIcon className='mr-2' icon={faTimes}></FontAwesomeIcon>Reset Filters</Button> */}
                                                    </Col>}
                                                    
                                                    <Col className='mr-auto text-right px-0 mx-0 mb-2 mb-sm-0'>
                                                        {tableActions.map((action, index) => {
                                                            return <Fragment key={`f_${index}`} >
                                                                <Button {...action.props ? { ...action.props } : {}} onClick={() => action.onClick()}>{action.icon} {action.text}</Button>
                                                            </Fragment>
                                                        })}
                                                        {onExport !== undefined && <Button color='primary' outline={true} onClick={() => _export()} className='ml-2'><FontAwesomeIcon className='mr-2' icon={faFileDownload}></FontAwesomeIcon>Export</Button>}
                                                        {hasColumnSettings && <Button color='primary' outline={true} onClick={() => toggleColumnsPanel(!isColumnsPanelOpen)} className='ml-2'><FontAwesomeIcon icon={faColumns}></FontAwesomeIcon></Button>}
                                                    </Col>
                                                    {hasSearch && <Col sm={{ size: 'auto' }} className='ml-auto p-0 pl-sm-2'>
                                                        <GlobalFilter
                                                            preGlobalFilteredRows={preGlobalFilteredRows}
                                                            globalFilter={globalFilter}
                                                            setGlobalFilter={setGlobalFilter}
                                                        />
                                                    </Col>}
                                                </Row>
                                                }
                                                {index !== 0 && <> {column.render('Header')}
                                                    <span className='mx-2'>
                                                        {(column.disableSortBy === false || column.disableSortBy === undefined) && <>
                                                            <FontAwesomeIcon style={{ color: column.isSorted && column.isSortedDesc ? '#000' : '#ccc' }} icon={faArrowDownLong}></FontAwesomeIcon>
                                                            <FontAwesomeIcon style={{ color: column.isSorted && column.isSortedDesc === false ? '#000' : '#ccc' }} icon={faArrowUpLong}></FontAwesomeIcon>
                                                        </>}
                                                    </span>
                                                </>
                                                }
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>}
                            <tbody {...getTableBodyProps()}>
                                {page.length === 0 && <tr>
                                    <td className='text-center' colSpan={colSpan}>No result</td></tr>}
                                {page.map((row, i) => {

                                    prepareRow(row);
                                    const _onClick = onRowClick ? onRowClick : (row) => { }
                                    const { original, index } = row;
                                    return (
                                        <tr {...row.getRowProps()} style={{ cursor: hasHover ? 'pointer' : 'auto' }} onClick={() => _onClick(original)}>
                                            {row.cells.map(cell => {
                                                const className = hasHeader ? { className: 'border-top border-1' } : index === 0 ? { className: 'border-0' } : {}
                                                return (
                                                    <td {...cell.getCellProps()} {...className}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </Table>}
                    {size.width < 768 &&
                        <>
                            <Row className='no-gutters'>
                                <Col className='mb-2'>
                                    <GlobalFilter
                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                        globalFilter={globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                    />
                                </Col>
                                {((Array.isArray(tableActions) && tableActions.length > 0) || (onExport !== undefined) || (hasColumnSettings)) &&
                                    <Col xs={{ size: 'auto' }} className="ml-2">
                                        <UncontrolledDropdown>
                                            <span className="d-sm-inline-block">
                                                <DropdownToggle color='primary' className='shadow-none' outline={true}>
                                                    <FontAwesomeIcon icon={faEllipsisH}></FontAwesomeIcon>
                                                </DropdownToggle>
                                            </span>
                                            <DropdownMenu right>
                                                {tableActions.map((action, index) => {
                                                    return <DropdownItem onClick={() => action.onClick()} key={`b_${index}`}>{action.icon} {action.text}</DropdownItem>
                                                })}
                                                {onExport !== undefined && <DropdownItem color='primary' onClick={() => _export()}> <FontAwesomeIcon className='mr-2' icon={faFileDownload}></FontAwesomeIcon> Export</DropdownItem>}
                                                {hasColumnSettings && <DropdownItem color='primary' onClick={() => toggleColumnsPanel(!isColumnsPanelOpen)}> <FontAwesomeIcon className='mr-2' icon={faColumns}></FontAwesomeIcon> Change Columns</DropdownItem>}
                                            </DropdownMenu>

                                        </UncontrolledDropdown>
                                    </Col>
                                }
                            </Row>
                            <Row><Col>
                                {headerGroups.map((headerGroup, index) => (
                                    <div {...headerGroup.getHeaderGroupProps()} >
                                        {index === 0 && <Button className='mb-2 shadow-none' id="toggler" block outline={true} color='primary'>
                                            <FontAwesomeIcon icon={faArrowDownLong}></FontAwesomeIcon>
                                            <FontAwesomeIcon className='mr-2' icon={faArrowUpLong}></FontAwesomeIcon>
                                            Sorting
                                        </Button>}
                                        {index !== 0 && <UncontrolledCollapse toggler="#toggler">
                                            <ListGroup className='mb-2'>
                                                {headerGroup.headers.filter((column) => (column.disableSortBy !== true)).map((column, colIndex) => {
                                                    return (
                                                        <ListGroupItem key={`sort_${colIndex}`} className={'cursor-pointer'}>

                                                            <div {...column.getHeaderProps(column.getSortByToggleProps())} style={column.style}>
                                                                {index !== 0 && column.Header !== '' && <>
                                                                    <span className='mx-2 w-100 flex-fill cursor-pointer'>
                                                                        {(column.disableSortBy === false || column.disableSortBy === undefined) && <>
                                                                            <FontAwesomeIcon style={{ color: column.isSorted && column.isSortedDesc ? '#000' : '#ccc' }} icon={faArrowDownLong}></FontAwesomeIcon>
                                                                            <FontAwesomeIcon style={{ color: column.isSorted && column.isSortedDesc === false ? '#000' : '#ccc' }} icon={faArrowUpLong}></FontAwesomeIcon>
                                                                        </>}
                                                                    </span>
                                                                    {column.render('Header')}
                                                                </>
                                                                }
                                                            </div>

                                                        </ListGroupItem>
                                                    )
                                                })}
                                            </ListGroup>
                                        </UncontrolledCollapse>}
                                    </div>
                                ))}
                            </Col></Row>
                            <Table striped={isStriped}>
                                <tbody>
                                    {page.map((row, i) => {
                                        const { original } = row;
                                        const _onClick = onRowClick ? onRowClick : (row) => { }
                                        prepareRow(row);
                                        const cells = [...row.cells.filter((cell) => cell.column.id === 'controls'), ...row.cells.filter((cell) => cell.column.id !== 'controls')];
                                        return <tr {...row.getRowProps()} style={{ cursor: 'pointer' }} onClick={() => _onClick(original)}>
                                            <td>
                                                {cells.map((cell, index) => {
                                                    return (cell.column.id === 'mainImage' || cell.column.id === 'image' || cell.column.id === 'controls') ? <Row className='no-gutters' {...cell.getCellProps()}>
                                                        <Col className='mb-2'>{cell.render('Cell')}</Col>
                                                    </Row>
                                                        : <Row className='no-gutters' {...cell.getCellProps()}>
                                                            <Col xs={{ size: 4 }}><strong>{typeof cell?.column?.Header === 'string' ? cell?.column?.Header : ''}</strong></Col>
                                                            <Col xs={{ size: 'auto' }}>{cell.render('Cell')}</Col>
                                                        </Row>
                                                })}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </>
                    }
                </Col>
            </Row>
            {page && Array.isArray(page) && page.length > 0 && <Row>
                <Col sm={{ size: 'auto' }} className='mr-auto'>
                    <PagerSize sizes={Array.isArray(pagerSizes) ? pagerSizes : [10, 25, 50]} currentPageSize={pageSize} setPageSize={(size) => setPageSize(size)}></PagerSize>
                </Col>
                <Col sm={{ size: 'auto' }} className='text-right ml-auto'>
                    <PagerList
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageCount={pageCount}
                        gotoPage={(props) => _onGoToPage(props)}
                        nextPage={(props) => _onNextPage(props)}
                        previousPage={(props) => _onPreviousPage(props)}
                        pageSize={pageSize}
                        currentPage={pageIndex + 1}></PagerList>
                </Col>
            </Row>}
        </div>
    )
}

const SelectTable = ({ title, columns, hideHeader, data, striped, actions, hover, columnSettings, search, onRowClick, pagerSizes, pagerSize, onChecked, filters }) => {
    const tableFilters = filters || [];
    const tableRef = useRef(null);
    const defaultColumn = React.useMemo(() => ({
        Filter: DefaultColumnFilter,
    }), [])


    const filterTypes = React.useMemo(() => ({
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true
            })
        }
    }), [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter, selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: pagerSize !== undefined && Number.isSafeInteger(pagerSize) ? pagerSize : 25 },
            defaultColumn,
            filterTypes
        },
        useColumnOrder,
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columns => [
                {
                    id: 'selection',
                    disableSortBy: true,
                    disableGlobalFilter: true,
                    disableFilters: true,
                    style: { width: 20 },
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (

                        <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                    ),
                    Cell: ({ row }) => (

                        <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onClick={(e) => e.stopPropagation()} />
                    ),
                },
                ...columns,
            ])
        }
    )

    React.useEffect(() => {
        const selectedIds = Object.keys(selectedRowIds).map((key) => (Number(key)));
        onChecked?.(selectedIds)


    }, [
        onChecked,
        selectedRowIds
    ]);

    const size = useWindowSize()

    const hasHover = hover !== undefined && typeof (hover) === 'boolean' ? hover : true;
    const hasSearch = search !== undefined && typeof (search) === 'boolean' ? search : true;

    const isStriped = striped !== undefined && typeof (striped) === 'boolean' ? striped : true
    const hasHeader = hideHeader !== undefined && typeof (hover) === 'boolean' ? !hideHeader : true;


    const colSpan = columns.length > 0 ? columns[0]?.columns?.length ? columns[0].columns.length + 1 : 0 : 0;
    const tableActions = Array.isArray(actions) ? actions : [];

    const _onNextPage = (props) => {
        nextPage(props)
        _scrollToTop();
    }

    const _onGoToPage = (props) => {
        gotoPage(props)
        _scrollToTop();
    }

    const _onPreviousPage = (props) => {
        previousPage(props)
        _scrollToTop();
    }

    const _scrollToTop = () => {
        if (tableRef?.current) {
            tableRef.current.scrollIntoView()
        }
    }


    return (
        <div ref={tableRef}>
            <Row>
                <Col>
                    {size.width > 768 &&
                        <Table striped={isStriped} responsive hover={hasHover} {...getTableProps()} style={{ tableLayout: 'auto' }}>
                            {hasHeader === true && <thead>
                                {headerGroups.map((headerGroup, index) => (
                                    <tr {...headerGroup.getHeaderGroupProps()} >
                                        {headerGroup.headers.map((column, i) => {
                                            return (
                                                <Fragment key={`fr_${i}`}>
                                                    {
                                                        index === 0 && column?.originalId !== 'selection_placeholder' && <th style={{ paddingLeft: 0, paddingRight: 0 }} colSpan={colSpan}>
                                                            <Row className='no-gutters'>
                                                                {(column?.originalId !== 'selection_placeholder') && <>
                                                                    {tableFilters && tableFilters?.length > 0 && <Col className='px-0'> {headerGroups.filter((h, i) => i !== 0).map((headerGroup, index) => {
                                                                        return (
                                                                            <span key={`group_${index}`} {...headerGroup.getHeaderGroupProps()}>
                                                                                {headerGroup.headers.map((column, index) => {

                                                                                    return <Fragment key={`header_${index}`}>
                                                                                        {
                                                                                            column.canFilter && tableFilters?.indexOf(column?.id) > -1 && <span key={`header_${index}`} className='mr-2' {...column.getHeaderProps()}>
                                                                                                {column.render('Filter')}
                                                                                            </span>
                                                                                        }
                                                                                    </Fragment>
                                                                                }
                                                                                )}
                                                                            </span>
                                                                        )
                                                                    })}</Col>}
                                                                    <Col className='mr-auto text-right px-0 mx-0 mb-2 mb-sm-0'>
                                                                        {tableActions.map((action, index) => {
                                                                            return <Fragment key={`f_${index}`} >
                                                                                <Button {...action.props ? { ...action.props } : {}} onClick={() => action.onClick()}>{action.icon} {action.text}</Button>
                                                                            </Fragment>
                                                                        })}
                                                                    </Col></>}
                                                                {hasSearch && (column?.originalId !== 'selection_placeholder') && <Col sm={{ size: 'auto' }} className='ml-auto p-0 pl-sm-2'>

                                                                    <GlobalFilter
                                                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                                                        globalFilter={globalFilter}
                                                                        setGlobalFilter={setGlobalFilter}
                                                                    />
                                                                </Col>}
                                                            </Row>
                                                        </th>
                                                    }

                                                    {index !== 0 && <th {...column.getHeaderProps([{
                                                        className: column.className,
                                                        style: index === 0 ? { ...column.style, paddingBottom: 0, paddingRight: 0, borderTopWidth: 0, width: 0 } : { ...column.style }
                                                    },
                                                    column.getSortByToggleProps()])}>

                                                        <> {column.render('Header')}
                                                            <span className='mx-2'>
                                                                {(column.disableSortBy === false || column.disableSortBy === undefined) && <>
                                                                    <FontAwesomeIcon style={{ color: column.isSorted && column.isSortedDesc ? '#000' : '#ccc' }} icon={faArrowDownLong}></FontAwesomeIcon>
                                                                    <FontAwesomeIcon style={{ color: column.isSorted && column.isSortedDesc === false ? '#000' : '#ccc' }} icon={faArrowUpLong}></FontAwesomeIcon>
                                                                </>}
                                                            </span>
                                                        </>
                                                    </th>}
                                                </Fragment>
                                            )
                                        })}
                                    </tr>
                                ))}
                            </thead>}
                            <tbody {...getTableBodyProps()}>
                                {page.length === 0 && <tr>
                                    <td className='text-center' colSpan={colSpan}>No result</td></tr>}
                                {page.map((row, i) => {

                                    prepareRow(row);
                                    const _onClick = onRowClick ? onRowClick : (row) => { }
                                    const { original, index } = row;
                                    return (
                                        <tr {...row.getRowProps()} style={{ cursor: hasHover ? 'pointer' : 'auto' }} onClick={() => _onClick(original)}>
                                            {row.cells.map(cell => {
                                                const className = hasHeader ? { className: 'border-top border-1' } : index === 0 ? { className: 'border-0' } : {}
                                                return (
                                                    <td {...cell.getCellProps()} {...className}>{cell.render('Cell')}</td>
                                                )
                                            })}
                                        </tr>
                                    )
                                }
                                )}
                            </tbody>
                        </Table>}
                    {size.width < 768 &&
                        <>
                            <Row className='no-gutters'>
                                <Col className='mb-2'>
                                    <GlobalFilter
                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                        globalFilter={globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                    />
                                </Col>
                                {(Array.isArray(tableActions) && tableActions.length > 0) &&
                                    <Col xs={{ size: 'auto' }} className="ml-2">
                                        <UncontrolledDropdown>
                                            <span className="d-sm-inline-block">
                                                <DropdownToggle color='primary' className='shadow-none' outline={true}>
                                                    <FontAwesomeIcon icon={faEllipsisH}></FontAwesomeIcon>
                                                </DropdownToggle>
                                            </span>
                                            <DropdownMenu right>
                                                {tableActions.map((action, index) => {
                                                    return <DropdownItem onClick={() => action.onClick()} key={`b_${index}`}>{action.icon} {action.text}</DropdownItem>
                                                })}
                                            </DropdownMenu>

                                        </UncontrolledDropdown>
                                    </Col>
                                }
                            </Row>
                            <Row><Col>
                                {headerGroups.map((headerGroup, index) => (
                                    <div {...headerGroup.getHeaderGroupProps()} >
                                        {index === 0 && <Button className='mb-2 shadow-none' id="toggler" block outline={true} color='primary'>
                                            <FontAwesomeIcon icon={faArrowDownLong}></FontAwesomeIcon>
                                            <FontAwesomeIcon className='mr-2' icon={faArrowUpLong}></FontAwesomeIcon>
                                            Sorting
                                        </Button>}
                                        {index !== 0 && <UncontrolledCollapse toggler="#toggler">
                                            <ListGroup className='mb-2'>
                                                {headerGroup.headers.filter((column) => (column.disableSortBy !== true)).map((column, colIndex) => {
                                                    return (
                                                        <ListGroupItem key={`sort_${colIndex}`} className={'cursor-pointer'}>

                                                            <div {...column.getHeaderProps(column.getSortByToggleProps())} style={column.style}>
                                                                {index !== 0 && column.Header !== '' && <>
                                                                    <span className='mx-2 w-100 flex-fill cursor-pointer'>
                                                                        {(column.disableSortBy === false || column.disableSortBy === undefined) && <>
                                                                            <FontAwesomeIcon style={{ color: column.isSorted && column.isSortedDesc ? '#000' : '#ccc' }} icon={faArrowDownLong}></FontAwesomeIcon>
                                                                            <FontAwesomeIcon style={{ color: column.isSorted && column.isSortedDesc === false ? '#000' : '#ccc' }} icon={faArrowUpLong}></FontAwesomeIcon>
                                                                        </>}
                                                                    </span>
                                                                    {column.render('Header')}
                                                                </>
                                                                }
                                                            </div>

                                                        </ListGroupItem>
                                                    )
                                                })}
                                            </ListGroup>
                                        </UncontrolledCollapse>}
                                    </div>
                                ))}
                            </Col></Row>
                            <Table striped={isStriped}>
                                <tbody>
                                    {page.map((row, i) => {
                                        const { original } = row;
                                        const _onClick = onRowClick ? onRowClick : (row) => { }
                                        prepareRow(row);
                                        const cells = [...row.cells.filter((cell) => cell.column.id === 'controls'), ...row.cells.filter((cell) => cell.column.id !== 'controls')];
                                        return <tr {...row.getRowProps()} style={{ cursor: 'pointer' }} onClick={() => _onClick(original)}>
                                            <td>
                                                {cells.map((cell, index) => {
                                                    return (cell.column.id === 'mainImage' || cell.column.id === 'image' || cell.column.id === 'controls') ? <Row className='no-gutters' {...cell.getCellProps()}>
                                                        <Col className='mb-2'>{cell.render('Cell')}</Col>
                                                    </Row>
                                                        : <Row className='no-gutters' {...cell.getCellProps()}>
                                                            <Col xs={{ size: 4 }}><strong>{typeof cell?.column?.Header === 'string' ? cell?.column?.Header : ''}</strong></Col>
                                                            <Col xs={{ size: 'auto' }}>{cell.render('Cell')}</Col>
                                                        </Row>
                                                })}
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </>
                    }
                </Col>
            </Row>
            {page && Array.isArray(page) && page.length > 0 && <Row>
                <Col sm={{ size: 'auto' }} className='mr-auto'>
                    <PagerSize sizes={Array.isArray(pagerSizes) ? pagerSizes : [10, 25, 50]} currentPageSize={pageSize} setPageSize={(size) => setPageSize(size)}></PagerSize>
                </Col>
                <Col sm={{ size: 'auto' }} className='text-right ml-auto'>
                    <PagerList
                        canPreviousPage={canPreviousPage}
                        canNextPage={canNextPage}
                        pageOptions={pageOptions}
                        pageCount={pageCount}
                        gotoPage={(props) => _onGoToPage(props)}
                        nextPage={(props) => _onNextPage(props)}
                        previousPage={(props) => _onPreviousPage(props)}
                        pageSize={pageSize}
                        currentPage={pageIndex + 1}></PagerList>
                </Col>
            </Row>}
        </div>
    )
}

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (<input
        style={{ lineHeight: '16px' }}
        className="form-control rounded bg-white w-100"
        type="text"
        placeholder={'Search'}
        value={value || ""}
        onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
        }}
    />
    )
}


const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)

export const TableComponent = (props) => {
    const { columns, data, rowEvents, type } = props
    const { onClick } = rowEvents !== undefined ? rowEvents : { onClick: () => { } }
    const tableColumns = React.useMemo(
        () => [
            {
                id: "ym",
                columns: columns
            },
        ],
        [columns]
    )

    return (type === 'select' ? <SelectTable {...props} columns={tableColumns} data={data} onRowClick={(row) => { onClick(row) }} /> : <DefaultTable {...props} columns={tableColumns} data={data} onRowClick={(row) => { onClick(row) }} />)
}

export const getResourceData = (key, data, resources) => {
    const loop = (path, data) => {
        if (path?.length === 0 || !data) return data;
        else {
            const head = path?.shift();
            let value = data?.[head];
            if (data?.hasOwnProperty(head) && typeof value === 'string' && value.startsWith('yri:')) {
                const yri = value.replace(/#.+/g, '');
                const resourceData = resources?.hasOwnProperty(yri) ? resources?.[yri] : {};
                return loop(path, resourceData);
            } else {
                return loop(path, value);
            }
        }
    }
    return loop(key?.split('.'), data);
}


export default TableComponent;