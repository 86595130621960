
import qs from 'qs';
import {find, isString} from 'lodash';
import { getProperty } from '../utils/uiHelper';
const _ = {
    find:find,
    isString:isString,
}
export class AssettoService {

    constructor(apiClient,collection) {
        this._apiClient = apiClient;
        this._collection = collection;
    }

    getAssets(queryObject = {}) {
        let promise = new Promise((resolve, reject) => {
            const queryString = Object.keys(queryObject).length > 0 ? `?${qs.stringify(queryObject, { arrayFormat:'comma', skipEmptyStrings: true })}` : '';
            this._apiClient.get(`assetto/${this._collection}${queryString}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response?.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getAsset(id, queryObject = {}) {
        let promise = new Promise((resolve, reject) => {
            const queryString = Object.keys(queryObject).length > 0 ? `?${qs.stringify(queryObject, { arrayFormat:'comma', skipEmptyStrings: true })}` : '';
            this._apiClient.get(`assetto/${this._collection}/${id}${queryString}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getAssetNavigation(queryObject = {}, collections = []) {

       const _mapChildren = (collections, entity, resources, identifier) => {
            const key = _.find(collections, (item) => item.collection?.id === entity.collection)?.item?.id;
            const propKey = key ? `@${key}` : undefined;
            const propValue = getProperty(propKey, entity, []);
            const yris = Array.isArray(propValue) ? propValue : isString(propValue) ? [propValue] : [];
            const childrenResources = yris.map((yri) => {
                return { ...resources[yri], hierarchy: `${identifier}|${yri}` };
            });

            let children = {};
            childrenResources.forEach((child) => {
                children[child?.['@identifier']] = _mapChildren(collections, child, resources, identifier + '|' + child?.['@identifier'])
            })

            const settingsItem = _.find(collections, (item) => item.collection?.id === entity.collection);
            const extraParams = settingsItem !== undefined ? { icon: settingsItem.icon } : {};

            return Object.assign({}, { ...entity }, Object.keys(children).length > 0 ? { children: children } : {}, extraParams);
        }

        const queryString = Object.keys(queryObject).length > 0 ? `?${qs.stringify(queryObject, { skipEmptyStrings: true })}` : '';
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`assetto/${this._collection}${queryString}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                const entities = result.entities ? result.entities : [];
                const resources = result?.resources ? result?.resources : {};
                let children = {};
                entities.forEach((entity) => {
                    children[entity?.['@identifier']] = _mapChildren(collections, entity, resources, entity?.['@identifier']);
                })

                const output = {
                    tree: children,
                    resources:resources
                }
                resolve(output);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    saveAsset(requestBody) {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}`;
            let body = requestBody;
            this._apiClient.post(url, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {   
                reject(undefined);
            });
        })
        return promise;
    }

    createAsset(requestBody) {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}`;
            let body = requestBody;
            this._apiClient.postMultiPart(url, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {   
                reject(undefined);
            });
        })
        return promise;
    }

    updateAsset(id, requestBody) {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}`;
            let body = requestBody;
            this._apiClient.put(url, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {
                reject(undefined);
            });
        })
        return promise;
    }

    patchAsset(id, requestBody) {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}`;
            let body = requestBody;
            this._apiClient.patch(url, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {
                reject(undefined);
            });
        })
        return promise;
    }

    patchAssetProperties(id, requestBody) {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}/@/properties`;
            let body = requestBody;
            this._apiClient.patch(url, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {
                reject(undefined);
            });
        })
        return promise;
    }

    connectDevice(id, body) {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}/connect`;
            this._apiClient.post(url, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {   
                reject(undefined);
            });
        })
        return promise;
    }

    disconnectDevice(id, body) {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}/disconnect`;
            this._apiClient.post(url, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {   
                reject(undefined);
            });
        })
        return promise;
    }

    deleteAsset(id) {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}`;
            this._apiClient.delete(url)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    getAssetResources(id, path) {
        let promise = new Promise((resolve, reject) => {
            const resourcePath = path !== undefined && path !== '' ? `/${path}` : '';
            let url = `assetto/${this._collection}/${id}/@/resources${resourcePath}`;
            this._apiClient.get(url).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {
                reject(undefined);
            });
        })
        return promise;
    }

    addAssetResources(id,data) {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}/@/resources`;
            let body = data;
            this._apiClient.postMultiPart(url, body)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    deleteAssetResource(id, path = '') {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}/@/resources${path}`;
            this._apiClient.delete(url)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    getLastLocations() {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/locations/dashboard`;
            this._apiClient.get(url).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {   
                reject(undefined);
            });
        })
        return promise;
    }

    addTimelineEntry(id,requestBody) {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}/@/timeline`;
            let body = requestBody;
            this._apiClient.post(url, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {   
                reject(undefined);
            });
        })
        return promise;
    }

    updateTimelineEntry(assetId, timelineId, requestBody) {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${assetId}/@/timeline/${timelineId}`;
            let body = requestBody;
            this._apiClient.put(url, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {   
                reject(undefined);
            });
        })
        return promise;
    }


    getTimeline = (id, queryObject = null) => {
        const query = queryObject !== null && Object.keys(queryObject).length > 0  ? `?${qs.stringify(queryObject, { skipEmptyStrings: true,arrayFormat:'comma' })}` : '';
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}/@/timeline${query}`;
            this._apiClient.get(url).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {   
                reject(error);
            });
        })
        return promise;
    }

    getLinks = (id, path = '', params = {}) => {
        const query = qs.stringify(params, { skipEmptyStrings: true });
        const queryString = query !== '' ? `?${query}` : '';
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}/@/links${path}${queryString}`;
            this._apiClient.get(url).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {
                reject(error);
            });
        })
        return promise;
    }

    addLink = (id, path, value) => {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}/links${path}`;
            this._apiClient.put(url, { value: value }).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {
                reject(error);
            });
        })
        return promise;
    }

    deleteLink = (id, path) => {
        let promise = new Promise((resolve, reject) => {
            let url = `assetto/${this._collection}/${id}/links/${path}`;
            this._apiClient.delete(url).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {
                reject(error);
            });
        })
        return promise;
    }


}