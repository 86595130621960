import React from 'react';
import Toastr from '../../../components/Youmoni/Toastr';
import { AssettoService } from '../../../services/assettoService';
import { FormsService } from '../../../services/formsService';
import { validateFormInputs } from '../../../utils/formValidator';
import { Alert, Button, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import FormField from '../../../components/Youmoni/FormFields/FormField';
import Loader from '../../../components/Loader';
import { faFile, faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getProperty } from '../../../utils/uiHelper';


const field = {
    type: "file",
    name: "images",
    label: 'Images',
    placeholder: "",
    invalid: false,
    multi: true,
    required: false,
    requiredMessage: "Field is required"
}

const imageMimeTypes = ['image/apng',
    'image/bmp',
    'image/gif',
    'image/x-icon',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp']

const AddAssetMenu = (props) => {
    const { onSave, onCancel} = props;
    return <Nav className='px-0 nav-buttons'>
        {onSave && <NavItem color={'primary'}>
            <NavLink onClick={() => { onSave() }}>
                <FontAwesomeIcon className="mr-2" icon={faSave}></FontAwesomeIcon> <span></span> Save
            </NavLink>
        </NavItem>
        }
     
        {onCancel && <NavItem color={'primary'}>
            <NavLink onClick={() => { onCancel() }}>
                <FontAwesomeIcon className="mr-2" icon={faTimes}></FontAwesomeIcon> <span></span> Cancel
            </NavLink>
        </NavItem>}
    </Nav>
}

export class AddServiceRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            fields: [],
            data: {},
            fieldErrors: {},
            files:[],
            isFileUploadModalOpen:false
        }

        this._assettoService = new AssettoService(props.apiClient, 'service-requests');
        this._formsService = new FormsService(props.apiClient);
    }

    componentDidMount() {
        this._initComponent();
    }

    _setInitialData = (fields) => {
        let data = {};
        fields.forEach((field) => {
            data[field.name] = field.type === 'keyvalue' ? [{ key: '', value: '' }] : field.type === 'object' ? this._setInitialData(field?.fields)  : ''
        });
        return data;
    }

    _initComponent = () => {

        this._formsService.getForms('service-requests').then((response) => {
            const fields = Array.isArray(response.newForm) ? response.newForm : [];
            let data = this._setInitialData(fields)

            data.severity = this.props?.notification?.severity || '';
            data.title = this.props?.notification?.title || '';
            data.description = this.props?.notification?.description || '';
            
            if(this.props?.notification)
            {
                data.description = this.props?.notification
            }

            fields?.filter((field) => field?.type === 'hidden').forEach((field) => {
                const fieldValue = getProperty(field.key, this?.props?.currentAsset)
                if(fieldValue !== undefined)
                {
                    data[field.name] = fieldValue;
                }
            })

            this.setState({ isLoading: false, data: data, fields: fields });

        }).catch((error) => {
            Toastr("error", "Error", "Something went wrong, please try again later.")
        });
    }

    _onValueChange = (fieldName, newValue) => {
        const { fields } = this.state;
        const currentField = fields.filter((fieldItem) => fieldItem.name === fieldName)[0];
        const fieldError = currentField.required && !newValue ? { invalid: true, message: currentField.requiredMessage ? currentField.requiredMessage : 'Field is required' } : { invalid: false, message: '' };
        this.setState((prevState) => {
            return {
                ...prevState,
                data: { ...prevState.data, [fieldName]: newValue },
                fieldErrors: { ...prevState.fieldErrors, [fieldName]: fieldError }
            }
        });
    }

    _getBodyRequest = (body) => {
        const { files } = this.state;
        let formData = new FormData();
        const { currentAsset } = this.props;
        Object.keys(body).forEach((key) => {
            if (typeof body[key] === 'object' && body[key] !== null) {
                Object.keys(body[key]).forEach((objkey) => {
                    formData.append(`${key}[${objkey}]`, body[key]?.[objkey] || "")
                })
            }
            else {
                formData.append(key, body[key])
            }
        })

        const formfiles = [...files];
        formfiles.forEach((file) => {
            formData.append('resources', file.file)
        })

        formData.append('assetIdentifier', `${currentAsset?.['@identifier']}#service-request`)
        formData.append('qualifier', `assetto.${currentAsset?.collection}.servicerequest.${body?.category}`)
        formData.append("status", 'Created')
        return formData;
    }


    _onChangeFiles = (fileList) => {
        let files = [...fileList];
        this.setState({files:files});
    }
    
    _onSave = () => {
        const { onDismiss, onSuccess,} = this.props;
        const { data, fields } = this.state;
        
        const form = validateFormInputs(data, fields);

        if (!form.isValid) {
            this.setState((prevState) => {
                return { ...prevState, fieldErrors: form.errors }
            });
            return;
        }

        let formData = this._getBodyRequest(form.data);
        
        this.setState({ isLoading: true });
        this._assettoService.createAsset(formData).then((response) => {
            this.setState({ isLoading: false });
            if (onDismiss) {
                onDismiss();
            }
            if (onSuccess) {
                onSuccess();
            }
        }).catch((error) => {
            console.log(error);
            this.setState({ isLoading: false });
            Toastr("error", "Error", "Something went wrong, please try again later.")
        });
    }

    _onDismiss = () => {
        const { onDismiss } = this.props;
        if (onDismiss) {
            onDismiss()
        }
    }



    _onAddFiles = (files) => {
        let selectedfiles = [...files];
        if (selectedfiles && selectedfiles.length > 0) {
            selectedfiles.forEach((file, index) => {
                var reader = new FileReader();
                reader.onload = (e) => {
                    const file = {
                        src: e.target.result,
                        file: selectedfiles[index]
                    }

                    this.setState((prevState) => ({ files: [...prevState.files, file] }));
                }
                reader.readAsDataURL(file); // convert to base64 string
            })
        }
        else {
            this.setState({ files: [] });
        }
    }

    _onRemoveFile = (fileName) => {
        const { files } = this.state;
        const newFiles = files.filter((f) => f.file.name !== fileName);
        this.setState({ files: newFiles });
    }

    _onAddImages = () => {

    }

    render() {
        const { isLoading, fields, data, fieldErrors,  files } = this.state;
        return <>
            {isLoading && <Loader></Loader>}
            <Row>
                <Col className='p-0 border-bottom bg-light'>
                    <AddAssetMenu  onSave={() => this._onSave()}></AddAssetMenu>
                </Col>
            </Row>

            <Row>
                <Col sm="12" className='pt-2'>
                    <FormField
                        {...field}
                        onChange={(value) => this._onAddFiles(value)}
                        invalid={false}
                        errorMessage={''}
                    ></FormField>
                </Col>
            </Row>
            {
                files && files.length > 0 &&
                <Row>
                    <Col sm="12">
                        {
                            files.map((item, index) => {
                                const preview = imageMimeTypes.indexOf(item.file.type) > -1 ?
                                <div key={`file_${index}`} className={'w-100'} style={{ height:'100%', minHeight: '50px', maxWidth:"75px", backgroundImage: `url('${item.src}')`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} ></div> :
                                <div key={`file_${index}`} className={'py-3 w-100 bg-primary'} style={{ height:'100%', minHeight: '50px', maxWidth:"75px", backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                    <FontAwesomeIcon size={"1x"} className={'d-block mx-auto text-white'} icon={faFile}></FontAwesomeIcon>
                                </div>
                                    
                                return <Alert isOpen={true} toggle={()=> this._onRemoveFile(item.file.name)} key={`connectedAsset_${index}`} className={"alert-outline-coloured mb-1"} color={'primary'}>
                                <div style={{ width: '100px'}} className="border border-primary border-1 rounded-left border-right-0">
                                {preview}
                                </div>
                                <div className="alert-message">
                                    <strong>{item.file.name}</strong>
                                </div>
                            </Alert>
                            })
                        }
                    </Col>
                </Row>
            }


            {Array.isArray(fields) && fields.length > 0 &&
                <>
                    {
                        fields.map((field, index) => {
                            return <Row key={`key${index}`}>
                                <Col md="12" className='pt-2'>
                                    <FormField
                                        {...field}
                                        value={data[field.name]}
                                        onChange={(value) => this._onValueChange(field.name, value)}
                                        invalid={fieldErrors[field.name] !== undefined ? fieldErrors[field.name].invalid : false}
                                        errorMessage={fieldErrors[field.name] !== undefined ? fieldErrors[field.name].message : ''}
                                        fieldErrors={fieldErrors[field.name]}
                                    ></FormField>
                                </Col>
                            </Row>
                        })}

                    <Row>
                        <Col sm="12" className={'text-right pt-2'}>
                            <Button className='mr-2' onClick={() => this._onDismiss()}>Cancel</Button>
                            <Button onClick={() => this._onSave()} color="primary">Save</Button>
                        </Col>
                    </Row>
                </>     
            }
        </>
    }
}

export default AddServiceRequest;