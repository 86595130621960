import React from 'react';
import { Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const IconAlert = (props) => {
    const { color, icon } = props;
    return    <Alert color={color} className='alert-outline-coloured mb-0'>
    <div className="alert-icon">
        <FontAwesomeIcon icon={icon} fixedWidth></FontAwesomeIcon>
    </div>
    <div style={{ borderBottomLeftRadius: '0', borderTopLeftRadius: '0' }} className="alert-message rounded-right rounded-left-0 ">
        {props.children}
    </div>
</Alert>
}
export default IconAlert;