import { toastr } from "react-redux-toastr";

const Toastr = (type,title,message) => {
  const options = {
    timeOut: parseInt(3000),
    showCloseButton: true,
    progressBar: true,
    position: "top-right"
  };

  const toastrInstance =
    type === "info"
      ? toastr.info
      : type === "warning"
      ? toastr.warning
      : type === "error"
      ? toastr.error
      : toastr.success;

  toastrInstance(
    title,
     message,
    options
  );
}

export default Toastr;

