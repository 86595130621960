import React from "react";
import { connect } from "react-redux";
import { enableTenantTheme } from "../../../redux/actions/themeActions";
import {DominoService} from '../../../services/dominoService';
import Loader from '../../../components/Loader';

class TenantTheme extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      isLoading:true,
    }

    this._service = new DominoService(props.apiClient)
  }

  componentDidMount() {
    
      const {dispatch, tenant} = this.props;

      const landlord = window.location.host.split('.').shift()?.replace(/-.+/g, '');
      const themePromises = [];
      
      themePromises.push(this._getTheme(tenant));
      themePromises.push(this._getTheme(landlord));

    Promise.allSettled(themePromises).then(([tenantResponse, landlordResponse]) => {
      const isTenantThemeFulfilled = tenantResponse && tenantResponse.status === 'fulfilled';
      const isLandlordThemeFulfilled = landlordResponse && landlordResponse.status === 'fulfilled';
      const theme = isTenantThemeFulfilled && tenantResponse.value ? tenant :
        isLandlordThemeFulfilled && landlordResponse.value ? landlord : undefined;

      if (theme) {
        dispatch(enableTenantTheme(theme));
      }

      }).catch((error) => {
      }).finally(() =>
       { 
        document.title = tenant && `${tenant !== '' ? tenant.replace(/^\w/, (c) => c.toUpperCase()) : 'Youmoni'} - Console`
         this.setState({ isLoading: false });}
      );    
  }

  _getTheme = (tenant) => {
    return new Promise((resolve, reject) => {
      if (tenant === '') {
        reject();
      }
      else {
        import(`../../../assets/scss/tenants/${tenant}.scss`).then((response) => {
          resolve(true);
        }).catch(() => {
          reject();
        })
      }
    });
  }
  

  render() {
    const { children } = this.props;
    const { isLoading} = this.state;

    return <>
      {isLoading && <Loader></Loader>}
      {!isLoading && <>{children}</>}
    </>
  }
}

export default connect()(TenantTheme);