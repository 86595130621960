import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faLockOpen, faEllipsisH, faExclamationTriangle, faClock, faThermometerThreeQuarters, faSync, faDoorClosed, faLock, faCheck } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Media, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LastSeenFormatter } from '../../../utils/columnFormatter';
import IconAlert from '../../../components/Youmoni/IconAlert';
import { faFileArchive } from '@fortawesome/free-regular-svg-icons';
import { getResourceData } from '../../../components/Youmoni/DataTable/TableComponent';

export const DashboardNotification = (props) => {
    let component = null;
    const { qualifier, severity, uid, editable, onUpdate } = props;
    const icon = qualifier !== undefined && QualifierIconMappings.hasOwnProperty(qualifier) ? QualifierIconMappings[qualifier] : faExclamationTriangle;
    if (qualifier !== undefined && QualifierTypeMappings.hasOwnProperty(qualifier)) {
        const control = QualifierTypeMappings[qualifier];
        if (control) {
            component = React.createElement(QualifierTypeMappings[qualifier], props);
        }
    }
    return <IconAlert color={severity} icon={icon}>
        <span className="card-actions float-right py-0">
            <UncontrolledDropdown className="py-0" direction='left'>
                <DropdownToggle tag="a">
                    {editable && <FontAwesomeIcon icon={faEllipsisH} />}
                    {editable === false && <div>&nbsp;</div>}
                </DropdownToggle>
                <DropdownMenu container={'body'} right={true}>
                    <DropdownItem tag={'button'} className="px-3" onClick={() => onUpdate(uid, { status: 'acknowledge' })}> <FontAwesomeIcon className="mr-2" icon={faCheck}></FontAwesomeIcon> Acknowledge</DropdownItem>
                    <DropdownItem tag={'button'} className="px-3" onClick={() => onUpdate(uid, { status: 'archived' })}><FontAwesomeIcon className="mr-2" icon={faFileArchive}></FontAwesomeIcon>Archive</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </span>
        {component && <>{component}</>}
        {component === null && <><DefaultNotification {...props}></DefaultNotification></>}
    </IconAlert>
}

const DefaultNotification = (props) => {
    const {uid, title, creation, origin, children,resources } = props;
    const originYri = origin.replace(/#.+/g, '')
    const originName = getResourceData('origin.name', { origin: originYri }, resources);
    
    const assetto = /yri:\/\/.+\.assetto\//;
    const gizmo = /yri:\/\/.+\.gizmo\//;
    return <>
        <Media>
            <Media body className="pb-2">
                <Media heading>
                    <Link to={{ pathname: "/dashboard", search: `?notification=${uid}` }} className={'text-dark'}><h4 className={'font-weight-bold d-inline'}>{title ? title : `[Notification Title Missing]`}</h4>
                    </Link>
                </Media>
                <span className="mr-2">Last Alert: {LastSeenFormatter({ value: creation, status: 'dark' }, { id: uid }, props.uid, { icon: faClock })}</span>
                {assetto.test(originYri) && <div><Link onClick={(e) => e.stopPropagation()} to={`/assets/${originYri?.replace(assetto,'')}`}>{originName || ''}</Link></div>}
                {gizmo.test(originYri) && <></>}
                {children}
            </Media>
        </Media>
    </>
}

const CabinetRefillNeeded = (props) => {
    const { timeline,qualifier } = props;
    const shortages = timeline && timeline.shortages ? timeline.shortages : {};
    let data = [];
    try {
        Object.keys(shortages).forEach((key) => {
            const severity = shortages[key].hasOwnProperty('severity') ? shortages[key].severity : 'info';
            const item = <Badge color={severity}>{key}: {shortages[key].count}</Badge>
            data.push(item);
        });
    }
    catch {
        console.error(`Failed parsing ${qualifier} notification`)
    }
    return <DefaultNotification {...props}>
        <>
            {data.map((item, index) => {
                return <span key={`refill_needed_${index}`} className={'mr-2'}>{item}</span>
            })
            }
        </>
    </DefaultNotification>
}

const CabinetAlarmed = (props) => {
    const { timeline , severity} = props;
    const cabinetDoors = timeline.doors && timeline.doors.length > 0 ? timeline.doors : []
    const cabinetLocks = timeline.locks && timeline.locks.length > 0 ? timeline.locks : []
    return <DefaultNotification {...props}>
        {cabinetDoors.map((door, index) => {
            const className = door === 'opened' ? `text-${severity.status}` : 'text-info';
            return <span className={`text-center mr-1 ${className}`} key={`span_door_${index}`}><FontAwesomeIcon size={'sm'} icon={door === 'opened' ? faDoorOpen : faDoorClosed}></FontAwesomeIcon></span>
        })}

        {cabinetLocks.map((lock, index) => {
            const className = lock !== 'locked' ? `text-${severity.status}` : 'text-info';
            return <span className={`text-center mr-1 ${className}`} key={`span_lock_${index}`}><FontAwesomeIcon icon={lock === 'locked' ? faLock : faLockOpen}></FontAwesomeIcon></span>
        })}

    </DefaultNotification>
}

const CabinetGasDetected = (props) => {
    const { timeline, severity } = props;
    const level = timeline && timeline.level ? timeline.level : 'unknown';
    const color = severity && severity.status ? severity.status : 'dark'
    let data = [
        <Badge color={color}>Level: {level}</Badge>
    ];

    return <DefaultNotification {...props}>
        <>
            {data.map((item, index) => {
                return <span key={`refill_needed_${index}`} className={'mr-2'}>{item}</span>
            })
            }
        </>
    </DefaultNotification>
}

export default DashboardNotification;

const QualifierIconMappings = {
    "invento.cabinet.door.alarmed": faDoorOpen,
    "invento.cabinet.lock.alarmed": faLockOpen,
    "invento.cabinet.refill.needed": faSync,
    "influx.temperature": faThermometerThreeQuarters,
}

const QualifierTypeMappings = {
    "invento.cabinet.refill.needed": CabinetRefillNeeded,
    "invento.cabinet.door.alarmed": CabinetAlarmed,
    "invento.cabinet.lock.alarmed": CabinetAlarmed,
    "invento.cabinet.gas.detected": CabinetGasDetected

};