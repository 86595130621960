
import React from "react";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dashboard from "../tenants/_base/Dashboard/Dashboard";

const mainRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <FontAwesomeIcon icon={faSlidersH}></FontAwesomeIcon>,
    containsHome: false,
    component: Dashboard,
    facet: 'dashboard'
  }
];

export default mainRoutes;