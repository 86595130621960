import React from "react";
import { Container, Row, Col, Spinner, Modal, ModalBody } from "reactstrap";

const Loader = () => (
  <Modal backdropClassName={"bg-white"} contentClassName={"bg-transparent"} fade={false} size="md" centered={true} isOpen={true}>
    <ModalBody>
      <Container fluid>
        <Row>
          <Col className="d-flex">
            <span className="mx-auto"><Spinner color="primary" /></span>
          </Col>
        </Row>
      </Container>
    </ModalBody>
  </Modal>
);

export default Loader;
