import React from "react";
import $ from 'jquery';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, UncontrolledDropdown } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";

const autocollapse = (menu, maxHeight) => {

    var nav = $(menu)
    var navHeight = nav.innerHeight()
    if (navHeight >= maxHeight) {
        $(menu + ' .dropdown').removeClass('d-none');
        $(".navbar-nav").removeClass('w-auto').addClass("w-100");

        let maxLoop = 0;
        while (navHeight > maxHeight && maxLoop < 30) {

            maxLoop += 1;
            var children = nav.children(menu + ' li:not(:last-child)');
            var count = children.length;
            $(children[count - 1]).prependTo(menu + ' .dropdown-menu');
            navHeight = nav.innerHeight();
        }
        $(".navbar-nav").addClass("w-auto").removeClass('w-100');

    }
    else {
        var collapsed = $(menu + ' .dropdown-menu').children(menu + ' li');

        if (collapsed.length === 0) {
            $(menu + ' .dropdown').addClass('d-none');
        }

        while (navHeight < maxHeight && (nav.children(menu + ' li').length > 0) && collapsed.length > 0) {
            //  remove child from dropdown
            collapsed = $(menu + ' .dropdown-menu').children('li');
            $(collapsed[0]).insertBefore(nav.children(menu + ' li:last-child'));
            navHeight = nav.innerHeight();
        }

        if (navHeight > maxHeight) {
            autocollapse(menu, maxHeight);
        }

    }

    var active = $(menu).find('.active');
    const ul = active.parent().parent()[0];
    if (ul && ul.id === 'more') {
        $(active).toggleClass('active');
        $(ul).prev().toggleClass('active');
    }
};

class Tabs extends React.Component {
    componentDidMount() {
        autocollapse('#nav-tab', 55);
    }


    render() {
        const { tabs, activeTab, id, collection, location } = this.props;
        const {search} = location ? location : {};
        return <Nav tabs id="nav-tab">
            {
                tabs.map((tab, index) => {
                    return <NavItem key={`li_${index}`} className="nav-item" >
                        <Link
                            className={`nav-link ${classNames({ active: activeTab === tab.path })}`}
                            to={{ pathname: `/${collection}/${id}${tab.path}`, search: search }}>
                            <span className="text-capitalize text-nowrap">{tab.title}</span>
                        </Link>
                    </NavItem>
                })
            }
            <UncontrolledDropdown inNavbar className="nav-item dropdown">
                <DropdownToggle nav data-toggle="dropdown" className="nav-link dropdown-toggled text-capitalize">
                    <span style={{ height: 5 }} className="d-inline"><FontAwesomeIcon style={{ height: 15 }} size='1x' icon={faEllipsisH}></FontAwesomeIcon></span>
                </DropdownToggle>
                <DropdownMenu right id='more' style={{ minWidth: 250 }} className="dropdown-menu mx-1 bg-white w-100">
                    <DropdownItem className="d-none d-hidden"></DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Nav>
    }
}

export class TabNavigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            windowSize: undefined,
        }
    }

    componentDidMount() {
        window.addEventListener('resize', (ev) => this._onResizeScreen(ev));
    }

    componentWillUnmount() {
        window.removeEventListener('resize', (ev) => this._onResizeScreen(ev));
        this.setState = () => { return; };
    }

    _onResizeScreen = (ev) => {
        this.setState({ windowSize: ev.target.innerWidth })
    }

    render() {
        const { windowSize } = this.state;
        const { tabs, activeTab, id, collection } = this.props;
        let activeTabName = tabs.filter((tab) => tab.path === activeTab)[0] !== undefined ? tabs.filter((tab) => tab.path === activeTab)[0].title : 'Select View';
        return <div key={`tabs_${windowSize}`}>
            <div className={'d-none d-md-block'} >
                <Tabs {...this.props}></Tabs>
            </div>
            <div className="d-md-none">
                <UncontrolledDropdown className="d-flex">
                    <DropdownToggle color="primary" outline={false} className={'flex-fill py-2 my-2 '} caret>
                        <span className={'px-1 text-capitalize'}> {activeTabName}</span>
                    </DropdownToggle>
                    <DropdownMenu style={{ width: '93%' }} className="shadow-lg">
                        {
                            tabs.map((tab, index) => {
                                return <Link className="flex-fill" key={`asset_ddl_${index}`} to={`/${collection}/${id}${tab.path}`}>
                                    <DropdownItem>
                                        <span className={'text-capitalize'}>{tab.title}</span>
                                    </DropdownItem>
                                </Link>
                            })
                        }
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        </div>
    }
}