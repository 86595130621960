import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Card, Button, Badge, Modal, ModalBody } from "reactstrap";
import { AssettoService } from "../../../services/assettoService";
import AccessControl, { ACTION, hasAccess, MATCHER } from "../../../utils/accessControl";
import Loader from '../../../components/Loader'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faExclamationTriangle, faMapMarkerAlt, faSync } from "@fortawesome/free-solid-svg-icons";
import { RoundImg } from "../../../components/Youmoni/Img";
import * as _ from "lodash";
import { DashboardMap } from "../../../components/Youmoni/Map/DashboardMap";
import { getBounds, MapboxGLButtonControl } from '../../../components/Youmoni/Map/Map';
import { Marker, Popup } from "react-map-gl";
import DashboardNotifications from "./DashboardNotifications";
import IconAlert from "../../../components/Youmoni/IconAlert";
import { getResourceData } from "../../../components/Youmoni/DataTable/TableComponent";
import { LastSeen, getLastSeenColor } from "../../../components/Youmoni/DataTable/TableColumn";
import { getProperty } from "../../../utils/uiHelper";



const PopupComponent = (props) => {
  const { _devices, timestampIso } = props;
  const link = `/assets/${props.collection}/${props.alias}`
  const deviceNames = Array.isArray(_devices) ? _devices.map((d) => { return d.name }) : [];

  const content = <>
    <Row>
      <Col sm="12" className="mt-2">
        <h3 style={{ minWidth: '250px' }}>
          {props.name} ({props.alias}) </h3>
      </Col>
    </Row>
    <Row>
      <Col sm="12" className='mb-4'>
        {props.mainImage &&
          <RoundImg key={`img_${new Date().getTime()}`} {...props} src={props.mainImage ? `assetto/${props.mainImage}` : undefined}></RoundImg>
        }
      </Col>
    </Row>
    <Row>
      <Col xs="6">Device</Col>
      <Col xs="6">
        {Array.isArray(deviceNames) && deviceNames.length > 0 ? deviceNames.join(',') : 'No device'}
      </Col>
    </Row>
    <Row>
      <Col xs="6">Latitude:</Col>
      <Col xs="6">
        {isNaN(props.lat) ? '-' : props.lat.toFixed(4)}
      </Col>
    </Row>
    <Row>
      <Col xs="6">Longitude:</Col>
      <Col xs="6">
        {isNaN(props.lng) ? '-' : props.lng.toFixed(4)}
      </Col>
    </Row>
    {Array.isArray(deviceNames) && deviceNames.length > 0 && <Row>
      <Col xs="6">Last Seen:</Col>
      <Col xs="6">
        {props.timestampIso === undefined && <Badge color='danger'>No data</Badge>}
        {props.timestampIso !== undefined && <LastSeen data={timestampIso} ></LastSeen>}
      </Col>
    </Row>}
    <Row>
      <Col sm="12" className='text-right mt-4'>
        <Link to={`${link}`}><Button color='primary'>View Details</Button></Link>
      </Col>
    </Row>
  </>
  return <>
    <div className="d-none d-md-block">{content}</div>
    <div className="d-block d-md-none"><Modal size="sm" wrapClassName="d-md-none" className="d-md-none" centered toggle={() => props.onToggle()} backdropClassName={'d-md-none'} isOpen={props.alias !== undefined}>
      <ModalBody>{content}</ModalBody>
    </Modal></div>
  </>
}

class DashBoard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      assets: [],
      selectedMarker: undefined,
      isLoading: true,
      sensorNotifications: [],
      deviceNotifications: [],
      center: { lat: props.center && props.center.lat ? props.center.lat : 0, lng: props.center && props.center.lng ? props.center.lng : 0 },
      bounds: [],
      statusFilters: ['info', 'success', 'warning', 'danger', 'severe', 'critical'],
      isMapPanelOpen: false
    }

    this.ref = React.createRef();
  }

  _initComponent = ({ fitToBounds }) => {
    const { facets, settings, apiClient } = this.props;



    if (!hasAccess('dashboard', ACTION.ANY, facets, MATCHER.START)) {
      this.setState({ isLoading: false });
      return;
    }
    else {
      this.setState({ isLoading: true });


      const mapCollections = settings?.dashboard?.map ? settings?.dashboard?.map : {};
      const collections = Object.keys(mapCollections).map((key) => { return key });
      const collectionsWithAccess = collections.filter((collection) => hasAccess(`dashboard.${collection}`, ACTION.ANY, facets, MATCHER.START));

      const promises = [];
      collectionsWithAccess.forEach((collection) => {
        const service = new AssettoService(apiClient, collection);
        promises.push(service.getAssets({ context: ['navigation'], contextual: true, focus: `yri://${apiClient?._tenant}.assetto/root/${apiClient?._tenant}` }))
      })

      Promise.allSettled(promises).then((responses) => {
        const successResponses = responses.filter((r) => r.status === 'fulfilled').map((r) => { return r.value });
        const allEntities = successResponses.map((response) => { return response?.entities });
        const allResources = successResponses.map((response) => { return response?.resources });

        let resources = {};
        allResources.forEach((r) => {
          resources = { ...resources, ...r }
        });


        const points = [];
        const assets = [];
        const entities = _.flatten(allEntities);
        entities.forEach((entity) => {

          const obj = mapCollections?.[entity?.collection];
          let latitudeKey = obj?.latitude;
          let longitudeKey = obj?.longitude;

          const echoYris = getProperty('@links.inwards.notifications', entity, []);
          const notifications = [];
          echoYris.forEach((yri) => {
            const notifiaction = getProperty(yri, resources, undefined);
            if (notifiaction) { notifications.push(notifiaction) }
            })

        const echoPending = _.filter(notifications, (n) => n.status === `pending`);
          const echoStatus = _.some(echoPending, (item) => item.severity === 'critical') ? 'critical' : 
           _.some(echoPending, (item) => item.severity === 'severe') ? 'severe' : 
           _.some(echoPending, (item) => item.severity === 'warning') ? 'warning' : 'success';

          
          if (latitudeKey.indexOf('$device') > -1 && longitudeKey.indexOf('$device') > -1) {

            const deviceYris = getProperty('@links.outwards.devices', entity, [])
            const devices = deviceYris.map((yri) => { return yri.replace(/yri:\/\/.+\.gizmo\/devices\//, '') })
            devices.forEach(device => {

              const lat = getResourceData(latitudeKey.replace('$device', device), entity, resources);
              const lng = getResourceData(longitudeKey.replace('$device', device), entity, resources);
              const deviceObj = getResourceData(`devices.${device}`, entity, resources);
              const timestampIso = getResourceData(`devices.${device}.recording.timestampIso`, entity, resources);

              const connectedDevices = [];
              if (deviceObj) {
                connectedDevices.push(deviceObj);
              }

              if (lng && lat) {
                points.push({ lat: lat, lng: lng });
                const status = echoStatus === 'success' ? getLastSeenColor(timestampIso) : echoStatus;
                
                
                //Get echo notifications here aswell
                const asset = { ...entity, lng: lng, lat: lat, status: status, _devices: connectedDevices, timestampIso: timestampIso }
                assets.push(asset)
              }
            });
          }
          else {
            const lat = getResourceData(latitudeKey, entity, resources);
            const lng = getResourceData(longitudeKey, entity, resources);

            if (lng && lat) {
              points.push({ lat: lat, lng: lng });
              const asset = { ...entity, lng: lng, lat: lat, status: echoStatus }
              assets.push(asset)
            }
          }
        })

        if (points.length > 0 && fitToBounds) {
          const bounds = getBounds(points);
          this.setState({ assets: assets, bounds: bounds, isLoading: false });
        }
        else {
          this.setState({ assets: assets, isLoading: false });
        }

      }).catch((error) => {
        this.setState({ isLoading: false });
      })
    }
  }

  _onMarkerClick = (marker) => {
    if (marker === undefined) {
      this.setState({ selectedMarker: undefined });
      return;
    }

    this.setState({ selectedMarker: marker, isLoading: false });
  }

  componentWillUnmount() {
    this.setState = () => { return; };
  }

  _onReload = () => {
    this._initComponent({ fitToBounds: false });
  }

  _onMapLodaed = () => {
    this._initComponent({ fitToBounds: true });
  }

  _update = (status) => {
    const { statusFilters } = this.state;
    const tempFilters = [...statusFilters]
    if (tempFilters.indexOf(status) > -1) {
      this.setState((prevState) => {
        return {
          ...prevState,
          statusFilters: tempFilters.filter((filter) => filter !== status)
        }
      })
    }
    else {
      this.setState((prevState) => {
        return {
          ...prevState,
          statusFilters: [...tempFilters, status]
        }
      })
    }
  }

  render() {
    const { facets } = this.props;
    let { isLoading, selectedMarker, assets, bounds, statusFilters, isMapPanelOpen } = this.state;
    const markers = assets && Array.isArray(assets) ? assets.filter((a) => statusFilters.indexOf(a?.status) > -1) : [];
    const reload = new MapboxGLButtonControl({ title: "Refresh", icon: faSync, eventHandler: () => { this._onReload() } });
    const more = new MapboxGLButtonControl({ title: "More", icon: faEllipsisH, eventHandler: () => { this.setState({ isMapPanelOpen: true }) } });
    const mapButtons = [{ button: reload, position: 'top-left' }, { button: more, position: 'top-right' }];

    return (
      <div>
        <AccessControl
          facets={facets}
          facet='dashboard'
          matcher={MATCHER.START}
          actions={ACTION.ANY}
          deny={() => <><IconAlert color={'danger'} icon={faExclamationTriangle}>Access denied</IconAlert></>}
        >
          <>
            {isLoading && <Loader></Loader>}
            <Container fluid className="p-0">
              <Row>
                <Col xl="8">
                  <Card body className="p-0 dashboard-map border border-1 mb-xl-0 overflow-hidden">
                    <DashboardMap
                      onMapLoaded={() => this._onMapLodaed()}
                      bounds={bounds}
                      mapButtons={mapButtons}
                      onMapClick={() => this.setState({ selectedMarker: undefined })}>
                      {isMapPanelOpen && <div style={{ height: 80, minWidth: 260 }} className={'control-panel'}>
                        <div style={{ width: 10 }} className={'float-right'}><Button close onClick={() => this.setState({ isMapPanelOpen: !isMapPanelOpen })}></Button></div>
                        <div style={{ fontSize: '1rem' }}>Settings</div>
                        <label htmlFor={'missingDevices'} className={'cursor-pointer'} style={{ marginRight: '10%', color: '#486865', marginBottom: '4px' }}>
                          <FontAwesomeIcon className={`text-dark mr-2`} icon={faMapMarkerAlt} />Show Missing Devices
                        </label>
                        <div className={'float-right'}>
                          <input className={'cursor-pointer'} id={'missingDevices'} checked={statusFilters.indexOf('missing') > -1} onChange={() => this._update('missing')} type="checkbox"></input>
                        </div>
                      </div>}
                      {markers.map((marker, index) => {
                        const status = Array.isArray(marker.devices) && marker.devices.length === 0 ? 'empty' : marker.status;
                        return <Marker
                          key={`marker_${index}`}
                          anchor='bottom-left'
                          onClick={(e) => { e.originalEvent.stopPropagation(); this._onMarkerClick(marker) }}
                          longitude={marker.lng}
                          latitude={marker.lat}>
                          <span id={`marker_${index}`} className={'cursor-pointer'}>
                            <FontAwesomeIcon className={`text-${status} pointer`}
                              icon={faMapMarkerAlt}
                              size='2x' />
                            {marker.name && <Badge style={{ fontSize: 11.2 }} className={`ml-1 text-center`} color={status}>{marker.name}</Badge>}
                          </span>
                        </Marker>
                      })}

                      {selectedMarker !== undefined && <Popup tipSize={10}
                        className={'d-none d-md-flex'}
                        longitude={selectedMarker.lng}
                        latitude={selectedMarker.lat}
                        onClose={() => this.setState({ selectedMarker: undefined })}
                        closeOnClick={false}
                        dynamicPosition={true}>
                        <PopupComponent {...this.props} {...selectedMarker} onToggle={() => this.setState({ selectedMarker: undefined })}></PopupComponent>
                      </Popup>}
                    </DashboardMap>
                  </Card>
                </Col>
                <Col xl="4">
                  <DashboardNotifications {...this.props}></DashboardNotifications>
                </Col>
              </Row>
            </Container>
          </>
        </AccessControl>
      </div>
    );
  }
}

export default connect(store => ({
  tenant: store.tenant
}))(DashBoard);