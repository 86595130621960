import React from 'react';
import { faFile} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row, Alert } from 'reactstrap';
import FormField from '../FormFields/FormField';
import { ModalPanel } from './ModalPanel';

const imageMimeTypes = ['image/apng',
    'image/bmp',
    'image/gif',
    'image/x-icon',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'image/webp']


export const FileUploadPanel = ({ files, isOpen, onChange, onCancel, onRemove, onSave, ...rest }) => {
    const _onChange = (fileList) => {
        let files = [...fileList];
        onChange(files);
    }
    const _onSave = () => {
        onChange([]);
        onSave();
    };
    const _onCancel = () => {
        onChange([]);
        onCancel();
    }

    const _onRemove = (fileName) => {
        onRemove(fileName);
    }

    const field = {
        type: "file",
        name: "images",
        label: 'Image',
        placeholder: "",
        invalid: false,
        multi: true,
        required: false,
        requiredMessage: "Field is required"
    }

    return <ModalPanel size={'lg'} {...rest} onDismiss={_onCancel}  title="Select File" isOpen={isOpen}>
            <Row>
                <Col sm="12" className='pt-2'>
                    <FormField
                        {...field}
                        onChange={(value) => _onChange(value)}
                        invalid={false}
                        errorMessage={''}
                    ></FormField>
                </Col>
            </Row>
            {
                files && files.length > 0 &&
                <Row>
                    <Col sm="12">
                        {
                            files.map((item, index) => {
                                const preview = imageMimeTypes.indexOf(item.file.type) > -1 ?
                                <div key={`file_${index}`} className={'w-100'} style={{ height:'100%', minHeight: '50px', maxWidth:"75px", backgroundImage: `url('${item.src}')`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }} ></div> :
                                <div key={`file_${index}`} className={'py-3 w-100 bg-primary'} style={{ height:'100%', minHeight: '50px', maxWidth:"75px", backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
                                    <FontAwesomeIcon size={"1x"} className={'d-block mx-auto text-white'} icon={faFile}></FontAwesomeIcon>
                                </div>
                                    
                                return <Alert isOpen={true} toggle={()=> _onRemove(item.file.name)} key={`connectedAsset_${index}`} className={"alert-outline-coloured mb-1"} color={'primary'}>
                                <div style={{ width: '100px'}} className="border border-primary border-1 rounded-left border-right-0">
                                {preview}
                                </div>
                                <div className="alert-message">
                                    <strong>{item.file.name}</strong>
                                </div>
                            </Alert>
                            })
                        }
                    </Col>
                </Row>
            }
            <Row>
                <Col sm="12" className="text-right">
                    <Button onClick={_onCancel} className="mr-2">Cancel</Button>
                    <Button onClick={_onSave} color='primary'>Save</Button>
                </Col>
            </Row>
    </ModalPanel>
}