import React from "react";
import async from "../components/Async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCubes } from "@fortawesome/free-solid-svg-icons";

const Devices = async(() => import("../tenants/_base/Devices/Devices"));

const routes = [{
    path: "/devices",
    name: "Devices",
    icon: <FontAwesomeIcon icon={faCubes}></FontAwesomeIcon>,
    component: Devices,
    facet:'devices'
  },
  {
    path: "/devices/:id",
    name: "Devices",
    hidden:true,
    component: Devices,
    facet:'devices'
  },
]



 export const deviceRoutes = routes ;