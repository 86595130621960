
export class FormsService {
    constructor(apiClient) {
        this._apiClient = apiClient;
        this._tenant = apiClient._tenant;
    }

    getForms(collection) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`forms/${collection}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }
}
