import React from "react";
import async from "../components/Async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const Notifications = async(() => import("../tenants/_base/Notifications/Notifications"));
const routes = [
{
    path: "/notifications",
    name: "Notifications",
    icon: <FontAwesomeIcon icon={faBell}></FontAwesomeIcon>,
    component: Notifications,
    facet :'notifications'
}]

export const notificationRoutes = routes;