export class ApiService {
    constructor(apiKey, tenant) {
        this._requestHeaders = [];
        this._requestMethod = 'GET';
        this._tenant = tenant;
        this._apiKey = apiKey;

        this._requestHeaders.push(['x-authorization', apiKey]); 
        this._requestHeaders.push(['x-tenant', tenant]);
        this._requestHeaders.push(['Content-Type','application/json;charset=UTF-8']);

    }
    setRequestHeaders(headers) {
        for (const i in headers) {
            if (headers[i].hasOwnProperty('key')
                && headers[i].hasOwnProperty('value')) {
                this._requestHeaders.push([
                    headers[i].key,
                    headers[i].value
                ]);
            }
        }
        return this;
    }
    getRequestHeaders() {
        return this._requestHeaders;
    }
    resetRequestHeaders() {
        this._requestHeaders = [];
        return this;
    }
    setRequestMethod(method) {
        this._requestMethod = method;
        return this;
    }
    Request(body) {
        return {
            headers: this._requestHeaders,
            method: this._requestMethod,
            body: JSON.stringify(body),
        };
    }

    RequestNoBody() {
        return {
            headers: this._requestHeaders,
            method: this._requestMethod,
        };
    }

    RequestMultiPart(formData) {
        return {
            headers: [['x-authorization', this._apiKey], ['x-tenant', this._tenant], ['Content-Encoding', 'gzip, deflate, br']],
            method: this._requestMethod,
            body: formData,
        };
    }
}
export default ApiService;