import qs from 'qs';
export class DominoService {

    constructor(apiClient) {
        this._apiClient = apiClient;
    }

    getTenants() {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get('domino/tenants').then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getSubTenant(tenantId) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/tenants/${tenantId}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    updateTenant = (tenantId, body) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.patch(`domino/tenants/${tenantId}`, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    createTenant(tenantId, name, isLandlord) {
        let promise = new Promise((resolve, reject) => {
            let url = `domino/tenants/${tenantId}`;
            let body = { name: name, landlord: isLandlord };
            this._apiClient.put(url, body)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((result) => {
                    resolve(result);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    deleteTenant(tenantId) {
        let promise = new Promise((resolve, reject) => {
            let url = `domino/tenants/${tenantId}`;
            this._apiClient.delete(url)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((result) => {
                    resolve(result);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    //User
    getUsers() {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get('domino/users').then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status);
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getUser(email) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/users/${email}`).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw response;

            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    addUser(email) {
        let url = `domino/users/${email}`;
        let promise = new Promise((resolve, reject) => {
            this._apiClient.put(`${url}`, {}).then((response) => {
                return response;
            }).then((result) => {
                if (result.ok) {
                    resolve(result);
                }
                throw result
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    validateUser(email) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/auths/${email}`).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                reject(response.status)
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    createUser(data) {
        let promise = new Promise((resolve, reject) => {
            let body = data;
            this._apiClient.post(`domino/users`, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status);
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    updateUser(email, roles) {
        let body = {
            roles: roles.map((role) => { return role.roleId })
        };
        let url = `domino/users/${email}`;
        let promise = new Promise((resolve, reject) => {
            this._apiClient.patch(`${url}`, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status);
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    deleteUser(email) {
        let url = `domino/users/${email}`;
        let promise = new Promise((resolve, reject) => {
            this._apiClient.delete(`${url}`).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status);
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getMandates() {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get('domino/mandates').then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status);
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getGroups() {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/groups`).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status);
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getGroup(groupId) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/groups/${groupId}`).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status);
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    addGroup(groupId, data) {
        let promise = new Promise((resolve, reject) => {
            let url = `domino/groups/${groupId}`;
            let body = data;
            this._apiClient.put(url, body)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    updateGroup(groupId, group) {
        let body = group;
        let url = `domino/groups/${groupId}`;
        let promise = new Promise((resolve, reject) => {
            this._apiClient.patch(`${url}`, body).then((response) => {
                return response;
            }).then((result) => {
                if (result.ok) {
                    resolve(result);
                }
                else {
                    reject();
                }
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    deleteGroup(groupId) {
        let promise = new Promise((resolve, reject) => {
            let url = `domino/groups/${groupId}`;
            this._apiClient.delete(url)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    addGroupMember(groupId, userId) {
        let promise = new Promise((resolve, reject) => {
            let url = `domino/groups/${groupId}/users/${userId}`;
            this._apiClient.put(url, {})
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    removeGroupMember(groupId, userId) {
        let promise = new Promise((resolve, reject) => {
            let url = `domino/groups/${groupId}/users/${userId}`;
            this._apiClient.delete(url)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    getAccountResources = () => {
        let promise = new Promise((resolve, reject) => {
            let url = `domino/resources/console`;
            this._apiClient.get(url)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    addAccountResource(filename, file) {
        let promise = new Promise((resolve, reject) => {
            let url = `domino/resources/${filename}`;
            this._apiClient.put(url, file)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    addAccountResources(data) {
        let promise = new Promise((resolve, reject) => {
            let url = `domino/resources`;
            let body = data;
            this._apiClient.postMultiPart(url, body)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    deleteAccountResource(path) {
        let promise = new Promise((resolve, reject) => {
            let url = `domino/resources/${path}`;
            this._apiClient.delete(url)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    getAccountConfigs = () => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/configs`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getAccountConfig = (filename, defaultFile = false) => {
        const url = `domino/configs/${filename}`;
        const queryString = defaultFile ? `?default=true` : '';

        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`${url}${queryString}`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status);
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }


    addAccountConfig(data) {
        let promise = new Promise((resolve, reject) => {
            let url = `domino/configs`;
            let body = data;
            this._apiClient.postMultiPart(url, body)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    deleteAccountConfig = (filename) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.delete(`domino/configs/${filename}`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getMemberships = (queryObject = {}) => {
        const queryString = Object.keys(queryObject).length > 0 ? `?${qs.stringify(queryObject, {  arrayFormat: 'comma', skipEmptyStrings: true })}` : '';
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/memberships${queryString}`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getMembershipsByResource = (resourceYri) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/memberships?resource=${encodeURIComponent(resourceYri)}`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }


    getMembership = (id) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/memberships/${id}`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    addMembershipInvitation = (template, request) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.post(`domino/memberships?template=${template}`, request).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;

    }

    deleteMembership = (id) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.delete(`domino/memberships/${id}`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }


   patchMembership = (id, request) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.patch(`domino/memberships/${id}`, request).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getMembershipTemplates = () => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/membership-templates`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getMembershipTemplate = (id) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/membership-templates/${id}`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    deleteMembershipTemplate = (id) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.delete(`domino/membership-templates/${id}`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getRoles = () => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/roles`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getRole = (id) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`domino/roles/${id}`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    addRole = (request) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.post(`domino/roles`, request).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    updateRole = (id, request) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.put(`domino/roles/${id}`, request).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }
    deleteRole = (id) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.delete(`domino/roles/${id}`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }
}