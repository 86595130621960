import React from "react";
import { Modal, Button, Row, Col } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

class RightPanel extends React.Component {

  toggleSidebar() {
    if (this.props.dismiss) {
      this.props.dismiss();
    }
  }
  render() {
    const { isOpen, block, header } = this.props;
    const size = this.props.size ? this.props.size : '';
    const className = this.props.className ? this.props.className : 'mx-3';
    const backdropClassName = this.props.backdropClassName ? this.props.backdropClassName : 'bg-white'
    
    
    const _toggle = () => {
      if(block === true)
      {return}

      this.toggleSidebar();

    }
    
    return (
      <Modal fade={false} backdropClassName={backdropClassName} className={size} isOpen={isOpen} toggle={() => _toggle()}>
        <div className={`right-panel-wrapper`}>
          {
            isOpen &&
            <div className={`panel ${isOpen ? 'open' : ''}`}>
              <div className={`panel-content pb-5 ${size}`}>

                { header && header !== '' ?
                  <Row>
                    <Col className={'mx-3 py-4'}>
                    <h2 className={'d-inline'}>{header}</h2>
                    <div className={'float-right'}><Button close onClick={() => this.toggleSidebar()} /></div>
                    </Col>
                  </Row> : 
                   <div className="right-panel-title mx-3 py-2">
                     <Button close onClick={() => this.toggleSidebar()} />
                   </div>
                }
               
                <PerfectScrollbar>
                  <div className={`${className}`}>
                    {this.props.children}
                  </div>
                </PerfectScrollbar>
              
              </div>
            </div>
          }
        </div>
      </Modal>
    );
  }
}

export default RightPanel