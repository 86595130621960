import React from "react";
import async from "../components/Async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFileLines } from "@fortawesome/free-regular-svg-icons";

const ServiceRequests = async(() => import("../tenants/_base/ServiceRequests/ServiceRequests"));

const routes = [{
    path: "/service-requests",
    name: "Service Requests",
    icon: <FontAwesomeIcon icon={faFileLines}></FontAwesomeIcon>,
    component: ServiceRequests,
    facet:'service-requests'
  },
]

 export const serviceRequestsRoutes = routes;