import ApiService from './ApiService'
export class ApiClient {
    
    constructor(apiKey, tenant) {
        this._requestHeaders = [];
        this._requestMethod = 'GET';
        this._tenant = tenant;
        this._apiKey = apiKey;
        let host = window.location.host;
        this._baseUrl = `${window.location.protocol}//${host}/api/`
    }

    head(url) {
        var api = new ApiService(this._apiKey,  this._tenant);
        api.setRequestMethod( 'HEAD' ); 
        return fetch(this._baseUrl + url, api.RequestNoBody());
    }

    get(url, body = null) {
        var api = new ApiService(this._apiKey,  this._tenant);
        const headers = body ?  api.Request(body) : api.RequestNoBody();
        return fetch(this._baseUrl + url, headers);
    }

    post(url, body) {
        var api = new ApiService(this._apiKey,  this._tenant);
        api.setRequestMethod( 'POST' ); 
        return fetch(this._baseUrl + url, api.Request(body));
    }

    put(url, body) {
        var api = new ApiService(this._apiKey,  this._tenant);
        api.setRequestMethod( 'PUT' ); 
        api.setRequestHeaders()
        return fetch(this._baseUrl + url, api.Request(body));
    }

    putMultiPart(url,formData){
        var api = new ApiService(this._apiKey,  this._tenant);
        api.setRequestMethod( 'POST' ); 
        return fetch(this._baseUrl + url, api.RequestMultiPart(formData));

    }

    postMultiPart(url,formData){
        var api = new ApiService(this._apiKey,  this._tenant);
        api.setRequestMethod( 'POST' ); 
        return fetch(this._baseUrl + url, api.RequestMultiPart(formData));

    }

    patch(url, body) {
        var api = new ApiService(this._apiKey,  this._tenant);
        api.setRequestMethod( 'PATCH' ); 
        return fetch(this._baseUrl + url, api.Request(body));
    }

    delete(url, body) {
        var api = new ApiService(this._apiKey, this._tenant);
        api.setRequestMethod('DELETE');
        return fetch(this._baseUrl + url, body === undefined ? api.RequestNoBody() : api.Request(body));
    }
}
export default ApiClient;