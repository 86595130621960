import { faCheck, faFolder, faFolderOpen, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Row, Modal, ModalHeader, ModalBody, ModalFooter, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Loader from '../../../../components/Loader';
import DataTable from '../../../../components/Youmoni/DataTable/DataTable';
import { Img, ThumbImg } from '../../../../components/Youmoni/Img';
import Toastr from '../../../../components/Youmoni/Toastr';
import { FileUploadPanel } from '../../../../components/Youmoni/Panels/FileUploadPanel';
import { AssettoService } from '../../../../services/assettoService';
import TableComponent from '../../../../components/Youmoni/DataTable/TableComponent';


const DeleteModal = (props) => {
    return (<Modal size="md" centered={true} isOpen={props.isOpen} toggle={props.onCancel}>
        <ModalHeader tag="h2" toggle={props.onCancel}>
            Delete {props.id}
        </ModalHeader>
        <ModalBody className="m-3">
            Are you sure you want to delete {props.id}?
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={props.onCancel}>
                Cancel
            </Button>
            <Button
                color={"primary"}
                onClick={props.onConfirm}>
                Delete
            </Button>
        </ModalFooter>
    </Modal>)
}

export default class AssetResources extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: true,
            isFileUploadModalOpen: false,
            isDeleteModalOpen: false,
            files: [],
            tableData: [],
            tableColumns: [],
            selectedRow: null,
            selectedRows: [],
            breadcrumbs: ['resources'],
        }

        this._assettoService = new AssettoService(props.apiClient, props.collection);
    }

    componentDidMount() {

        this._initComponent();
    }

    _actionColumnFormatter = (props) => {
        const { row } = props;
        const { name } = row;
        return <div>
            {
                name !== '' &&
                <>
                    {name === `timeline` && <Button onClick={() => this._initComponent(name)} color={'primary'} outline={true}><FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon></Button>}
                    {name !== `timeline` && <Button onClick={() => this.setState({ selectedRow: row, isDeleteModalOpen: true })} color={'primary'} outline={true}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button>}
                </>
            }
        </div>
    }

    _folderColumnFormatter = (props) => {
        const { row } = props;
        const { name } = row;
        return <div>
            {
                name !== '' &&
                <>
                    {name === `timeline` && <Button onClick={() => this._initComponent(name)} color={'primary'} outline={true}><FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon></Button>}
                </>
            }
        </div>
    }

    _selectColumnFormatter = (props) => {
        const { row } = props;
        const { name } = row;
        return <>
            {
                name !== '' &&
                <>
                    {name === `timeline` && <Button onClick={() => this._initComponent(name)} color={'primary'} outline={true}><FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon></Button>}
                    {name !== `timeline` && <Button onClick={() => this.props.onSelect(row)} color={'primary'} outline={true}><span>Select</span></Button>}
                </>
            }
        </>
    }

    _imageColumnFormatter = (props) => {
        const { collection, id, cell } = props;
        const url = `assetto/${cell}`;
        return <div style={{ height: 'auto', width: '100px' }}>
            {
                props.cell !== '' &&
                <>
                    {props.cell === `${collection}/${id}/resources/timeline` && <FontAwesomeIcon size="2x" icon={faFolder}></FontAwesomeIcon>}
                    {props.cell !== `${collection}/${id}/resources/timeline` && <Img {...props} style={{ width: '75px', height: '75px', objectFit: 'cover' }} className="img-fluid" src={`${url}`} alt='alt'></Img>}
                </>
            }
        </div>
    }

    _initComponent = (path = '') => {
        const { id } = this.props;
        const { breadcrumbs } = this.state;
        this.setState({ isloading: true });
        const columns = [{ dataField: 'path', text: 'Preview', sort: true, formatter: (p) => this._imageColumnFormatter({ ...this.props, cell: p }) },
        { dataField: 'name', text: 'Name', sort: true }];

        let crumbs = [...breadcrumbs];
        if (path !== '') {
            if (crumbs.indexOf(path) === -1) {
                crumbs.push(path);
            }
        }
        else {
            crumbs = crumbs.splice(0, 1);
        }

        this._assettoService.getAssetResources(id, path).then((response) => {
            const tableData = response;
            let tableColumns = columns.filter((column) => column.dataField !== 'action' && column.dataField !== 'select' && column.dataField !== 'folder');
            if (this.props.onSelect) {
                const selectColumn = !this.props.multi ?
                    { dataField: 'select', align: 'right', text: '', sort: false, formatter: (cell, row) => this._selectColumnFormatter({ ...this.props, cell: cell, row: row }) } :
                    { dataField: 'folder', align: 'right', text: '', sort: false, formatter: (cell, row) => this._folderColumnFormatter({ ...this.props, cell: cell, row: row }) };
                tableColumns.push(selectColumn);
            }
            else {
                tableColumns.push({ dataField: 'action', align: 'right', text: '', sort: false, formatter: (cell, row) => this._actionColumnFormatter({ ...this.props, cell: cell, row: row }) })
            }
            this.setState({ isLoading: false, tableData: tableData, tableColumns: tableColumns, breadcrumbs: crumbs });
        }).catch((error) => {
            this.setState({ isLoading: false, tableData: [], tableColumns: columns });
        })
    }

    _onDismss = () => {
        this.setState({ isDeleteModalOpen: false, isFileUploadModalOpen: false });
    }

    _onConfirmDelete = () => {
        const { selectedRow } = this.state;
        const { id } = this.props;
        this.setState({ isLoading: true });
        const relativePath = selectedRow && selectedRow.relativePath ? selectedRow.relativePath : undefined

        if (relativePath !== undefined) {
            this._assettoService.deleteAssetResource(id, relativePath).then((response) => {
                const { tableData } = this.state;
                const newData = tableData.filter((data) => data.relativePath !== relativePath);
                this.setState((state) => {
                    return {
                        ...state,
                        tableData: newData,
                        isLoading: false,
                        isDeleteModalOpen: false
                    }
                })
            }).catch((error) => {
                console.log(error);
                this.setState((state) => {
                    return {
                        ...state,
                        isLoading: false,
                    }
                });
                Toastr("error", "Error", "Something went wrong, try again later.")
            })
        }
    }

    rowEvents = {
        onClick: (e, row, rowIndex) => {
        }
    };

    _onAddFiles = (selectedfiles) => {
        if (selectedfiles && selectedfiles.length > 0) {
            selectedfiles.forEach((file, index) => {
                var reader = new FileReader();
                reader.onload = (e) => {
                    const file = {
                        src: e.target.result,
                        file: selectedfiles[index]
                    }
                    this.setState((prevState) => ({ files: [...prevState.files, file] }));
                }
                reader.readAsDataURL(file); // convert to base64 string
            })
        }
        else {
            this.setState({ files: [] });
        }
    }

    _onRemoveFile = (fileName) => {
        const { files } = this.state;
        const newFiles = files.filter((f) => f.file.name !== fileName);
        this.setState({ files: newFiles });
    }

    _getBodyRequest = (files) => {
        let formData = new FormData();
        const formfiles = [...files];
        formfiles.forEach((file) => {
            formData.append('resources', file.file)
        })
        return formData;
    }

    _readFileArray = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const binaryFile = {
                    type: file.file.type,
                    name: file.file.name,
                    data: new Int8Array(e.target.result)
                }
                resolve(binaryFile);
            }
            reader.readAsArrayBuffer(file.file);
        })
    }


    _getBinaryFiles = () => {
        const { files } = this.state;
        const promiseArray = [];
        const promise = new Promise((resolve) => {
            if (files && files.length > 0) {
                files.forEach((f) => {
                    promiseArray.push(this._readFileArray(f))
                })
            }

            Promise.all(promiseArray).then((responses) => {
                resolve(responses);
            });
        })
        return promise;
    }

    _onSave = () => {
        const { id } = this.props;
        this.setState({ isLoading: true });
        const { files } = this.state;
        const requestBody = this._getBodyRequest(files);
        this._assettoService.addAssetResources(id, requestBody).then((response) => {
            this.setState({ isLoading: false })
            Toastr("success", "Uploaded", "file(s) was successfully uploaded")
            this.setState({ isFileUploadModalOpen: false })
            this._initComponent();

        }).catch((error) => {
            this.setState({ isLoading: false })
            Toastr("error", "Error", "Something went wrong, try again later.")
            console.log(error);
        });
    }

    _onMultiSelect = () => {
        const { selectedRows } = this.state;
        if (this.props.onSelect) {
            this.props.onSelect(selectedRows);
        }
    }


    render() {
        const { isLoading, files, isFileUploadModalOpen, tableColumns, tableData, selectedRow, selectedRows, isDeleteModalOpen,breadcrumbs } = this.state;
        const id = selectedRow && selectedRow.name ? selectedRow.name : '';

        const selectRow = this.props.multi ? {
            mode: 'checkbox',
            clickToSelect: false,
            hideSelectColumn: false,
            nonSelectable: ['timeline'],
            onSelect: (row, isSelect, rowIndex, e) => {
                const newRows = isSelect ? selectedRows.concat(row) : selectedRows.filter((r) => r.name !== row.name);
                this.setState(state => {
                    return {
                        ...state,
                        selectedRows: newRows
                    }
                })
            },
            onSelectAll: (isSelect, rows, e) => {
                this.setState(state => {
                    return {
                        ...state,
                        selectedRows: isSelect ? rows : []
                    }
                })
            }
        } : { hideSelectColumn: true, mode: 'radio' };

        return <div>
            {isLoading && <Loader></Loader>}
            <Container fluid={true} className="px-0 py-2">
                {breadcrumbs && breadcrumbs.length > 1 &&
                <Breadcrumb listClassName="bg-transparent p-0" style={{ backgroundColor: 'transparent' }} listTag="div" tag="nav">
                    {
                        breadcrumbs.map((crumb, index) => {
                            return <BreadcrumbItem key={`breadcrumb_${index}`}>
                                <Button color={'link'} className="text-capitalize p-0 shadow-none" onClick={() => this._initComponent(index !== 0 ? crumb : '')}>{crumb}</Button>
                                </BreadcrumbItem>
                        })
                    }
                </Breadcrumb>
                }
                <Row>
                    <Col sm="12">
                        {
                            tableColumns && tableColumns.length > 0 &&
                            <div>
                                {this.props.multi && <span className="float-left"><Button disabled={selectedRows.length === 0} onClick={() => this._onMultiSelect()} color='primary'> <FontAwesomeIcon className="mr-2" icon={faCheck} /> Select</Button></span>}
                                <DataTable
                                    keyField="name"
                                    hasSearch={true}
                                    selectRow={selectRow}
                                    columns={tableColumns}
                                    data={tableData}
                                    buttons={[<Button color={'primary'} outline={true} onClick={() => this.setState({ isFileUploadModalOpen: true })}><FontAwesomeIcon className="mr-2" icon={faUpload}></FontAwesomeIcon>Add file</Button>]}>
                                </DataTable>
                            </div>
                        }
                    </Col>
                </Row>
                <DeleteModal id={id} isOpen={isDeleteModalOpen} onConfirm={this._onConfirmDelete} onCancel={this._onDismss}></DeleteModal>
                <FileUploadPanel
                    files={files}
                    isOpen={isFileUploadModalOpen}
                    onChange={this._onAddFiles}
                    onSave={this._onSave}
                    onRemove={this._onRemoveFile}
                    onCancel={() => this.setState({ isFileUploadModalOpen: false })}
                ></FileUploadPanel>
            </Container>
        </div>
    }
}


const MainImage = (props) => {
    const url = props && props.src !== undefined ? `assetto/${props.src}` : undefined;
    return <div style={{ textAlign: 'center' }}><ThumbImg {...props} className="img-fluid" src={url} alt='alt'></ThumbImg></div>
}

export const SelectAssetResources = (props) => {

    const [state,setState] = useState({data:[]});
    const [selectedRowIds, setSelectedRowIds] = React.useState({});
        const columns = useMemo(() => {
            return [
                { accessor: 'path', disableSortBy: true, Header: 'Image', style: { width: 60 }, Cell: ({ value }) => <MainImage apiClient={props?.apiClient} src={value}> </MainImage> },
                { accessor: 'name', Header: 'Name' }
            ];

        },[props?.apiClient])



        useEffect(() => {
            if(props?.apiClient && props?.collection && props?.id)
            {
                const service =  new AssettoService(props.apiClient, props?.collection);
                service.getAssetResources(props?.id).then((response) => {
                    setState((prevState) => {
                        return {
                            ...prevState,
                            data: response
                        }
                    })
                    

                }).catch((error) => {
                    
                })
            }
        },[props?.apiClient, props?.collection, props?.id])


    const _onDismss = () => {
        const {onDismiss} = props;
        if(onDismiss)
        {
            onDismiss();
        }
    }

    const _onSelect = () => {
        const {onSelect} = props;
        const items = state?.data?.filter((d, index) => selectedRowIds.indexOf(index) > -1);
        if(onSelect)
        {
            onSelect(items)
        }
    }

    return <>
    <TableComponent {...props}  type={'select'}  onChecked={setSelectedRowIds}  columns={columns} data={state?.data || []}></TableComponent>
        <Row className='pt-4 pb-2'>
            <Col className='text-right'><Button className='mr-2' onClick={() => _onDismss()}>Cancel</Button>
                <Button color={'primary'} onClick={() => _onSelect()}>Select</Button>
            </Col>
        </Row>
    
     
    </>
}