import React from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FormField } from '../../../../../../components/Youmoni/FormFields/FormField';
import { DominoService } from '../../../../../../services/dominoService';
import { validateFormInputs } from '../../../../../../utils/formValidator';
import Loader from '../../../../../../components/Loader';
import _ from 'lodash';
import Toastr from '../../../../../../components/Youmoni/Toastr';

import { FormsService } from '../../../../../../services/formsService';

export class EditMembership extends React.Component {
    constructor(props) {
        super();
        this.state = {
            isLoading:true,
            data: {},
            fields: [],
            fieldErrors: {},
        }

        this._dominoService = new DominoService(props.apiClient);
        this._formsService = new FormsService(props.apiClient);
    }

    componentDidMount() {
        const { id } = this.props;
        const promises = [this._dominoService.getMembership(id), this._formsService.getForms('memberships')]

        Promise.allSettled(promises).then(([membershipResponse, formsResponse]) => {

            const data = membershipResponse.status === 'fulfilled' ? membershipResponse.value : {}
            const formFields = formsResponse.status === 'fulfilled' ? formsResponse.value?.editForm || [] : []
            this.setState({ data: data, fields: formFields, isLoading: false });

        })
            .catch((error) => {
                Toastr('error', 'Something went wrong', 'Please try again later.')
            })
    }


    _onValueChanged(fieldName, newValue) {
        const { fields } = this.state;
        const currentField = fields.filter((fieldItem) => fieldItem.name === fieldName)[0];
        const fieldError = currentField.required && !newValue ? { invalid: true, message: currentField.requiredMessage ? currentField.requiredMessage : 'Field is required' } : { invalid: false, message: '' };
        this.setState((prevState, props) => {
            return {
                ...prevState,
                data: { ...prevState.data, [fieldName]: newValue },
                fieldErrors: {
                    ...prevState.fieldErrors, [fieldName]: fieldError
                }
            }
        });
    }

    _onSave = () => {
        const {id} = this.props;
        const {data, fields} = this.state;
        const form = validateFormInputs(data,fields);


        if (!form.isValid) {
            this.setState((prevState) => {
                return { ...prevState, fieldErrors: form.errors }
            });
            return;
        }

        let request = {};
        fields.forEach((field) => {
            if (form?.data?.[field.name] !== undefined) {
                request[field.name] = form?.data?.[field.name]
            }

        })

        this._dominoService.patchMembership(id, request).then((response) => {

            if(this.props?.onSuccess)
            {
                this.props?.onSuccess(response);
            }
        }).catch((error) => {
            Toastr('error','Somehing went wrong', "Could not update membership, please try again later");
        })

    }

    _onCancel = () => {
        const { onDismiss } = this.props;
        if (onDismiss) {
            onDismiss()
        }
    }

    render() {
        const { isLoading, data, fieldErrors, fields, } = this.state;
        return (<div className='py-2'>
            {isLoading && <Loader></Loader>}
            <Row>
                {
                    fields.map((field, index) => {
                        return <Col sm="12" className='pb-2' key={`input_${index}`}>
                            {field?.type !== 'tree' && <FormField
                                key={`field_${index}`}
                                {...field}
                                value={data[field.name]}
                                onChange={(value) => this._onValueChanged(field.name, value)}
                                invalid={fieldErrors[field.name] !== undefined ? fieldErrors[field.name].invalid : false}
                                errorMessage={fieldErrors[field.name] !== undefined ? fieldErrors[field.name].message : ''}
                            ></FormField>}
                        </Col>
                    })
                }
            </Row>
            <Row><Col>
            </Col></Row>
            <Row>
                <Col sm="12" className={'text-right'}>
                    <Button className='mr-2' onClick={() => this._onCancel()}>Cancel</Button>
                    <Button onClick={() => this._onSave()} color="primary">Save</Button>
                </Col>
            </Row>
        </div>)
    }
}

export default EditMembership;