import React from "react";
import async from "../components/Async";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarth} from "@fortawesome/free-solid-svg-icons";

const Locations = async(() => import("../tenants/_base/Location/Locations"));

const routes = [{
    path: "/locations",
    name: "Locations",
    icon: <FontAwesomeIcon icon={faEarth}></FontAwesomeIcon>,
    component: Locations,
    facet:'locations'
  }
]

 export const locationRoutes = routes ;