import React, { useEffect, useRef, useState ,useCallback} from 'react'
import  { default as ReactMapGL, GeolocateControl, NavigationControl, FullscreenControl } from 'react-map-gl'
import {faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
// eslint-disable-next-line
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import mapboxgl from 'mapbox-gl';
import { maxBy, minBy } from 'lodash';
mapboxgl.workerClass = MapboxWorker;

const MAP_CONFIG = {
    maxZoom: 20,
    mapStyle: "mapbox://styles/mapbox/light-v9",
    mapboxAccessToken: "pk.eyJ1Ijoib2RkZSIsImEiOiJjamplZnEwYmw0b2llM3ZsZmpvaG9zam53In0.D4zjizzS4qgVnfBmwioadA"
};

export class MapboxGLButtonControl {
    constructor({ className = "", title = "", eventHandler = null, icon = null }) {
        this._className = className;
        this._title = title;
        this._eventHandler = eventHandler;
        this._icon = icon;
    }

    onAdd(map) {
        this._span = document.createElement("span");
        this._span.className = "mapboxgl-ctrl-icon d-flex justify-content-center";
        if (this._icon.icon && this._icon.icon.length === 5) {
            const viewBox = `0 0 ${this._icon.icon[0]} ${this._icon.icon[1]}`
            this._svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
            this._path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
            this._svg.setAttribute('width', 15);
            this._svg.setAttribute('height', 15)
            this._svg.setAttribute('viewBox', viewBox);
            this._svg.setAttribute('class', 'align-self-center');
            this._path.setAttribute('d', this._icon.icon[4]);
            this._svg.appendChild(this._path);
            this._span.appendChild(this._svg);
        }

        this._btn = document.createElement("button");
        this._btn.className = this._className;
        this._btn.type = "button";
        this._btn.title = this._title;
        this._btn.onclick = this._eventHandler;
        this._btn.appendChild(this._span);

        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}

export const Map = (props) => {
    const { children } = props;
    const [cursor, setCursor] = useState('auto');
    const mapRef = useRef();
    const containerRef = useRef(null);

    const onMouseEnter = useCallback(() => setCursor('pointer'), []);
    const onMouseLeave = useCallback(() => setCursor('auto'), []);


    useEffect(() => {
        if (Array.isArray(props.bounds) && props.bounds.length === 2) {
            let padding = 100;
            const width = containerRef.current ? containerRef.current.clientWidth : undefined
            const height = containerRef.current ? containerRef.current.clientHeight : undefined;
            padding = width !== undefined && height !== undefined && width > 200 && height > 200 ? padding : 0;

            mapRef?.current?.fitBounds(
                props.bounds,
                {maxZoom:12, padding: padding, duration: 1000 }
            );
        }
    }, [props.bounds]);


    const _onMapClick = (evt) => {
        const { onMapClick } = props;
        if (onMapClick) {
            onMapClick(evt);
        }
    }


    const _onLoad = (evt) => {
        const { onMapLoaded, onFitToBounds } = props;
        const map = mapRef?.current?.getMap()
        if (map) {

            if (onFitToBounds) {
                const fitToBounds = new MapboxGLButtonControl({
                    title: "Fit to Bounds",
                    icon: faMapMarkerAlt,
                    eventHandler: () => { onFitToBounds()}
                });
                map.addControl(fitToBounds, 'top-left');
            }
        }
        if (onMapLoaded) {
            onMapLoaded()
        }
    }

    const height = props?.height || '70vh';
    return (
        
        <div ref={containerRef} className="map">
       <ReactMapGL ref={mapRef}
                {...MAP_CONFIG} 
                style={{width:'100%',minHeight:'70vh', height:height}}
                interactiveLayerIds={Array.isArray(props.layerIds) ? props.layerIds : []}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onLoad={(evt) => _onLoad(evt)}
                onClick={(evt) => _onMapClick(evt)}
                cursor={cursor}>
                    <FullscreenControl position='top-left' className="map-ctrl position-relative" container={document.querySelector('map')} />
                    <NavigationControl position='top-left' className="mt-2 map-ctrl position-relative" />
                    <GeolocateControl position='top-left' className="mt-2 map-ctrl position-relative" positionOptions={{ enableHighAccuracy: true }} trackUserLocation={true} />
                <>
                    {children}
                </>
            </ReactMapGL>
        </div>
    );
}

export const getMinOrMax = (coords, minOrMax, latOrLng) => {
    if (minOrMax === "max") {
        return (maxBy(coords, value => value[latOrLng]))[latOrLng];
    } else {
        return (minBy(coords, value => value[latOrLng]))[latOrLng];
    }
};

export const getBounds = (coords) => {
    const maxLat = getMinOrMax(coords, "max", "lat");
    const minLat = getMinOrMax(coords, "min", "lat");
    const maxLng = getMinOrMax(coords, "max", "lng");
    const minLng = getMinOrMax(coords, "min", "lng");

    const southWest = [minLng, minLat];
    const northEast = [maxLng, maxLat];
    return [southWest, northEast];
};