import { faUser, faClock, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row } from 'reactstrap';
import Loader from '../../../../components/Loader';
import TableComponent, { getResourceData } from '../../../../components/Youmoni/DataTable/TableComponent';
import FormField from '../../../../components/Youmoni/FormFields/FormField';
import { ModalPanel } from '../../../../components/Youmoni/Panels/ModalPanel';
import Toastr from '../../../../components/Youmoni/Toastr';
import { AssettoService } from '../../../../services/assettoService';
import { DominoService } from '../../../../services/dominoService';
import { formFields, PreViewModal, _filterByAssignments } from '../Account/Memberships/Memberships/AddMembership';
import { find, findIndex } from 'lodash';
import { validateFormInputs } from '../../../../utils/formValidator';
import DisplayMembership from '../Account/Memberships/Memberships/DisplayMembership';

const _ = {
    find: find,
    findIndex: findIndex
}

const AddPanel = (props) => {
    const { data } = props;
    const [state, setState] = useState({ isLoading: true, data: {}, fieldErrors: {}, fields: [], templateField: undefined, templates: [], templateResources: {} });

    useEffect(() => {

        const dominoService = new DominoService(props.apiClient);
        dominoService.getMembershipTemplates().then((response) => {

            const templates = response?.entities && Array.isArray(response?.entities) ? response?.entities?.filter((item) => item?.accountId === props.apiClient?._tenant) : [];
            const templatesResources = Object.keys(response?.resources || {}).length > 0 ? response?.resources : {};
            const templateOptions = templates.filter((item) => _filterByAssignments(props?.tenant, item?.assignments, props?.collection)).map((template) => {
                return { value: template?.alias, label: template?.name }
            })

            let initialData = {};
            formFields.forEach((f) => {
                initialData[f.name] = null;
            })
            initialData[props?.collection] = { alias: data?.alias, name: data?.name };
            initialData["type"] = '';

            const templateField = {
                type: "select",
                name: 'type',
                label: 'Role',
                options: templateOptions,
                placeholder: "Template",
                required: true,
                requiredMessage: "Field is required"
            }


            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false,
                    templates: templates,
                    data: initialData,
                    templateResources: templatesResources,
                    templateField: templateField
                }
            })

        }).catch((error) => {
            setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false,
                }
            })
        })

    }, [props?.data, props?.apiClient, props?.tenant, data?.alias, data?.name, props?.collection]);


    const _onTemplateChange = (newValue) => {
        const { settings } = props;
        const { templateField, templates } = state;
        const fieldError = templateField.required && !newValue ? { invalid: true, message: templateField.requiredMessage ? templateField.requiredMessage : 'Field is required' } : { invalid: false, message: '' };
        const selectedTemplate = _.find(templates, (template) => template?.alias === newValue);


        const collections = settings?.collections ? settings.collections : [];
        const assignments = selectedTemplate?.assignments ? selectedTemplate?.assignments : [];
        let assets = {};

        const struct = collections.find((item) => item?.collection?.id === props?.data?.collection)?.hierarchy || '';
        const paths = struct.split('.')

        assignments.forEach((assignment) => {
            assignment.forEach((item) => {
                const resource = item?.resource;
                const url = new URL(resource)
                const resourceCollection = url?.pathname?.split('/').pop();
                const key = collections.find((item) => item?.collection?.id === resourceCollection)?.item?.id;
                const index = _.findIndex(paths, (path) => path === key);
                if (index > -1) {
                    const path = paths.slice(0, index + 1).join('.');
                    const asset = getResourceData(path, props?.data, props?.data?._resources)
                    if (asset) {
                        assets[asset?.collection] = { alias: asset?.alias, name: asset?.name };
                    }
                }
            })
        })



        const tempFields = [...formFields];

        setState((prevState) => {
            return {
                ...prevState,
                fields: tempFields,
                data: { ...prevState.data, type: newValue, ...assets },
                selectedTemplate: selectedTemplate,
                fieldErrors: { ...prevState.fieldErrors, type: fieldError }
            }
        });

    }

    const _onValueChanged = (fieldName, newValue) => {
        const { fields } = state;
        const currentField = fields.filter((fieldItem) => fieldItem.name === fieldName)[0];
        const fieldError = currentField?.required && !newValue ? { invalid: true, message: currentField.requiredMessage ? currentField.requiredMessage : 'Field is required' } : { invalid: false, message: '' };
        setState((prevState) => {
            return {
                ...prevState,
                data: { ...prevState.data, [fieldName]: newValue },
                fieldErrors: {
                    ...prevState.fieldErrors, [fieldName]: fieldError
                }
            }
        });
    }

    const _onPreview = () => {
        const { data, fields, templateField } = state;
        const form = validateFormInputs(data, [...fields, templateField]);

        if (!form.isValid) {
            setState((prevState) => {
                return { ...prevState, fieldErrors: form.errors }
            });
            return;
        }

        setState((prevState) => {
            return { ...prevState, isPreviewModalOpen: true }
        });

    }

    const _onDismiss = () => {
        setState((prevState) => {
            return { ...prevState, isPreviewModalOpen: false }
        });
    }

    const _onSave = () => {

        const { onDismiss, onReload } = props;
        const { data, selectedTemplate, templateField, fields } = state;
        const form = validateFormInputs(data, [...fields, templateField]);

        if (!form.isValid) {
            setState((prevState) => {
                return { ...prevState, fieldErrors: form.errors }
            });
            return;
        }

        const assignments = [];
        const templateAssignments = Array.isArray(selectedTemplate?.assignments) ? selectedTemplate?.assignments : [];
        let noOfassignments = 0;
        templateAssignments.forEach((assignment) => {
            assignment.forEach((item) => {
                if (item?.yri?.indexOf('assetto') > -1) {
                    noOfassignments += 1;
                    const property = item?.yri?.split('/').pop();
                    const value = data.hasOwnProperty(property) ? data[property] : undefined;
                    if (value?.alias)
                        assignments.push(`${item?.yri}/${value?.alias}`)
                }
            })

        })


        const request = {
            "principal": data?.email,
            "assignments": assignments,
            "firstName": data?.firstName,
            "lastName": data?.lastName
        }

        if (request?.assignments?.length === 0 || noOfassignments !== assignments.length) {
            return;
        }

        const service = new DominoService(props?.apiClient);
        setState((prevState) => {
            return { ...prevState, isLoading: true }
        });

        service.addMembershipInvitation(data?.type, request).then((response) => {

            setState((prevState) => {
                return { ...prevState, isLoading: false, isPreviewModalOpen: false }
            });

            if (onDismiss) {
                onDismiss()
            }
            if (onReload) {
                onReload()
            }

        }).catch((error) => {
            Toastr('error', "Something went wrong", 'Could not create membership, Please try again later')
            setState((prevState) => {
                return { ...prevState, isLoading: false, isPreviewModalOpen: false }
            });
        })
    }

    return <ModalPanel {...props} size={'md'} title={'Invite User'}>
        {state?.isLoading && <Loader></Loader>}
        <Row className='mt-2'>
            {state?.templateField && <Col className='pb-2' sm="12"><FormField
                {...state?.templateField}
                value={state?.data[state?.templateField.name]}
                onChange={(value) => _onTemplateChange(value)}
                invalid={state?.fieldErrors?.type !== undefined ? state?.fieldErrors?.type.invalid : false}
            ></FormField></Col>}
            {
                state?.fields.map((field, index) => {
                    return <Col sm="12" className='pb-2' key={`input_${index}`}>
                        <FormField
                            key={`field_${index}`}
                            {...field}
                            value={state?.data[field.name]}
                            onChange={(value) => _onValueChanged(field.name, value)}
                            invalid={state?.fieldErrors[field.name] !== undefined ? state?.fieldErrors[field.name].invalid : false}
                        ></FormField>
                    </Col>
                })
            }
        </Row>
        <Row>
            <Col sm="12" className={'text-right'}>
                <Button className='mr-2' onClick={() => props?.onDismiss()}>Cancel</Button>
                <Button onClick={() => _onPreview()} color="primary">Next</Button>
            </Col>
        </Row>
        {
            state?.isPreviewModalOpen && <PreViewModal isOpen={state?.isPreviewModalOpen} templateResources={state?.templateResources} data={state?.data} selectedTemplate={state?.selectedTemplate} onSave={() => _onSave()} onDismiss={() => _onDismiss()}></PreViewModal>
        }

    </ModalPanel>
}

const MemberMenu = (props) => {
    const { onChangeView, currentViewIndex, onAddInvite } = props
    return <>
        <Nav className='px-0 nav-buttons'>
            {<NavItem color={'primary'}>
                <NavLink active={currentViewIndex === 0} onClick={() => { onChangeView(0) }} className={'fw-bolder'}>
                    <FontAwesomeIcon className="mr-2" icon={faUser}></FontAwesomeIcon> <span></span> Users
                </NavLink>
            </NavItem>}
            <NavItem>
                <NavLink active={currentViewIndex === 1} onClick={(e) => { onChangeView(1) }}>
                    <FontAwesomeIcon className="mr-2" icon={faClock}></FontAwesomeIcon> Invites
                </NavLink>
            </NavItem>
            {onAddInvite && <NavItem className='px-2'>
                <Button color={'primary'} className='mt-1' onClick={(e) => { onAddInvite() }}>
                    <FontAwesomeIcon className="mr-2" icon={faUserCircle}></FontAwesomeIcon> Add Invite
                </Button>
            </NavItem>}
        </Nav>
    </>
}

const ViewPanel = (props) => {
    return <ModalPanel {...props} size={'md'} title={'Membership'}>
        <DisplayMembership {...props}></DisplayMembership>
    </ModalPanel>
}

const UserTable = (props) => {
    const tableColumns = [
        {
            Header: 'Id',
            accessor: 'userId',
        },
        {
            Header: 'Principal',
            accessor: 'principal',
        },
        {
            Header: 'Assignments', accessor: 'assignments', Cell: ({ value }) => {
                const assignments = value && Array.isArray(value) ? value : []
                const data = assignments.map((a) => {
                    const roleYri = a?.role?.split('#').shift();
                    const resourceYri = a?.resource?.split('#').shift();
                    const role = props?.tableResources?.[roleYri]?.name;
                    const resource = props?.tableResources?.[resourceYri]?.name;
                    return `${role}@${resource}`

                })
                return <>
                    {
                        data.map((assignment, index) => {
                            return <div className='text-nowrap' key={`a_${index}`}>{assignment}</div>
                        })
                    }
                </>
            }
        }
    ];


    return <TableComponent
        title={'Users'}
        hasSearch={true}
        columns={tableColumns}
        rowEvents={{ onClick: (row) => { props?.onRowClick(row) } }}
        data={props?.tableData || []}></TableComponent>
}

const InvitesTable = (props) => {
    const tableColumns = [
        {
            Header: 'Principal',
            accessor: 'principal',
        },
        {
            Header: 'Assignments', accessor: 'assignments', Cell: ({ value }) => {
                const assignments = value && Array.isArray(value) ? value : []
                const data = assignments.map((a) => {
                    const roleYri = a?.role?.split('#').shift();
                    const resourceYri = a?.resource?.split('#').shift();
                    const role = props?.tableResources?.[roleYri]?.name;
                    const resource = props?.tableResources?.[resourceYri]?.name;
                    return `${role}@${resource}`

                })
                return <>
                    {
                        data.map((assignment, index) => {
                            return <div className='text-nowrap' key={`a_${index}`}>{assignment}</div>
                        })
                    }
                </>
            }
        },
        {
            Header: 'Created',
            accessor: 'created',
            Cell: ({ value }) => {
                return <span className="text-nowrap d-flex align-items-center"><FontAwesomeIcon icon={faClock} className={`mr-1`}></FontAwesomeIcon>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>
            }
        }
    ];


    return <TableComponent
        title={'Invites'}
        hasSearch={true}
        columns={tableColumns}
        rowEvents={{ onClick: (row) => { props?.onRowClick(row) } }}
        data={props?.tableData || []}></TableComponent>
}

const SelectUserTable = (props) => {
    const tableColumns = [
        {
            Header: 'Principal',
            accessor: 'principal',
        },
        {
            Header: 'Assignments', accessor: 'assignments', Cell: ({ value }) => {
                const assignments = value && Array.isArray(value) ? value : []
                const data = assignments.map((a) => {
                    const roleYri = a?.role?.split('#').shift();
                    const resourceYri = a?.resource?.split('#').shift();
                    const role = props?.tableResources?.[roleYri]?.name;
                    const resource = props?.tableResources?.[resourceYri]?.name;
                    return `${role}@${resource}`

                })
                return <>
                    {
                        data.map((assignment, index) => {
                            return <div className='text-nowrap' key={`a_${index}`}>{assignment}</div>
                        })
                    }
                </>
            }
        }
    ];


    return <TableComponent
        title={'Users'}
        hasSearch={true}
        columns={tableColumns}
        rowEvents={{ onClick: (row) => { props?.onRowClick(row) } }}
        data={props?.tableData || []}></TableComponent>
}

export class AssetMembers extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: true,
            currentViewIndex: 0,
            tableData: [],
            tableColumns: [],
            tableResources: {},
            isAddPanelOpen: false,
            isViewPanelOpen: false,
            selectedRow: undefined,
            isDeleteModalOpen: false
        }

        this._assettoService = new AssettoService(props.apiClient, props.collection);
        this._dominoService = new DominoService(props.apiClient);
    }

    componentDidMount() {

        this._initComponent();
    }

    _initComponent = () => {
        const { data } = this.props;
        if (data['@identifier']) {
            const identifier = data['@identifier'];
            this._dominoService.getMembershipsByResource(identifier).then((response) => {
                const tableData = response?.entities && Array.isArray(response?.entities) ? response.entities : [];
                const tableResources = response?.resources && Object.keys(response?.resources || {}).length > 0 ? response?.resources : {};
                this.setState({ isLoading: false, tableData: tableData, tableResources: tableResources });

            }).catch((error) => {
                Toastr('error', 'Something went wrong!', 'Could not get members for this asset. Please try again later');
                this.setState({ isLoading: false })
            })
        }
        else {
            this.setState({ isLoading: false })
        }
    }


    _onDelete = () => {
        this.setState({ isLoading: true, isDeleteModalOpen: false })
        const { selectedRow } = this.state;

        this._dominoService.deleteMembership(selectedRow?.userUid).then((response) => {
            this.setState((prevState) => {
                const tempData = [...prevState.tableData];
                return {
                    ...prevState,
                    isLoading: false,
                    isDeleteModalOpen: false,
                    isViewPanelOpen: false,
                    selectedRow: undefined,
                    tableData: tempData.filter((item) => item?.principal !== selectedRow?.principal)
                }
            })

        }).catch((error) => {
            Toastr('error', "Something went wrong!", "Could not delete membership");
            this.setState((prevState) => {
                return {
                    ...prevState,
                    isLoading: false,
                }
            })
        })
    }

    render() {
        const { collection } = this.props;
        const { isLoading, isAddPanelOpen, isDeleteModalOpen, isViewPanelOpen, currentViewIndex, tableResources, selectedRow } = this.state;
        return <div>
            {isLoading && <Loader></Loader>}
            <Row className='border-bottom'>
                <Col className='px-0'>
                    <MemberMenu currentViewIndex={currentViewIndex} onAddInvite={() => this.setState({ isAddPanelOpen: true })} onChangeView={(index) => this.setState({ currentViewIndex: index })} ></MemberMenu>
                </Col>
            </Row>
            <Row>
                <Col>
                    {currentViewIndex === 0 && <UserTable {...this.props} onRowClick={(row) => this.setState({ isViewPanelOpen: true, selectedRow: row })} tableResources={tableResources} tableData={this.state?.tableData?.filter((d) => d?.status === 'accepted') || []}></UserTable>}
                    {currentViewIndex === 1 && <InvitesTable {...this.props} onRowClick={(row) => this.setState({ isViewPanelOpen: true, selectedRow: row })} tableResources={tableResources} tableData={this.state?.tableData?.filter((d) => d?.status?.type === 'pending') || []}></InvitesTable>}
                </Col>
            </Row>

            {isAddPanelOpen && <AddPanel {...this.props} isOpen={isAddPanelOpen} onReload={() => this._initComponent()} collection={collection} onDismiss={() => this.setState({ isAddPanelOpen: false })}></AddPanel>}
            {isViewPanelOpen && <ViewPanel {...this.props} data={selectedRow} isOpen={isViewPanelOpen} onDelete={() => this.setState({ isDeleteModalOpen: true })} onDismiss={() => this.setState({ isViewPanelOpen: false })} />}
            {isDeleteModalOpen && <Modal centered isOpen={isDeleteModalOpen} toggle={() => this.setState({ isDeleteModalOpen: false })}>
                <ModalHeader toggle={() => this.setState({ isDeleteModalOpen: false })}>Delete Membership</ModalHeader>
                <ModalBody>
                    Are you sure you want to delete membership for  <strong>{selectedRow?.principal}</strong>?
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => this.setState({ isDeleteModalOpen: false })}> Cancel</Button>
                    <Button color={'primary'} onClick={() => this._onDelete()}> Delete</Button>
                </ModalFooter>
            </Modal>}
        </div>
    }
}

export class SelectAssetMember extends React.Component {
    constructor(props) {
        super()
        this.state = {
            isLoading: true,
            currentViewIndex: 0,
            tableData: [],
            tableColumns: [],
            tableResources: {},
            isAddPanelOpen: false,
            isViewPanelOpen: false,
            selectedRow: undefined,
            isDeleteModalOpen: false
        }

        this._assettoService = new AssettoService(props.apiClient, props.collection);
        this._dominoService = new DominoService(props.apiClient);
    }

    componentDidMount() {

        this._initComponent();
    }

    _initComponent = () => {
        const { data } = this.props;
        if (data?.['@identifier']) {
            const identifier = data['@identifier'];
            this._dominoService.getMembershipsByResource(identifier).then((response) => {
                const tableData = response?.entities && Array.isArray(response?.entities) ? response.entities : [];
                const tableResources = response?.resources && Object.keys(response?.resources || {}).length > 0 ? response?.resources : {};
                this.setState({ isLoading: false, tableData: tableData, tableResources: tableResources });

            }).catch((error) => {
                Toastr('error', 'Something went wrong!', 'Could not get members for this asset. Please try again later');
                this.setState({ isLoading: false })
            })
        }
        else {
            this.setState({ isLoading: false })
        }
    }

    _onRowClick = (row) => {
        const { onSelected } = this.props;
        if (onSelected) {
            onSelected(row);
        }
    }


    render() {
        const { isLoading, tableResources } = this.state;
        return <div>
            {isLoading && <Loader></Loader>}
            <Row>
                <Col>
                    <SelectUserTable {...this.props} onRowClick={(row) => this._onRowClick(row)} tableResources={tableResources} tableData={this.state?.tableData || []}></SelectUserTable>
                </Col>
            </Row>
        </div>
    }
}