import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

export const FaIcon = (props) => {
    const { icon, ...rest } = props;
    return <>
        {library?.definitions?.fas?.hasOwnProperty(icon) && <FontAwesomeIcon {...rest} icon={icon}></FontAwesomeIcon>}
    </>
}