import { faFile, faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState, useEffect } from 'react';
import { ResourceService } from '../../services/resourceService';
import { Spinner } from 'reactstrap'

const imageMimeTypes = ['image/apng',
  'image/bmp',
  'image/gif',
  'image/x-icon',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp']

export const Img = (props) => {
  const [data, setData] = useState(null);
  const [isLoding, setIsLoading] = useState(true);
  useEffect(() => {
    let isSubscribed = true
    const resourceService = new ResourceService(props.apiClient);
    if (resourceService && resourceService._apiClient) {
      resourceService.getResource(props.src).then((response) => {
        if (isSubscribed) {
          setIsLoading(false)
          setData(response);
        }
      }).catch((error) => {
        if (isSubscribed)
          setIsLoading(false);
      });
    }

    return () => (isSubscribed = false)
  }, [props.apiClient, props.src]);

  return (
    <Fragment>
      {isLoding && <div className={'d-flex justify-content-center align-items-center'}><div className="d-flex"><Spinner color="primary"></Spinner></div></div>}
      {data && data.src !== '' && imageMimeTypes.indexOf(data.type) > -1 &&
        <img src={data.src} style={props.style !== undefined ? props.style : {}} className={props.className !== undefined ? props.className : ''} alt={props.alt} />
      }
      {data && data.src !== '' && imageMimeTypes.indexOf(data.type) === -1 &&
        <div className={props.className !== undefined ? props.className : ''}>
          <FontAwesomeIcon size="2x" icon={faFile} style={props.style !== undefined ? props.style : {}} className={props.className !== undefined ? props.className : ''} alt={props.alt} />
        </div>
      }
    </Fragment>
  );
}

export const FluidImg = (props) => {
  const [data, setData] = useState(null);
  const [isLoding, setisLoading] = useState(true);
  useEffect(() => {
    let isSubscribed = true
    const resourceService = new ResourceService(props.apiClient);
    if (resourceService && resourceService._apiClient) {
      resourceService.getResource(props.src).then((response) => {
        if (isSubscribed) {
          setisLoading(false)
          setData(response);
        }
      }).catch((error) => {
        if (isSubscribed)
          setisLoading(false);
      });
    }

    return () => (isSubscribed = false)
  }, [props.apiClient, props.src]);

  return (
    <Fragment>
      {isLoding && <div className={'h-100 d-flex justify-content-center align-items-center'}><div className="d-flex"><Spinner color="primary"></Spinner></div></div>}
      {data && data.src !== '' && imageMimeTypes.indexOf(data.type) > -1 &&
        <div className={'h-100 w-100 text-center'} style={{ minHeight: '200px', backgroundImage: `url('${data.src}')`, backgroundPosition: 'center center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}></div>
      }
      {data && data.src !== '' && imageMimeTypes.indexOf(data.type) === -1 &&
        <div className={props.className !== undefined ? props.className : ''}>
          <FontAwesomeIcon size="2x" icon={faFile} style={props.style !== undefined ? props.style : {}} className={props.className !== undefined ? props.className : ''} alt={props.alt} />
        </div>
      }
    </Fragment>
  );
}

export const CoverImg = (props) => {
  const [data, setData] = useState(null);
  const [isLoding, setisLoading] = useState(true);
  useEffect(() => {
    let isSubscribed = true
    const resourceService = new ResourceService(props.apiClient);
    if (resourceService && resourceService._apiClient) {
      resourceService.getResource(props.src).then((response) => {
        if (isSubscribed) {
          setisLoading(false)
          setData(response);
        }
      }).catch((error) => {
        if (isSubscribed)
          setisLoading(false);
      });
    }

    return () => (isSubscribed = false)
  }, [props.apiClient, props.src]);

  return (
    <Fragment>
      {isLoding && <div className={'h-100 d-flex justify-content-center align-items-center'} style={{ minHeight: 250}}><div className="d-flex"><Spinner color="primary"></Spinner></div></div>}
      {data && data.src !== '' && imageMimeTypes.indexOf(data.type) > -1 &&
        <div className={'h-100 w-100 text-center'} style={{ minHeight: 250, backgroundImage: `url('${data.src}')`, backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}></div>
      }
      {data && data.src !== '' && imageMimeTypes.indexOf(data.type) === -1 &&
        <div className={props.className !== undefined ? props.className : ''}>
          <FontAwesomeIcon size="2x" icon={faFile} style={props.style !== undefined ? props.style : {}} className={props.className !== undefined ? props.className : ''} alt={props.alt} />
        </div>
      }
    </Fragment>
  );
}

export const RoundImg = (props) => {
  const [data, setData] = useState(null);
  const [isLoding, setisLoading] = useState(true);
  useEffect(() => {
    let isSubscribed = true
    const resourceService = new ResourceService(props.apiClient);
    if (resourceService && resourceService._apiClient) {
      resourceService.getResource(props.src).then((response) => {
        if (isSubscribed) {
          setisLoading(false)
          setData(response);
        }
      }).catch((error) => {
        if (isSubscribed)
          setisLoading(false);
      });
    }

    return () => (isSubscribed = false)
  }, [props.apiClient, props.src]);

  return (
    <Fragment>
      {isLoding && <div className={'h-100 d-flex justify-content-center align-items-center'}><div className="d-flex"><Spinner color="primary"></Spinner></div></div>}
      {data && data.src !== '' && imageMimeTypes.indexOf(data.type) > -1 &&
        <div className={'h-100 d-flex justify-content-center align-items-center'}>
          <div className="rounded-circle border pt-3 pb-3 px-3 border-2 bg-light border-light" style={{ width: '200px', height: '200px', margin: 'auto', backgroundImage: `url(${data.src})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
        </div>}
    </Fragment>
  );
}

export const ThumbImg = (props) => {
  const [data, setData] = useState(null);
  const [isLoding, setisLoading] = useState(true);
  useEffect(() => {
    let isSubscribed = true
    const resourceService = new ResourceService(props.apiClient);
    if (resourceService && resourceService._apiClient && props.src !== undefined && props.src !== '') {
      resourceService.getResource(props.src).then((response) => {
        if (isSubscribed) {
          setisLoading(false)
          setData(response);
        }
      }).catch((error) => {
        if (isSubscribed)
          setisLoading(false);
      });
    }
    else {
      if (isSubscribed) {
        setisLoading(false)
      }
    }

    return () => (isSubscribed = false)
  }, [props.apiClient, props.src]);

  return (
    <Fragment>
      {isLoding && <div className={'h-100 d-flex justify-content-center align-items-center'}><div className='rounded-circle pt-3 pb-3 px-3' style={{ width: 50, height: 50 }}><Spinner size={'sm'} color="primary"></Spinner></div></div>}
      {data && data.src !== '' && imageMimeTypes.indexOf(data.type) > -1 &&
        <div className={'h-100 d-flex justify-content-center align-items-center'}>
          <div className="rounded-circle border-1 bg-light border-light" style={{ width: 50, height: 50 }}>
            <img className="rounded-circle overflow-hidden" src={data.src} style={{ width: 50, height: 50, objectFit: 'cover' }} alt={props.alt} />
          </div>
        </div>}
      {
        data === null && isLoding === false &&
        <div style={{ display: 'flex' }} className="h-100 text-center justify-content-center align-items-center">
          <div style={{ backgroundColor: '#eee', width: 50, height: 50, margin: 'auto' }} className="rounded-circle border pt-3 pb-3 px-3 border-2 border-light">
            <FontAwesomeIcon
              className="h-100 justify-content-center align-items-center"
              icon={faImage}

            ></FontAwesomeIcon>
          </div>
        </div>
      }
    </Fragment>
  );
}

export const PreviewImg = (props) => {
  const [data, setData] = useState(null);
  const [isLoding, setisLoading] = useState(true);
  useEffect(() => {
    let isSubscribed = true
    const resourceService = new ResourceService(props.apiClient);
    if (resourceService && resourceService._apiClient && props.src !== undefined && props.src !== '') {
      resourceService.getResource(props.src).then((response) => {
        if (isSubscribed) {
          setisLoading(false)
          setData(response);
        }
      }).catch((error) => {
        if (isSubscribed)
          setisLoading(false);
      });
    }
    else {
      if (isSubscribed) {
        setisLoading(false)
      }
    }

    return () => (isSubscribed = false)
  }, [props.apiClient, props.src]);


  const _onDownload = (src) => {
    window.open(src)
  }



  return (
    <Fragment>
      {isLoding && <div><div className='rounded-circle pt-3 pb-3 px-3' style={{ width: 50, height: 50 }}><Spinner size={'sm'} color="primary"></Spinner></div></div>}
      {data && data.src !== '' && imageMimeTypes.indexOf(data.type) > -1 &&
        <div>
          <img alt='timeline' onClick={() => _onDownload(data?.src)} className="img-fluid img-thumbnail mr-2 cursor-pointer" style={{ width: '200px', height: '200px', objectFit: 'cover' }} src={data.src} ></img>
        </div>}
    </Fragment>
  );
}

