import React, { useState } from 'react'
import { Button, Collapse,  } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faEllipsisH } from "@fortawesome/free-solid-svg-icons";

const defaultContainer = ({ children }) => <div className="mapbox-control-panel-wrapper">{children}</div>;

export const MapPanel = (props) => {
  const Wrapper = props.containerComponent || defaultContainer;
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => setIsOpen(!isOpen);

  return <Wrapper>
    {!isOpen && <Button outline={true} color='primary' className={'position-relative float-right'} style={{ margin: '10px' }} onClick={() => toggle(!isOpen)}><FontAwesomeIcon icon={faEllipsisH}></FontAwesomeIcon></Button>}
    <Collapse isOpen={isOpen}>
      <div className={'control-panel'}>
      <div className={'float-right'}><Button close onClick={() => toggle(!isOpen)}></Button></div>
          <MapPanelRender {...props}></MapPanelRender>
          {props.children}
      </div>
    </Collapse>
  </Wrapper>
}

const MapPanelRender = props => props.render();

MapPanel.defaultProps = {
  render: () => null,
};