import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {Link} from 'react-router-dom';
import { Badge } from 'reactstrap';
import AwesomeIcon from '../components/Youmoni/AwesomeIcon';
import ColumnToolTip from '../components/Youmoni/ColumnToolTip';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export default class ColumnFormatter {

    getFormatter = (type) => {
        if(type === "link")
        {
            return this.linkFormatter;
        }
        if(type === "icon")
        {
            return this.iconStatusFormatter;
        }
        if(type === "href")
        {
            return this.hrefFormatter;
        }
        if(type === 'tel'){
            return this.telFormatter;
        }
    }
    telFormatter = (cell, row, rowIndex, formatExtraData) => {
        let href = `tel:${cell}`; 
        return <a onClick={(e) => e.stopPropagation()} href={href}>{cell}</a>
    }

    hrefFormatter = (cell, row, rowIndex, formatExtraData) => {
        let href = `${cell}`;
        return <a onClick={(e) => e.stopPropagation()} href={href}>{cell}</a>
      }

    linkFormatter = (cell, row, rowIndex, formatExtraData) => {
        let identifier = formatExtraData.identifier;
        let to = `${formatExtraData.to}/${row[identifier]}`;
        return <Link to={to}>{cell}</Link>
      }

    iconStatusFormatter = (cell, row, rowIndex, formatExtraData) => {
        let status = cell;
        let iconName = formatExtraData.iconName;
        let className = "text-success";
        switch (status) {
            case 'danger':
                className = 'text-danger'
                break;
            case 'warning':
                className = 'text-warning'
                break;
            default:
                className = 'text-success'
                break;
        }

        return <span><AwesomeIcon className={`${className}`} iconName={`${iconName}`} /></span>;
      }
}

export const IconBadgeFormatter = (cell, row, rowIndex, formatExtraData) => {
    const icon = formatExtraData.icon;
    const cssClass = cell !== undefined ? `text-${cell.status}` : '';
    const color = cell !== undefined ? cell.status : '';
    const value = cell !== undefined ? cell.value : '-';
   
    return <span style={{whiteSpace:'nowrap'}} id={`cell_${rowIndex}`}>
      <FontAwesomeIcon className={`${cssClass}`} icon={icon} />
      <Badge color={color} className={`ml-2`}> {value} </Badge>
    </span>;
  }

export const LastSeenFormatter = (cell, row, rowIndex, formatExtraData) => {
    const icon = formatExtraData && formatExtraData.icon ?  formatExtraData.icon : null;
    const key =  uuidv4();
    const cssClass = cell !== undefined ? `text-${cell.status}` : '';
    const color = cell !== undefined ? cell.status : '';
    const value = cell !== undefined && cell.value !== '' ? moment(cell.value).fromNow() : 'no-data';

    const locale = window.navigator.userLanguage || window.navigator.language
    const tooltip = cell !== undefined ? moment(cell.value).locale(locale).format('YYYY-MM-DD HH:mm:ss') : '';
  
    return <span style={{whiteSpace:'nowrap'}} id={`cell_${key}_${rowIndex}`}>
      {
        icon && <FontAwesomeIcon className={`${cssClass}`} icon={icon} />
      }
      
      <Badge color={color} className={`ml-2`}> {value} </Badge>
      {
        value !== 'no-data' &&
        <ColumnToolTip id={`cell_${key}_${rowIndex}`}>
        {tooltip}
      </ColumnToolTip>
      }
      
    </span>;
}

export const ImageFormatter = (cell, row, rowIndex, formatExtraData) => {
  return <div className={'w-100'} style={{ maxHeight: '50px', backgroundImage: `url('${cell}')`, backgroundPosition: 'center center', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} ></div>
}

export const DownloadHref = (cell, row, rowIndex, formatExtraData) => {
 
  return <a target='_blank' rel="noopener noreferrer"   href={cell.link}>{cell.name}</a>
}

export const PhoneFormatter = (cell, row, rowIndex, formatExtraData) => {
  return <a href={`tel:${cell}`} onClick={(e) => e.stopPropagation()}>
    <span style={{whiteSpace:'nowrap'}}>{cell}</span>
  </a> 
}

export const EmailFormatter = (cell, row, rowIndex, formatExtraData) => {
  return <a href={`mailto:${cell}`} onClick={(e) => e.stopPropagation()}>
    <span style={{whiteSpace:'nowrap'}}>{cell}</span>
  </a> 
}

export const LinkFormatter = (cell, row, rowIndex, formatExtraData) => {
  const prefix = `${formatExtraData && formatExtraData.prefix ? formatExtraData.prefix : ''}`
  const suffix = `${formatExtraData && formatExtraData.suffix ? formatExtraData.suffix : ''}`
  const to = `${prefix}/${cell.to}${suffix !== '' ? `/${suffix}` : ''}`
  return <Link to={to} onClick={(e) => e.stopPropagation()}>
    <span style={{ whiteSpace: 'nowrap' }}>{cell.text}</span>
  </Link>
}