import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { Input, CustomInput, Table, Col, Row, Badge, UncontrolledPopover, Modal, ModalHeader, ModalFooter, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, ModalBody } from 'reactstrap';
import Select from 'react-select';
import DateTime from "react-datetime";
import JSONInput from 'react-json-editor-ajrm';
import 'react-datetime/css/react-datetime.css';
import * as moment from 'moment';
import { CoverImg, ThumbImg } from '../Img';
import Button from 'reactstrap/lib/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faEdit, faEllipsisH, faImage, faLink, faMapPin, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { HexColorPicker, HexColorInput } from "react-colorful";
import Card from 'reactstrap/lib/Card';
import FormField from './FormField';
import { FormValidator } from '../../../utils/formValidator';
import { default as ReactMapGL, GeolocateControl, NavigationControl, FullscreenControl, Marker } from 'react-map-gl'
import { MapPanel } from '../MapBoxControlPanel';
import { faFileAlt, faMap, faTrashAlt, faUser } from '@fortawesome/free-regular-svg-icons';
import ReactQuill from 'react-quill';
import Pin from '../Map/Pin';
import TableComponent from '../DataTable/TableComponent';
import { ModalPanel } from '../Panels/ModalPanel';
import AddAssetLinks from '../../../tenants/_base/Admin/Assets/AddAssetLinks';
import { SelectAssetMember } from '../../../tenants/_base/Admin/Assets/AssetMembers';
import { SelectAssetResources } from '../../../tenants/_base/Admin/Assets/AssetResources';
import { uniqBy } from 'lodash';

const _ = {
    uniqBy: uniqBy
};

const MAP_CONFIG = {
    maxZoom: 20,
    maxPitch: 0,
    dragRotate: false,
    mapStyle: "mapbox://styles/mapbox/light-v9",
    mapboxAccessToken: "pk.eyJ1Ijoib2RkZSIsImEiOiJjamplZnEwYmw0b2llM3ZsZmpvaG9zam53In0.D4zjizzS4qgVnfBmwioadA"
};

export const TextField = (props) => {
    let { name, required, placeholder, disabled, type } = props;
    let value = props?.value || "";
    let invalid = props.invalid ? props.invalid : props.value === undefined ? false : props.invalid;

    const _onBlur = (fieldName, value) => {
        if (props.onBlur) {
            props.onBlur(fieldName, value);
        }
    }

    return <Input value={value} type={type} required={required} disabled={disabled} invalid={invalid} name={name} onChange={(event) => props.onChange(event.target.value)} onBlur={(event) => _onBlur(name, event.target.value)} placeholder={placeholder} />
}

export const NumberField = (props) => {
    let { name, required, placeholder, disabled, type } = props;
    let value = props?.value || '';
    let invalid = props.invalid ? props.invalid : props.value === undefined ? false : props.invalid;

    const _onBlur = (fieldName, value) => {
        if (props.onBlur) {
            props.onBlur(fieldName, value);
        }
    }

    const _onChange = (value) => {
        const numberValue = Number(value)
        if (isNaN(numberValue)) {
            props.onChange(0)
        }
        else {
            props.onChange(numberValue);
        }
    }

    return <Input value={value} type={type} required={required} disabled={disabled} invalid={invalid} name={name} onChange={(event) => _onChange(event.target.value)} onBlur={(event) => _onBlur(name, event.target.value)} placeholder={placeholder} />
}

export const BooleanField = (props) => {
    let { name, label } = props;
    let value = props.value === undefined ? false : props.value;
    return <CustomInput
        checked={value}
        className={'justify-content-center align-self-center'}
        onChange={(event) => props.onChange(event.target.checked)}
        type="switch"
        id={`checkbox_${name}`}
        name={name}
        label={label}
    />
}

export const UploadField = (props) => {
    let { accept, name, multi } = props;
    return <CustomInput className="overflow-hidden" type="file" accept={accept} multiple={multi ? multi : false} name={props.name} id={`fileBrowser${name}`} label="Select a file" onChange={(event) => props.onChange(event.target.files)} />
}

export const SelectField = (props) => {
    const { invalid, disabled, isClearable } = props
    let invalidClass = invalid ? 'border-danger' : '';
    let value = props.value === undefined || props.value === null ? null : props.value;
    let options = props.options && props.options.length > 0 ? props.options : []
    let selectedOption = value === null ? null : options.filter((opt) => opt.value === value)[0];

    const customStyles = {
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: (styles, { isDisabled }) => {
            return {
                ...styles,
                backgroundColor: isDisabled ? '#e9ecef' : 'white',
                border: '1px solid #ced4da'
            }
        }
    }
    return <Select
        isDisabled={disabled}
        isClearable={isClearable !== undefined ? isClearable : true}
        className="react-select-container border-danger"
        classNamePrefix={`${invalidClass} react-select`}
        styles={customStyles}
        options={options}
        value={selectedOption || ''}
        onChange={(option) => props.onChange(option ? option.value : '')}
    />
}

export const MultiSelectField = (props) => {
    const { invalid, isClearable } = props
    let invalidClass = invalid ? 'border-danger' : '';
    let value = props.value === '' || props.value === undefined || props.value === null ? [] : props.value;
    let options = props.options && props.options.length > 0 ? props.options : []

    const customStyles = {
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
    }

    let selectedOptions = []
    value.forEach((item) => {
        let selected = options.filter((option) => item === option.value)[0];
        if (selected) {
            selectedOptions.push(selected);
        }
    })

    return <div><Select
        className="react-select-container border-danger"
        classNamePrefix={`${invalidClass} react-select`}
        isMulti={true}
        styles={customStyles}
        closeMenuOnSelect={false}
        isClearable={isClearable !== undefined ? isClearable : true}
        options={options}
        value={selectedOptions}
        onChange={(values) => {
            props.onChange(values ? values.map((opt) => { return opt.value }) : []);
        }}
    />
    </div>
}

export const DateField = (props) => {

    const invalidClass = props.invalid ? 'form-control border-1 border-danger' : 'form-control';
    let value = props.value === undefined || props.value === '' ? '' : props.value === null ? '' : moment(props.value);

    const _onChange = (date) => {
        if (date && date.isValid())
            props.onChange(date.format('YYYY-MM-DD'));
        else
            props.onChange('');
    }

    return <div className="w-100">
        <DateTime
            wrapperClassName="w-100"
            initialViewMode='days'
            closeOnSelect={true}
            timeFormat={false}
            input={true}
            inputProps={{ placeholder: props.placeholder, className: invalidClass, maxLength: 0 }}
            className={`w-100`}
            dateFormat={'YYYY-MM-DD'}
            value={value}
            onChange={(date) => _onChange(date)}
        />
    </div>
}

export const JsonField = (props) => {

    const cssClass = props.invalid ? 'border border-1 border-danger rounded' : 'border border-1 border-dark rounded'

    return <div className={cssClass}><JSONInput
        id={`${props.name}`}

        placeholder={props.value !== "" ? props.value : undefined}
        jsObject={props.value !== "" ? props.value : undefined}
        onChange={(json) => props.onChange(json.jsObject !== undefined ? json.jsObject : '')}
        width={'100%'}
        height='550px'
    /></div>
}

export const ResourceField = (props) => {
    return <Card className="p-0 border border-1 w-100 overflow-hidden"><div style={{ height: '100%', minHeight: 250 }}>
        <div className="ml-2 mt-2 position-absolute">
            <Button className="mr-2" onClick={props.onRemove} outline={false}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button>
            <Button color={'primary'} onClick={props.onChange} outline={false}><FontAwesomeIcon icon={faCamera}></FontAwesomeIcon></Button>
        </div>
        <div style={{ right: 0 }} className="mr-2 mt-2 position-absolute">
            <Badge size={'lg'} color='dark'>{props.label}</Badge>
        </div>
        {!props.value && <div className="h-100 w-100 text-center">
            <div className="h-100 bg-light">
                <div style={{ height: '100%', minHeight: 250 }} className="h-100 w-100 d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon
                        className="justify-content-center align-items-center"
                        icon={faImage}
                        size="3x"
                    ></FontAwesomeIcon>
                </div>
            </div>
        </div>
        }
        {props.value && <CoverImg key={`_${props.value}`} {...props} className="img-fluid" src={`${props.value}`} alt='alt'></CoverImg>}
    </div>
    </Card>
}

export const KeyValueField = (props) => {
    let { disabled, type, fieldErrors } = props;
    let value = props.value === undefined ? [{ key: '', value: '' }] : Array.isArray(props.value) && props.value.length > 0 ? props.value : [{ key: '', value: '' }];

    const onChange = (field, index, input) => {
        const item = value[index];
        if (field === 'key') {
            item['key'] = input

        }
        if (field === 'value') {
            item['value'] = input
        }

        props.onChange(value)
    }

    const addRow = () => {
        value.push({ key: '', value: '' });
        props.onChange(value)
    }

    const removeRow = (index) => {
        const newArray = value.filter((item, i) => i !== index);
        if (newArray.length === 0) {
            newArray.push({ key: '', value: '' })
        }
        props.onChange(newArray)
    }

    return <Table size='sm'>

        <tbody>
            {value.map((item, index) => {
                let invalidKey = false;
                let invalidValue = false;
                if (fieldErrors) {
                    const fields = fieldErrors && fieldErrors.hasOwnProperty('fields') ? fieldErrors.fields : [];
                    const field = fields[index];
                    if (field) {
                        invalidKey = field.key !== '' ? false : field.invalidKey
                        invalidValue = field.value !== '' ? false : field.invalidValue
                    }
                }

                return <tr key={`tr_${index} `}>
                    <td className='px-1 w-50'>
                        <Input value={item.key} type={type} required={true} disabled={disabled} invalid={invalidKey} name={`${item.key}_${index}`} onChange={(event) => onChange('key', index, event.target.value)} placeholder={'Key'} />
                    </td>
                    <td className='pl-2 pr-0 w-50'>
                        <Input value={item.value} type={type} required={true} disabled={disabled} invalid={invalidValue} name={`${item.key}_${index}`} onChange={(event) => onChange('value', index, event.target.value)} placeholder={'Value'} />
                    </td>
                    <td className='pl-2 pr-1 text-right'>
                        <Button title="remove" onClick={() => removeRow(index)}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button>
                    </td>
                </tr>
            })}
            <tr>
                <td colSpan={3} className='px-0 text-right'><div className=''></div><Button onClick={() => addRow()} className={"text-decoration-none shadow-none"} color='link'> <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Row</Button></td>
            </tr>
        </tbody>
    </Table>
}

export const ArrayValueField = (props) => {
    let { fields, label } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState({});
    const [errors, setErrors] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(-1);

    let value = props.value === undefined ? [] : Array.isArray(props.value) && props.value.length > 0 ? props.value : [];

    const onAddItem = () => {
        const formValidator = new FormValidator()
        const form = formValidator.validate(data, fields);
        if (!form.isValid) {
            setErrors(form.errors)
            return;
        }
        if (selectedIndex === -1) {
            value.push(form.data)
        }
        else {
            value[selectedIndex] = form.data;
        }

        props.onChange(value)
        setSelectedIndex(-1)
        setIsOpen(false);
    }

    const onAddRow = () => {
        let obj = {}
        fields.forEach((field) => {
            obj[field.name] = '';
        })
        setData(obj);
        setErrors({});
        setSelectedIndex(-1)
        setIsOpen(true);
    }

    const onViewRow = (index) => {
        const item = value.filter((item, i) => i === index)[0]
        if (item) {
            setData(item)
            setSelectedIndex(index)
            setIsOpen(true);
        }
    }

    const removeRow = (index) => {
        const newArray = value.filter((item, i) => i !== index);
        props.onChange(newArray)
    }

    const _onValueChange = (fieldName, newValue) => {
        const currentField = fields.filter((fieldItem) => fieldItem.name === fieldName)[0];
        const fieldError = currentField.required && !newValue ? { invalid: true, message: currentField.requiredMessage ? currentField.requiredMessage : 'Field is required' } : { invalid: false, message: '' };
        setData((prevState) => {
            return {
                ...prevState,
                ...data,
                ...prevState.data, [fieldName]: newValue
            }
        })
        setErrors((prevState) => {
            return {
                ...prevState,
                ...errors,
                ...prevState.errors, [fieldName]: fieldError
            }
        })
    }

    const textareaFields = fields.filter((f) => f.type === 'textarea');
    const otherFields = fields.filter((f) => f.type !== 'textarea');

    return <>
        {
            value.map((item, i) => {
                const even = (i % 2 === 0)
                const className = i === 0 ? 'py-2 border-top border-bottom mx-0' : 'py-2 border-bottom mx-0';
                return <Row key={`row${i}`} className={`${className} ${!even ? 'bg-white' : 'bg-light'}`}>
                    <Col>
                        <Row>
                            <Col className='text-right'>
                                <Button onClick={() => onViewRow(i)} color={'link'} className='shadow-none px-1 mr-1'> <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon> </Button>
                                <Button onClick={() => removeRow(i)} color={'link'} className='shadow-none px-1'> <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon> </Button>
                            </Col>
                        </Row>
                        <Row>
                            {otherFields.filter((field) => item.hasOwnProperty(field.name) && item[field.name] !== '').map((field, index) => {
                                return <Col className='pb-1 text-nowrap' sm={{ size: 6 }} md={{ size: 4 }} key={`td${index}`}><strong>{field.label}:</strong> <span className='text-nowrap'>{item[field.name]}</span></Col>
                            })}
                        </Row>
                        <Row>
                            {textareaFields.filter((field) => item.hasOwnProperty(field.name) && item[field.name] !== '').map((field, index) => {
                                return <Col className='pb-1' key={`td${index}`}><strong>{field.label}:</strong> <span>{item[field.name]}</span></Col>
                            })}
                        </Row>
                    </Col>
                </Row>
            })
        }
        {value.length === 0 && <Row>
            <Col className='bg-light text-center mx-2 py-2'>No items</Col>
        </Row>}
        <Row>
            <Col className='bg-white text-right py-2'><Button color={'primary'} onClick={() => onAddRow()} >Add row</Button></Col>
        </Row>
        {isOpen && <ModalPanel size="md" block={true} isOpen={isOpen} title={`Add ${label}`} onDismiss={() => setIsOpen(!isOpen)}>
            {
                fields.map((field, index) => {
                    return <Row key={`formfield_${index}`} className='mt-2'>
                        <Col className='pb-1 pb-sm-0'><FormField
                            {...field} value={data[field.name]}
                            onChange={(value) => _onValueChange(field.name, value)}
                            invalid={errors[field.name] !== undefined ? errors[field.name].invalid : false}
                            fieldErrors={errors[field.name]}
                        ></FormField></Col>
                    </Row>
                })
            }
            <Row>
                <Col className='text-right mt-2 pb-5'>
                    <Button onClick={() => setIsOpen(!isOpen)} className='mr-2'>Cancel</Button>
                    <Button onClick={() => onAddItem()} color='primary'>{selectedIndex === -1 ? 'Add' : 'Update'}</Button>
                </Col>
            </Row>
        </ModalPanel>}
    </>
}

export const ColorField = (props) => {
    let value = props.value === undefined ? '' : props.value;
    const swatch = {
        width: 31,
        height: 31,
        border: '3px solid #fff',
        boxShadow: '0 0 0 1px #ced4da, inset 0 0 0 1px #ced4da',
        cursor: 'pointer'
    }
    return (
        <Row className='no-gutters'>
            <Col><HexColorInput className='form-control mr-1' prefixed color={value} onChange={(color) => { console.log(color); props.onChange(color) }} /></Col>
            <Col xs={{ size: 'auto' }}>
                <div id='colorPicker' className='ml-2 form-control rounded' style={{ ...swatch, backgroundColor: value }} />
                <UncontrolledPopover
                    popperClassName='border-0'
                    placement="left-end"
                    target="colorPicker"
                    hideArrow={true}
                    trigger="legacy"
                    className='p-0 border-0'
                >

                    <HexColorPicker color={value} className='rounded' onChange={(color) => { props.onChange(color) }} />
                </UncontrolledPopover>
            </Col>
        </Row>
    )
}

export const BBoxField = (props) => {
    const mapRef = useRef();
    const [isOpen, setIsOpen] = useState(false);
    let value = props.value === undefined ? '' : props.value;
    let initial = props?.initial || undefined;

    const _onMapLoaded = () => {
        if (mapRef.current) {
            if (value?.northEast?.lat && value?.northEast?.lng && value?.southWest?.lng && value?.southWest?.lat) {
                mapRef.current?.fitBounds([[value?.southWest?.lng, value?.southWest?.lat], [value?.northEast?.lng, value?.northEast?.lat]])
            }
            if (value === undefined && initial !== undefined) {
                mapRef.current?.fitBounds([[initial?.southWest?.lng, initial?.southWest?.lat], [initial?.northEast?.lng, initial?.northEast?.lat]])
            }
        }
    }

    const _onSelect = () => {
        if (mapRef.current) {
            const bounds = mapRef.current.getBounds();
            const northEast = bounds?._ne;
            const southWest = bounds?._sw;

            const bbox = {
                northEast: { lat: northEast.lat, lng: northEast.lng },
                southWest: { lat: southWest.lat, lng: southWest.lng },
            }
            props?.onChange(bbox);
            setIsOpen(false);
        }
    }

    return <>
        <Modal isOpen={isOpen} size='lg' toggle={() => setIsOpen(false)}>
            <ModalHeader>Select Bounds</ModalHeader>
            <ReactMapGL ref={mapRef}
                {...MAP_CONFIG}
                onLoad={(evt) => { _onMapLoaded() }}
                style={{ width: '100%', height: '70vh' }}>
                <FullscreenControl position='top-left' className="map-ctrl position-relative" container={document.querySelector('map')} />
                <NavigationControl position='top-left' className="mt-2 map-ctrl position-relative" />
                <GeolocateControl position='top-left' className="mt-2 map-ctrl position-relative" positionOptions={{ enableHighAccuracy: true }} trackUserLocation={true} />
                <>
                    <MapPanel>
                        <h4>Select Bounds</h4>
                        <div>Drag and zoom the map to select the bounds of your asset. Press select when you are satisfied. </div>
                    </MapPanel>
                </>
            </ReactMapGL>

            <ModalFooter>
                <Button onClick={() => setIsOpen(false)}>Cancel</Button>
                <Button onClick={() => _onSelect()} color={'primary'}>Select</Button>

            </ModalFooter>
        </Modal>
        <Card className='border mb-0'>
            <CardBody>
                <Row>
                    <Col className='text-left'>
                        <Button className='mr-2' onClick={() => props?.onChange('')} ><FontAwesomeIcon className='mr-2' icon={faTimes}></FontAwesomeIcon>Remove</Button>
                        <Button onClick={() => setIsOpen(true)} color={'primary'}><FontAwesomeIcon className='mr-2' icon={faMap}></FontAwesomeIcon>Select</Button>
                    </Col>
                </Row>
                <Table size={'sm'} style={{ tableLayout: 'auto' }}>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Latitude</th>
                            <th>Longitude</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>North East</td>
                            <td>{value?.northEast?.lat ? value?.northEast?.lat.toFixed(6) : '-'}</td>
                            <td>{value?.northEast?.lng ? value?.northEast?.lng.toFixed(6) : '-'}</td>
                        </tr>
                        <tr>
                            <td>South West</td>
                            <td>{value?.southWest?.lat ? value?.southWest?.lat.toFixed(6) : '-'}</td>
                            <td>{value?.southWest?.lng ? value?.southWest?.lng.toFixed(6) : '-'}</td>
                        </tr>
                    </tbody>
                </Table>
            </CardBody>
        </Card>

    </>

}

export const PositionField = (props) => {
    const mapRef = useRef();

    let value = props.value === undefined ? '' : props.value;
    const [isOpen, setIsOpen] = useState(false);
    const [marker, setMarker] = useState(props.value || undefined);

    const _round = (value, decimals = 6) => {
        return parseFloat((Math.round(value * 1e5) / 1e5).toFixed(decimals));
    }

    const _onMapLoaded = () => {
        if (mapRef.current) {
            if (value?.longitude && value?.latitude) {
                mapRef.current?.flyTo({ zoom: 14, center: [value?.longitude, value?.latitude], duration: 2000 });
            }
        }
    }

    const onMarkerDrag = useCallback((event) => {
        setMarker({
            longitude: event.lngLat.lng,
            latitude: event.lngLat.lat
        });
    }, []);


    const _onSelect = () => {
        const position = {
            latitude: _round(marker?.latitude, 6),
            longitude: _round(marker?.longitude, 6)
        }

        props?.onChange(position);
        setIsOpen(false);
    }

    const _onClick = useCallback((event) => {
        setMarker({
            longitude: event.lngLat.lng,
            latitude: event.lngLat.lat,
        });
    }, []);

    const _onClose = () => {
        setMarker(props?.value || undefined);
        setIsOpen(false);
    }

    return <>
        <Table borderless style={{ tableLayout: 'auto' }}>
            <tbody>
                <tr>
                    <td className='p-0 pr-1'><Input readOnly value={value?.latitude || ""} step={0} type="number" name="latitude" placeholder="Latitude" className='bg-white' /></td>
                    <td className='p-0 pr-1' ><Input readOnly value={value?.longitude || ""} type="number" name="longitude" placeholder="Longitude" className='bg-white' /></td>
                    <td style={{ width: 30 }} align="right" className='p-0'><Button className='mr-1' onClick={() => { props?.onChange('') }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></Button></td>
                    <td style={{ width: 30 }} align="right" className='p-0'><Button color={'primary'} onClick={() => setIsOpen(true)}><FontAwesomeIcon icon={faMapPin}></FontAwesomeIcon></Button></td>
                </tr>
            </tbody>
        </Table>

        <Modal isOpen={isOpen} size='lg' toggle={() => _onClose()}>
            <ModalHeader>Select Position</ModalHeader>
            <ReactMapGL ref={mapRef}
                {...MAP_CONFIG}
                style={{ width: '100%', height: '70vh' }}
                onClick={(evt) => _onClick(evt)}
                onLoad={(evt) => { _onMapLoaded() }}
                cursor='pointer'>
                {marker !== undefined && <Marker
                    longitude={marker.longitude}
                    latitude={marker.latitude}
                    anchor="bottom"
                    draggable
                    onDrag={onMarkerDrag}
                >
                    <Pin size={20} />
                </Marker>}
                <FullscreenControl position='top-left' className="map-ctrl position-relative" container={document.querySelector('map')} />
                <NavigationControl position='top-left' className="mt-2 map-ctrl position-relative" />
                <GeolocateControl position='top-left' className="mt-2 map-ctrl position-relative" positionOptions={{ enableHighAccuracy: true }} trackUserLocation={true} />
            </ReactMapGL>
            <ModalFooter>
                <Button onClick={() => _onClose()}>Cancel</Button>
                <Button onClick={() => _onSelect()} color={'primary'}>Select</Button>
            </ModalFooter>
        </Modal>
    </>

}

export const RichTextField = (props) => {
    let value = props.value === undefined ? '' : props.value;

    const modules = useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                ['blockquote', 'code-block'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
                [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
                [{ 'align': [] }],
                ['link']
            ],
        },
        clipboard: {
            matchVisual: false,
        },
    }), [])

    const _onChange = (value) => {
        // const cleandHtml = DOMPurify.sanitize(value, {FORBID_ATTR: ['style']});
        props?.onChange(value);
    }

    return (<>
        <ReactQuill {...props} modules={modules} theme="snow" value={value} onChange={(value) => _onChange(value)} />
    </>
    )
}

export const ObjectField = (props) => {


    const { fields, value, onChange, fieldErrors } = props;
    const [state, setState] = useState({ fieldErrors: {} })

    const _onChange = (field, newValue) => {
        let newValues = { ...value };
        newValues[field] = newValue;
        onChange({ ...newValues })
    }

    useEffect(() => {
        setState((prevState) => {
            return {
                ...prevState,
                fieldErrors: fieldErrors,
            }
        });
    }, [fieldErrors])




    return <div>
        {fields.map((field, index) => {
            return <FormField key={`index_${index}`}
                {...field}
                value={value?.hasOwnProperty(field.name) ? value[field.name] : ''}
                onChange={(value) => _onChange(field.name, value)}
                invalid={state?.fieldErrors?.[field.name] !== undefined ? state?.fieldErrors?.[field.name]?.invalid : false}
                errorMessage={state?.fieldErrors?.[field.name]?.message || ''}
            ></FormField>
        })}
    </div>

}

export const LinkListField = (props) => {

    const { value, onChange, apiClient, collection } = props;

    const columns = useMemo(() => [{ accessor: 'alias', Header: 'Id' }, { accessor: 'name', Header: 'Name' }], [])
    const [tableData, setTableData] = useState([])
    const [tableColumns, setTablColumns] = useState(columns)
    const [selectedRow, setSelectedRow] = useState(undefined);
    const [isAddPanelOpen, setIsAddPanelOpen] = useState(false);

    useEffect(() => {
        const obj = value ? value : {};
        const tableData = Object.keys(obj).filter((key) => key?.startsWith('_')).map((key) => {
            return value[key]
        })
        const tableColumns = [...columns];
        tableColumns.push(
            {
                accessor: 'controls', headerClassName: 'd-none', disableSortBy: true, Cell: ({ row }) => {
                    const { original } = row;

                    return <div style={{ textAlign: "right" }} >
                        <UncontrolledDropdown className='d-md-none float-right'>
                            <DropdownToggle size='sm' color='link' className='shadow-none' onClick={(e) => e.stopPropagation()}><FontAwesomeIcon icon={faEllipsisH}></FontAwesomeIcon></DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={(e) => { setSelectedRow(original); e.stopPropagation() }}><FontAwesomeIcon className='mr-2' icon={faTrashAlt} />Delete</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <ButtonGroup>
                            <Button className="mr-2 rounded d-none d-md-inline" outline={true} color={'primary'} onClick={(e) => { e.stopPropagation(); setSelectedRow(original); }}>
                                <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                            </Button>
                        </ButtonGroup>
                    </div>
                }
            }
        )
        setTableData(tableData);
        setTablColumns(tableColumns);

    }, [value, columns])


    const _onRemove = () => {
        const key = `${selectedRow?.alias}`;
        let newValue = { ...value };
        delete newValue[`_${key}`];
        delete newValue[`${key}`];

        onChange(newValue)
        setSelectedRow(undefined);
    }


    const _onSelected = (item) => {
        const { collection, tag } = props;
        const key = `${item?.alias}`;
        const tenant = apiClient?._tenant
        const yri = `yri://${tenant}.assetto/${collection}/${key}#${tag}`

        let newValue = { ...value };
        newValue[key] = yri;
        newValue[`_${key}`] = item;
        onChange(newValue)
    }

    return <>
        <TableComponent columns={tableColumns}
            data={tableData}
            actions={[
                { icon: <FontAwesomeIcon className="mr-2" icon={faPlus} />, text: 'Add', onClick: () => setIsAddPanelOpen(true), props: { className: 'ml-2', outline: true, color: 'primary' } },
            ]}>
        </TableComponent>
        <Modal centered isOpen={selectedRow !== undefined} size='md' toggle={() => setSelectedRow(undefined)}>
            <ModalHeader>Remove {selectedRow?.name}</ModalHeader>
            <ModalBody>
                Are you sure you want to remove {selectedRow?.name}?
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => setSelectedRow(undefined)}>Cancel</Button>
                <Button onClick={() => _onRemove()} color={'primary'}>Remove</Button>
            </ModalFooter>
        </Modal>

        {isAddPanelOpen && <ModalPanel size={'xl shadow-none'} title="Add Asset" backdropClassName={'bg-transparent'} isOpen={isAddPanelOpen} onDismiss={() => setIsAddPanelOpen(false)}>´
            <AddAssetLinks {...props} onSelect={(data) => _onSelected(data)} assetIds={tableData.map((d) => { return d.alias })} collection={collection}></AddAssetLinks>
        </ModalPanel>}
    </>
}

export const MemberField = (props) => {

    const { value, name, label, onChange, data } = props;
    const [inputText, setInputText] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const _onSelected = (row) => {

        const yri = `${row?.['@identifier']}#user`;
        let newValue = { ...value };
        newValue[name] = yri;
        newValue[`_${name}`] = row;
        const firstName = row?.firstName || '';
        const lastName = row?.lastName || '';
        const principal = row?.principal || '';

        setInputText(firstName && lastName ? `${firstName} ${lastName} (${principal})` : `${principal}`);
        onChange(yri)
        setIsOpen(false);
    }

    const _onRemove = (row) => {
        setInputText('');
        onChange(null)
    }


    useEffect(() => {

        const _value = data?.[`_${name}`]?.['@identifier']

        if (value && _value && value?.indexOf(_value) > -1) {
            const firstName = data?.[`_${name}`]?.firstName || '';
            const lastName = data?.[`_${name}`]?.lastName || '';
            const principal = data?.[`_${name}`]?.principal || '';

            setInputText(firstName && lastName ? `${firstName} ${lastName} (${principal})` : `${principal}`);
        }

    }, [value, name, data])

    return <>
        <Table borderless style={{ tableLayout: 'auto' }}>
            <tbody>
                <tr>
                    <td className='p-0 pr-1'><Input readOnly value={inputText} step={0} name={name} placeholder={label} className='bg-white' /></td>
                    <td style={{ width: 30 }} align="right" className='p-0'><Button className='mr-1' onClick={() => { _onRemove() }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></Button></td>
                    <td style={{ width: 30 }} align="right" className='p-0'><Button color={'primary'} onClick={() => setIsOpen(true)}><FontAwesomeIcon icon={faUser}></FontAwesomeIcon></Button></td>
                </tr>
            </tbody>
        </Table>

        <Modal centered isOpen={isOpen} size='md' toggle={() => setIsOpen(false)}>
            <ModalHeader>Select {label}</ModalHeader>
            <ModalBody>
                <SelectAssetMember {...props} onSelected={(row) => _onSelected(row)}></SelectAssetMember>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            </ModalFooter>
        </Modal>
    </>

}

const AssettoImage = (props) => {
    const url = useMemo(() => {
        const imgs = ['apng', 'avif', 'gif', "jpg", "jpeg", "jfif", "pjpeg", "pjp", 'png', 'svg', 'webp'];
        const src = props?.src && imgs.indexOf(props?.src?.split('.').pop()) > -1 ? `assetto/${props.src}` : undefined;
        return src;
    }, [props?.src])

    return <div style={{ textAlign: 'center' }}><ThumbImg {...props} className="img-fluid" src={url} alt='alt'></ThumbImg></div>
}

export const ResourcesField = (props) => {

    const { value, label, onChange } = props;
    const [isOpen, setIsOpen] = useState(false);



    const tableData = useMemo(() => {
        const tempData = Array.isArray(value) ? value : [];
        return tempData;
    }, [value])


    const columns = useMemo(() => {
        return [
            { accessor: 'path', disableSortBy: true, Header: 'Image', style: { width: 60 }, Cell: ({ value }) => <AssettoImage apiClient={props?.apiClient} src={value}> </AssettoImage> },
            { accessor: 'name', Header: 'Name' }
        ]
    }, [props?.apiClient])


    const tableColumns = useMemo(() => {
        const onRemove = (row) => {
            const tempData = Array.isArray(tableData) ? tableData : [];
            const items = Array.isArray(tempData) ? tempData : [];
            const filtered = items?.filter((item) => item?.path !== row?.path);
            onChange(filtered);
        }

        return [...columns, {
            accessor: 'controls', headerClassName: 'd-none', disableSortBy: true, Cell: ({ row }) => {
                const { original } = row;
                return <div style={{ textAlign: "right" }} >
                    <UncontrolledDropdown className='d-md-none float-right'>
                        <DropdownToggle size='sm' color='link' className='shadow-none' onClick={(e) => e.stopPropagation()}><FontAwesomeIcon icon={faEllipsisH}></FontAwesomeIcon></DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem onClick={(e) => { onRemove(original); e.stopPropagation() }}><FontAwesomeIcon className='mr-2' icon={faTrashAlt} />Delete</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <ButtonGroup>
                        <Button className="mr-2 rounded d-none d-md-inline" outline={true} color={'primary'} onClick={(e) => { e.stopPropagation(); onRemove(original); }}>
                            <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                        </Button>
                    </ButtonGroup>
                </div>
            }
        }
        ]

    }, [columns, onChange,tableData])

    const id = useMemo(() => {
        const identifier = props?.data?.['@identifier'];
        return identifier.split('/').pop();
    }, [props?.data])

    const onAddSelected = (items) => {
        const selectedItems = items.map((item) => { return { name: item.name, path: item.path } });
        const prev = Array.isArray(value) ? value : [];
        const merged = [...prev, ...selectedItems]
        const uniqItems = _.uniqBy(merged, 'path')
        onChange(uniqItems);
        setIsOpen(false)
    }

 

    return <>
        {Array.isArray(columns) && <TableComponent
            data={tableData}
            columns={tableColumns}
            actions={[
                { icon: <FontAwesomeIcon className="mr-2" icon={faFileAlt} />, text: 'Add', onClick: () => setIsOpen(true), props: { className: 'ml-2', outline: true, color: 'primary' } },
            ]}
        ></TableComponent>}

        <ModalPanel centered isOpen={isOpen} size='xl shadow-none' title={`Select ${label}`} backdropClassName={'bg-transparent'} onDismiss={() => setIsOpen(false)}>
            <SelectAssetResources  {...props} id={id} onSelect={(items) => onAddSelected(items)} onDismiss={() => setIsOpen(false)}></SelectAssetResources>
        </ModalPanel>
    </>
}

export const LinkField = (props) => {


    const { value, name, label, tag, onChange, data, collection } = props;
    const [inputText, setInputText] = useState('');
    const [isOpen, setIsOpen] = useState(false);


    const _onSelected = (row) => {
        const yri = `${row?.['@identifier']}#${tag}`;
        let newValue = { ...value };
        newValue[name] = yri;
        newValue[`_${name}`] = row;

        setInputText(row?.name);
        onChange(yri)
        setIsOpen(false);
    }

    const _onRemove = (row) => {
        setInputText('');
        onChange(null)
    }

    useEffect(() => {
        const _value = data?.[`_${name}`]?.['@identifier']
        if (value && _value && value?.indexOf(_value) > -1) {
            const inputText = data?.[`_${name}`]?.name || '';
            setInputText(inputText);
        }

    }, [value, name, data])

    return <>
        <Table borderless style={{ tableLayout: 'auto' }}>
            <tbody>
                <tr>
                    <td className='p-0 pr-1'><Input readOnly value={inputText} step={0} name={name} placeholder={label} className='bg-white' /></td>
                    <td style={{ width: 30 }} align="right" className='p-0'><Button className='mr-1' onClick={() => { _onRemove() }}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></Button></td>
                    <td style={{ width: 30 }} align="right" className='p-0'><Button color={'primary'} onClick={() => setIsOpen(true)}><FontAwesomeIcon icon={faLink}></FontAwesomeIcon></Button></td>
                </tr>
            </tbody>
        </Table>

        {isOpen && <ModalPanel size={'xl shadow-none'} title="Add Asset" backdropClassName={'bg-transparent'} isOpen={isOpen} onDismiss={() => setIsOpen(false)}>
            <AddAssetLinks {...props} onSelect={(data) => _onSelected(data)} assetIds={[]} collection={collection}></AddAssetLinks>
        </ModalPanel>}
    </>
}

export const StringArrayField = (props) => {
    let { disabled, type, fieldErrors } = props;
    let value = props.value === undefined ? [] : Array.isArray(props.value) && props.value.length > 0 ? props.value : [];

    const onChange = (index, input) => {
        value[index] = input
        props.onChange(value)
    }

    const addRow = () => {
        value.push('');
        props.onChange(value)
    }

    const removeRow = (index) => {
        const newArray = value.filter((item, i) => i !== index);
        if (newArray.length === 0) {
            newArray.push('')
        }
        props.onChange(newArray)
    }

    return <Table size='sm'>

        <tbody>
            {value.map((item, index) => {
                let invalidKey = false;
                let invalidValue = false;
                if (fieldErrors) {
                    const fields = fieldErrors && fieldErrors.hasOwnProperty('fields') ? fieldErrors.fields : [];
                    const field = fields[index];
                    if (field) {
                        invalidValue = field.value !== '' ? false : field.invalidValue
                    }
                }

                return <tr key={`tr_${index} `}>
                    <td className='pl-2 pr-0 w-100'>
                        <Input value={item} type={type} required={true} disabled={disabled} invalid={invalidValue} name={`${item.key}_${index}`} onChange={(event) => onChange(index, event.target.value)} />
                    </td>
                    <td className='pl-2 pr-1 text-right'>
                        <Button title="remove" onClick={() => removeRow(index)}><FontAwesomeIcon icon={faTrash}></FontAwesomeIcon></Button>
                    </td>
                </tr>
            })}
            <tr>
                <td colSpan={3} className='px-0 text-right'><div className=''></div><Button onClick={() => addRow()} className={"text-decoration-none shadow-none"} color='link'> <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> Add Row</Button></td>
            </tr>
        </tbody>
    </Table>
}
