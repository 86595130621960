import React, { useState, useEffect, useContext, useMemo } from "react";
import { AssettoService } from "../../../services/assettoService";
import { Row, Col, Container, Card, Table, CardTitle } from "reactstrap";
import { faEnvelope, faImage, faPhoneAlt, faUser} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RoundImg } from '../../../components/Youmoni/Img';
import _ from "lodash";
import { LastSeen, SystemRSSI } from "../../../components/Youmoni/DataTable/TableColumn";

const getProperty = (key, data, def = undefined) => {
    const _checkDefeault = (value, def) => value === null ? def : value;
    const value = _checkDefeault(_.get(data, `${key}`), def);
    return value ? value : def;
}

const phoneFormatter = (data) => {
    return data !== undefined && data !== '' ? <div className="text-nowrap"><FontAwesomeIcon className="mr-2" icon={faPhoneAlt}></FontAwesomeIcon> <a href={`tel:${data}`}>{data}</a></div> : '';
}

const nameFormatter = (data) => {
    return data !== undefined && data !== '' ? <div className="text-nowrap"><FontAwesomeIcon className="mr-2" icon={faUser}></FontAwesomeIcon>{data}</div> : '';
}

const emailFormatter = (data) => {
    return data !== undefined && data !== '' ? <div className="text-nowrap"><FontAwesomeIcon className="mr-2" icon={faEnvelope}></FontAwesomeIcon><a href={`mailto:${data}`}>{data}</a></div> : '';
}


const infoFields = [
    { name: 'name', text: 'Name', type: 'text' },
    { name: 'owner', text: 'Owner', type: 'multi', fields: [{ name: 'owner.name', text: 'Name', formatter: nameFormatter }, { name: 'owner.phone', text: 'Phone', formatter: phoneFormatter }, { name: 'owner.email', text: 'Email', formatter: emailFormatter }] },
]

const Image = (props) => {
    const { mainImage } = props ? props : {};
    return (
        <div style={{ minHeight: 200 }} className={'flex-fill'}>
            {mainImage && <RoundImg {...props} src={`assetto/${mainImage}`}></RoundImg>}
            {
                !mainImage &&
                <div className="h-100 d-flex text-center justify-content-center align-items-center">
                    <div style={{ width: '150px', height: '150px', margin: 'auto' }} className="d-flex rounded-circle border border-1 bg-light border-light">
                        <div className="d-flex flex-fill justify-content-center align-items-center">
                            <FontAwesomeIcon
                                className="align-self-center"
                                icon={faImage}
                                size={'2x'}
                            ></FontAwesomeIcon>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

const Info = (props) => {
    const data = { ...props };
    const fields = infoFields.filter((field) => {
        return (field.type === 'text' && data.hasOwnProperty(field.name) && data[field.name] !== undefined && data[field.name] !== "") ||
            (field.type === 'multi' && Array.isArray(field.fields) && Object.keys((data[field.name] || {}).length > 0))
    })

    return <div className="px-0"><Table size="sm" className="mb-0 px-0">
        <tbody>
            {fields.map((field, index) => {
                return <tr key={`info_${index}`} className="py-3 px-0">
                    <td className={index === 0 ? 'border-top-0' : 'border-top-1'} style={{ verticalAlign: 'top' }} ><b>{field.text}:</b></td>
                    <td className={index === 0 ? 'border-top-0' : 'border-top-1'} >
                        {field.type === 'text' && <> {data.hasOwnProperty(field.name) ? data[field.name] : ''} </>}
                        {field.type === 'multi' && Array.isArray(field.fields) && <>
                            {field.fields.map((f, index) => {
                                const value = getProperty(f?.name, data, undefined)
                                return <div key={`div_${index}`}>
                                    {value ? f.formatter !== undefined ? f.formatter(value) : value : ''}
                                </div>
                            })}
                        </>}
                    </td>
                </tr>
            }
            )}
        </tbody>
    </Table>
    </div>
}

const Devices = (props) => {
    const devices = useMemo(() => {
        const deviceYris = getProperty('@links.outwards.devices', props.asset, []);
        const tableData = [];
        deviceYris.forEach((yri) => {
            const device = getProperty(yri, props?.asset?._resources, undefined);
            if (device) {
                const yri = device?.recording?.replace(/#.+/g, '')
                const recording = getProperty(yri, props?.asset?._resources || {}, undefined)
                tableData.push({ ...device, _recording: recording });
            }
        })
        return tableData;
    }, [props.asset])

    return <div className="px-0">
        <Table striped size="sm" style={{ tableLayout: 'fixed' }} className="my-0 px-0">
            <thead>
                <tr>
                    <th className="pl-2">Name</th>
                    <th>Last seen</th>
                    <th>Signal strength</th>
                </tr>
            </thead>
            <tbody>
                {devices.map((device, index) => {
                    const lastSeen = getProperty('_recording.timestampIso', device);
                    const systemRssi = getProperty('_recording.System RSSI', device);
                    return <tr key={`row_${index}`}>
                        <td> {device.name}</td>
                        <td><LastSeen {...device} data={lastSeen}></LastSeen></td>
                        <td><SystemRSSI {...device} data={systemRssi}></SystemRSSI></td>
                    </tr>
                })}
            </tbody>
        </Table>
    </div>
}

export const AssetContext = React.createContext();
export const useAssetDetails = () => useContext(AssetContext);
export const AssetProvider = ({
    children,
    ...props
}) => {
    const [asset, setAsset] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const initAsset = async () => {
            if (props.apiClient) {
                const asseoService = new AssettoService(props.apiClient, props.collection);
                asseoService.getAsset(props.id).then((response) => {
                    setIsLoading(false);
                    setAsset(response);

                }).catch((error) => {
                    setIsLoading(false);
                })
            }
            else {
                setIsLoading(false);
            }
        };
        initAsset();
    }, [props.apiClient, props.collection, props.id]);


    return (isLoading === false && <AssetContext.Provider value={{ isLoading, asset }}>
        <Container fluid className="px-0">
            <Row>
                <Col sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 6, order: 1 }} xl={{ size: 4, order: 1 }} className="d-flex">
                    <Card body className={'flex-fill py-4 border'}>
                        <Image {...props} {...asset}></Image>
                    </Card>
                </Col>
                <Col sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 12, order: 3 }} xl={{ size: 4, order: 2 }} className="d-flex">
                    <Card body className={'flex-fill py-4 border overflow-hidden'}>
                        <CardTitle> {asset && asset.name ? asset.name : ''}</CardTitle>
                        <Info {...asset}></Info>
                    </Card>
                </Col>
                <Col sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 6, order: 2 }} xl={{ size: 4, order: 3 }} className="d-flex">
                    <Card body className={'flex-fill py-4 border overflow-hidden'}>
                        <CardTitle>Devices</CardTitle>
                        <Devices asset={asset} ></Devices>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>{children}</Col>
            </Row>
        </Container>
    </AssetContext.Provider>
    );
};