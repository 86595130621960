import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu,Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useAuth0 } from "../../utils/auth0";

const ProfileNav = () => {
    const {loading,isAuthenticated, user, logout} = useAuth0();

    let content = isAuthenticated && user ?
    <UncontrolledDropdown nav inNavbar>
    {/* <span className="d-inline-block d-sm-none">
      <DropdownToggle nav caret>
        <Settings size={18} className="align-middle" />
      </DropdownToggle>
    </span> */}
    <span className="d-sm-inline-block">
      <DropdownToggle nav caret>
        <img
          src={user.picture}
          className="avatar img-fluid rounded-circle mr-1"
          alt={user.name}
        />
        {/* <span className="text-dark">Chris Wood</span> */}
      </DropdownToggle>
    </span>
    <DropdownMenu right>
      {/* <Link to={"/profile"}>
      <DropdownItem>
        <User size={18} className="align-middle mr-2" />
        Profile
      </DropdownItem>
      </Link> */}
   
      {/* <DropdownItem>
        <PieChart size={18} className="align-middle mr-2" />
        Analytics
      </DropdownItem> */}
      {/* <DropdownItem divider /> */}
      {/* <DropdownItem>Settings & Privacy</DropdownItem>
      <DropdownItem>Help</DropdownItem> */}
          <DropdownItem onClick={() => logout({ returnTo: window.location.origin })}>Sign out</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown> :   <Link to={'/dashboard'}>
  <Button
      target="_blank"
      rel="noopener noreferrer"
      color="primary"
      className="ml-2"
    >
      Log in
    </Button>
  </Link>


return <span>{loading ? <div></div> : content}</span>
}

export default ProfileNav;