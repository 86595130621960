import React from 'react';
import async from "../components/Async";
import { Anchor as AnchorIcon } from "react-feather";
import { ComponentPropsHelper } from '../utils/componentPropsHelper';
import { AssetProvider, useAssetDetails } from '../tenants/_base/Assets/AssetProvider';
import { TabNavigation } from '../components/Youmoni/TabNavigation';
import { TabContent } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { ACTION, MATCHER, hasAccess } from '../utils/accessControl';
library.add(fas)

const Assets = async(() => import("../tenants/_base/Assets/Assets"));
const AssetTrackingMap = async(() => import("../tenants/_base/Assets/AssetTrackingMap"));
const AssetMap = async(() => import("../tenants/_base/Assets/AssetMap"));
const AssetPages = async(() => import("../tenants/_base/Assets/AssetPages"));
const AssetSensors = async(() => import("../tenants/_base/Assets/AssetSensors"));
const AssetEngineSensors = async(() => import("../tenants/_base/Assets/AssetEngineSensors"));
const AssetRoutes = async(() => import("../tenants/_base/Assets/AssetRoutes.js"));
const AssetControl = async(() => import("../tenants/_base/Assets/AssetControl"));
const AssetInformation = async(() => import("../tenants/_base/Assets/AssetInformation"));
const AssetTimeline = async(() => import("../tenants/_base/Assets/AssetTimeline"));
const AssetServiceHistory = async(() => import("../tenants/_base/Assets/AssetTimeline"));
const AssetManuals = async(() => import("../tenants/_base/Assets/AssetManuals"));
const AssetSocial = async(() => import("../tenants/_base/Assets/AssetSocial"));
const AssetNotifications = async(() => import("../tenants/_base/Assets/AssetNotifications"));
const AssetServiceRequests = async(() => import("../tenants/_base/Assets/AssetServiceRequests"));
const DeviceInformation = async(() => import("../tenants/_base/Devices/DeviceInformation"));


const CabinetInventory = async(() => import("../tenants/_base/Cabinets/CabinetInventory"));
const CabinetPurshaseTimeline = async(() => import("../tenants/_base/Cabinets/CabinetPurchaseTimeline"));
const CabinetProducts = async(() => import("../tenants/_base/Cabinets/CabinetProducts"));
const CabinetSales = async(() => import("../tenants/_base/Cabinets/CabinetSales"));


export const ComponentTypeMapping = {
    "map": AssetTrackingMap,
    "position": AssetMap,
    "sensors": AssetSensors,
    "engine": AssetEngineSensors,
    "routes": AssetRoutes,
    "control": AssetControl,
    "info": AssetInformation,
    "manuals": AssetManuals,
    "timeline": AssetTimeline,
    "notifications": AssetNotifications,
    "servicehistory": AssetServiceHistory,
    "service-requests":  AssetServiceRequests,
    "deviceinfo": DeviceInformation,
    "social": AssetSocial,
    "pages": AssetPages,
    "inventory": CabinetInventory,
    "purchasehistory": CabinetPurshaseTimeline,
    "sales": CabinetSales,
    "products": CabinetProducts,
}

const Content = (props) => {
    const { asset } = useAssetDetails()
    const { match } = props;
    const { params } = match ? match : {};
    const { collection, tab } = params ? params : {}

    if (collection && ComponentTypeMapping.hasOwnProperty(tab)) {
        const component = ComponentTypeMapping[tab];
        if (component) {
            const componentPropsHelper = new ComponentPropsHelper();
            const getComponentProps = componentPropsHelper.hasOwnProperty(tab) ? componentPropsHelper[tab] : () => { };
            const componentProps = getComponentProps(props);

            const currentTabProps = Object.assign({}, props, { collection: collection, currentAsset: asset }, componentProps)
            const tabComponent = React.createElement(component, currentTabProps);
            return <TabContent key={`tab_${tab}`} activeTab={tab} className="border-rounded">
                {tabComponent}
            </TabContent>
        }
    }
    return <></>
}

const AssetsWrapper = (props) => {
    return <Assets {...props}></Assets>
}

const CollectionTabMappings = {
    apartments: [
        { title: "Info", path: '/info', id: 'info' },
        { title: "Map", path: '/position', id: 'position' },
        { title: "Notifications", path: '/notifications', id: 'notifications' },
        { title: "Timeline", path: '/timeline', id: 'timeline' }
    ],
    housingsocieties: [
        { title: "Map", path: '/position', id: 'position' },
        { title: "Info", path: '/info', id: 'info' },
        { title: "Notifications", path: '/notifications', id: 'notifications' },
        { title: "Timeline", path: '/timeline', id: 'timeline' },
        { title: "Social", path: '/social', id: 'social' },
        { title: "Pages", path: '/pages', id: 'pages' }
    ],
    boats: [
        { title: "Map", path: '/map', id: 'map' },
        { title: "Real-Time Sensors", path: '/sensors', id: 'sensors' },
        { title: "Engine Sensors", path: '/engine', id: 'engine' },
        { title: "Notifications", path: '/notifications', id: 'notifications' },
        { title: "Information", path: '/info', id: 'info' },
        { title: "Manuals", path: '/manuals', id: 'manuals' },
        { title: "Timeline", path: '/timeline', id: 'timeline' },
        { title: "Service Requests", path: '/service-requests', id: 'service-requests' },
        { title: "Device Information", path: '/deviceinfo', id: 'deviceinfo' }
    ],
    machines: [
        { title: "Real-Time Sensors", path: '/sensors', id: 'sensors' },
        { title: "Map", path: '/map', id: 'map' },
        { title: "Control", path: '/control', id: 'map' },
        { title: "Notifications", path: '/notifications', id: 'notifications' },
        { title: "Information", path: '/info', id: 'info' },
        { title: "Manuals", path: '/manuals', id: 'manuals' },
        { title: "Timeline", path: '/timeline', id: 'timeline' },
        { title: "Service Requests", path: '/service-requests', id: 'service-requests' },
        { title: "Device Information", path: '/deviceinfo', id: 'deviceinfo' }
    ],
    trucks: [
        { title: "Map", path: '/map', id: 'map' },
        { title: "Real-Time Sensors", path: '/sensors', id: 'sensors' },
        { title: "Notifications", path: '/notifications', id: 'notifications' },
        { title: "Information", path: '/info', id: 'info' },
        { title: "Manuals", path: '/manuals', id: 'manuals' },
        { title: "Timeline", path: '/timeline', id: 'timeline' },
        { title: "Service Requests", path: '/service-requests', id: 'service-requests' },
        { title: "Device Information", path: '/deviceinfo', id: 'deviceinfo' }
    ],
    tanks: [
        { title: "Map", path: '/position', id: 'position' },
        { title: "Real-Time Sensors", path: '/sensors', id: 'sensors' },
        { title: "Notifications", path: '/notifications', id: 'notifications' },
        { title: "Information", path: '/info', id: 'info' },
        { title: "Manuals", path: '/manuals', id: 'manuals' },
        { title: "Timeline", path: '/timeline', id: 'timeline' },
        { title: "Service Requests", path: '/service-requests', id: 'service-requests' },
        { title: "Device Information", path: '/deviceinfo', id: 'deviceinfo' }
    ],
    trackers: [
        { title: "Map", path: '/map', id: 'map' },
        { title: "Real-Time Sensors", path: '/sensors', id: 'sensors' },
        { title: "Notifications", path: '/notifications', id: 'notifications' },
        { title: "Information", path: '/info', id: 'info' },
        { title: "Manuals", path: '/manuals', id: 'manuals' },
        { title: "Timeline", path: '/timeline', id: 'timeline' },
        { title: "Service Requests", path: '/service-requests', id: 'service-requests' },
        { title: "Device Information", path: '/deviceinfo', id: 'deviceinfo' }
    ],
    facilities: [
        { title: "Map", path: '/position', id: 'map' },
        { title: "Real-Time Sensors", path: '/sensors', id: 'sensors' },
        { title: "Notifications", path: '/notifications', id: 'notifications' },
        { title: "Information", path: '/info', id: 'info' },
        { title: "Manuals", path: '/manuals', id: 'manuals' },
        { title: "Timeline", path: '/timeline', id: 'timeline' },
        { title: "Service Requests", path: '/service-requests', id: 'service-requests' },
        { title: "Device Information", path: '/deviceinfo', id: 'deviceinfo' }
    ],
    dealers: [
        { title: "Map", path: '/position', id: 'position' },
        { title: "Notifications", path: '/notifications', id: 'notifications' },
        { title: "Information", path: '/info', id: 'info' },
        { title: "Timeline", path: '/timeline', id: 'timeline' },
    ],
    harbours: [
        { title: "Map", path: '/position', id: 'position' },
        { title: "Notifications", path: '/notifications', id: 'notifications' },
        { title: "Information", path: '/info', id: 'info' },
        { title: "Timeline", path: '/timeline', id: 'timeline' },
    ],
    cabinets: [
        { title: "Inventory", path: '/inventory', id: 'inventory' },
        { title: "Purchase History", path: '/purchasehistory', id: 'purchasehistory' },
        { title: "Sales", path: '/sales', id: 'sales' },
        { title: "Products", path: '/products', id: 'products' },
        { title: "Notifications", path: '/notifications', id: 'notifications' },
        { title: "Service History", path: '/servicehistory', id: 'servicehistory' },
        { title: "Timeline", path: '/timeline', id: 'timeline' },
        { title: "Map", path: '/position', id: 'position' },
        { title: "Info", path: '/info', id: 'info' },
        { title: "Manuals", path: '/manuals', id: 'manuals' },
        { title: "Device Information", path: '/deviceinfo', id: 'deviceinfo' }
    ],
    default: []
}


const AssetDetailsWrapper = (props) => {
    const { match, facets } = props;
    const { params } = match ? match : {};
    const { collection, id, tab } = params ? params : {}

    const assettabs = CollectionTabMappings.hasOwnProperty(collection) ? CollectionTabMappings[collection] : CollectionTabMappings.default;
    const tabs = assettabs.filter((tab) => hasAccess(`assets.${collection}.${tab.id}`, ACTION.ANY, facets, MATCHER.END));

    if (tab === undefined && tabs.length > 0) {
        return <Redirect to={`/assets/${collection}/${id}${tabs[0].path}`}></Redirect>
    }
    else if (tab !== undefined && !ComponentTypeMapping.hasOwnProperty(tab)) {
        return <Redirect to={`/404`}></Redirect>
    }
    else {
        let activeTab = tab !== undefined && ComponentTypeMapping.hasOwnProperty(tab) ? tab : tabs[0]?.id;
        return <AssetProvider {...props} tabs={tabs} collection={collection} id={id}>
            {activeTab !== undefined && <div className={"tab"}>
                <TabNavigation key={`assset_tabs_${new Date().getTime()}`} {...props} tabs={tabs} collection={`assets/${collection}`} id={id} activeTab={`/${activeTab}`}></TabNavigation>
                <Content {...props}></Content>
            </div>}
        </AssetProvider>
    }
}

const _path = 'assets/:collection';
const routes = [
    {
        path: `/${_path}`,
        component: AssetsWrapper,
        icon: AnchorIcon,
    },
    {
        path: `/${_path}/:id`,
        component: AssetDetailsWrapper,
        hidden: true
    },
    {
        path: `/${_path}/:id/:tab`,
        component: AssetDetailsWrapper,
        hidden: true
    },
]


export const assettoRoutes = (props) => {
    const { path, name, icon } = props
    const faIcon = icon && library?.definitions?.fas?.hasOwnProperty(icon) ? <FontAwesomeIcon icon={icon}></FontAwesomeIcon> : <></>

    return routes.map((route) => {
        return Object.assign({}, route, { name: name, icon: faIcon, collection: path, facet: `assets.${path}` }, faIcon ? { icon: faIcon } : {});
    });
}