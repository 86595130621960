import * as lodash from 'lodash';
export class FormValidator {
    validate(data, fields) {
        return validateFormInputs(data, fields);
    }
}

export const validateFormInputs = (data, fields) => {
    let isValid = false;
    let requiredFields = fields.filter((field) => field.required && !field.hidden);

    let emptyFields = {};
    requiredFields.filter((field) => data[field.name] === '' || data[field.name] === null).map((field) => emptyFields[field.name] = { invalid: true, message: 'Field is required' });

    let wrongFormat = {};
    let regExFields = fields.filter((field) => field.regex);
    regExFields.forEach((field) => {
        let testString = data[field.name];
        if (testString) {
            let regex = field.regex instanceof RegExp ? field.regex : new RegExp(field.regex);
            if (!regex.test(testString)) {
                wrongFormat[field.name] = { invalid: true, message: field.formatMessage ? field.formatMessage : 'Field has wrong format' };
            }
        }
    });


    let errors = lodash.merge(emptyFields, wrongFormat);


    const keyValuesFields = fields.filter((f) => f.type === 'keyvalue');
    keyValuesFields.forEach((f) => {
        const inputData = data[f.name];
        if (Array.isArray(inputData)) {
            const keyValueData = inputData.map((item) => {
                const invalidKey = item['value'] !== '' && item['key'] === '';
                const invalidValue = item['key'] !== '' && item['value'] === '';
                return Object.assign({}, item, { invalidKey: invalidKey, invalidValue: invalidValue });
            })

            if (lodash.some((keyValueData), (item) => item.invalidKey || item.invalidValue)) {
                errors[f.name] = { invalid: true, fields: keyValueData };
            }
            else if (!Object.keys(errors).length) {
                data[f.name] = inputData.filter((f) => f.key !== '' && f.value !== '');
            }
        }
    })

    const objectFields = fields.filter((f) => f.type === 'object');
    objectFields.forEach((field) => {
        const {fields} = field;
        const objData = data[field.name]
        const form = validateFormInputs(objData,fields);
        data[field.name] = form?.data;
        if(!form.isValid)
        {
            errors[field.name] = form.errors
        }
    })

    isValid = !Object.keys(errors).length;
    let newData = {}; 
    Object.keys(data).forEach((key) => {
        newData[key] = data[key] === '' ? null : typeof data[key] === 'string' ? data[key].trim() : data[key];
    })


    let output = {
        isValid: isValid,
        data: newData,
        errors: errors
    };
    return output;
}