import React, { Fragment, useState } from 'react';
import Toastr from '../../../components/Youmoni/Toastr';
import { AssettoService } from '../../../services/assettoService';
import { FormsService } from '../../../services/formsService';
import { Badge, Button, Card, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row, Table, UncontrolledDropdown } from 'reactstrap';
import Loader from '../../../components/Loader';
import { PreviewImg } from '../../../components/Youmoni/Img';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faComment, faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import AccessControl, { ACTION, MATCHER, hasAccess } from '../../../utils/accessControl';
import { getProperty } from '../../../utils/uiHelper';
import { Link } from 'react-router-dom';
import IconAlert from '../../../components/Youmoni/IconAlert';

const Menu = (props) => {
    const { facets, onCancel, onAddComment } = props;
    return <Nav className='px-0 nav-buttons'>
        {onAddComment && hasAccess('service-requests.comment', [ACTION.CREATE], facets, MATCHER.END) && <NavItem color={'primary'}>
            <NavLink onClick={() => { onAddComment() }}>
                <FontAwesomeIcon className="mr-2" icon={faComment}></FontAwesomeIcon> <span></span> Add Comment
            </NavLink>
        </NavItem>
        }

        {onCancel && <NavItem color={'primary'}>
            <NavLink onClick={() => { onCancel() }}>
                <FontAwesomeIcon className="mr-2" icon={faTimes}></FontAwesomeIcon> <span></span> Close
            </NavLink>
        </NavItem>}
    </Nav>
}

const CommentModal = (props) => {
    const { isOpen, onDismiss, onSave } = props;
    const [comment, setComment] = useState('');

    const _onSaveComment = () => {
        if (comment !== '') {
            onSave(`${comment}`)
        }
    }

    return <>{isOpen && <Modal centered fade={true} size={'lg'} isOpen={isOpen} toggle={() => onDismiss()}>
        <ModalHeader>Add Comment</ModalHeader>
        <ModalBody>
            <Input style={{ minHeight: 250 }} placeholder={'Add a comment...'} onChange={(e) => setComment(e.target.value)} className={'shadow-none'} type={'textarea'}></Input>
        </ModalBody>
        <ModalFooter>
            <Button onClick={() => onDismiss()}>Cancel</Button>
            <Button onClick={() => _onSaveComment()} color={'primary'}>Save</Button>
        </ModalFooter>
    </Modal>}
    </>
}

const EditCommentModal = (props) => {
    const { isOpen, onDismiss, onSave } = props;
    const [comment, setComment] = useState(props?.comment || '');

    const _onSaveComment = () => {
        if (comment !== '') {
            onSave(`${comment}`)
        }
    }

    return <>{isOpen && <Modal centered fade={true} size={'lg'} isOpen={isOpen} toggle={() => onDismiss()}>
        <ModalHeader>Add Comment</ModalHeader>
        <ModalBody>
            <Input style={{ minHeight: 250 }} placeholder={'Add a comment...'} value={comment} onChange={(e) => setComment(e.target.value)} className={'shadow-none'} type={'textarea'}></Input>
        </ModalBody>
        <ModalFooter>
            <Button onClick={() => onDismiss()}>Cancel</Button>
            <Button onClick={() => _onSaveComment()} color={'primary'}>Save</Button>
        </ModalFooter>
    </Modal>}
    </>
}

const DeleteCommentModal = (props) => {
    const { isOpen, onDismiss, onDelete } = props;
    const _onDeleteComment = () => {
        if (onDelete) {
            onDelete();
        }
    }

    return <>{isOpen && <Modal centered fade={true} size={'md'} isOpen={isOpen} toggle={() => onDismiss()}>
        <ModalHeader>Delete Comment</ModalHeader>
        <ModalBody>
            Are you sure you want to delete this comment?
        </ModalBody>
        <ModalFooter>
            <Button onClick={() => onDismiss()}>Cancel</Button>
            <Button onClick={() => _onDeleteComment()} color={'primary'}>Delete</Button>
        </ModalFooter>
    </Modal>
    }
    </>
}

const SplitButton = (props) => {
    const { field, value, color, onChange } = props;
    const defaultColor = color || 'primary'
    const options = field.options || []

    const selectedValue = value ? value : field?.options?.[0] ? field?.options[0]?.value : "N/A";

    return <UncontrolledDropdown group>
        <Button outline={true} block={true} className='shadow-none text-capitalize' color={defaultColor}>
            {
                selectedValue
            }
        </Button>
        <DropdownToggle
            split={false}
            className='shadow-none'
            outline={true}
            caret
            color={defaultColor}
        />
        <DropdownMenu>
            {
                options.map((option, index) => {
                    return <DropdownItem onClick={() => onChange(field, option?.value)} key={`ddi_${index}`}>
                        {option.label}
                    </DropdownItem>
                })
            }

        </DropdownMenu>
    </UncontrolledDropdown>

}

export class DisplayServiceRequest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            fields: [],
            data: {},
            fieldErrors: {},
            selectedComment: undefined,
            isAddCommentModalOpen: false,
            isEditCommentModalOpen: false,
            isDeleteCommentModalOpen: false,
            error: undefined
        }

        this._assettoService = new AssettoService(props.apiClient, 'service-requests');
        this._formsService = new FormsService(props.apiClient);
    }
    componentDidMount() {
        const { params, currentAsset } = this.props;



        const queryParams = params ? params : { context: ['service-request', 'navigation'], focus: currentAsset?.['@identifier'], contextual: true }
        this._initComponent(queryParams);
    }

    _initComponent = (queryParams) => {
        const { id } = this.props;
        const promises = [this._assettoService.getAsset(id, queryParams), this._formsService.getForms('service-requests')];
        Promise.all(promises).then(([servicerequestResponse, formsResponse]) => {
            const fields = Array.isArray(formsResponse.dispForm) ? formsResponse.dispForm : [];
            this.setState({ isLoading: false, data: servicerequestResponse, fields: fields });

        }).catch((error) => {
            console.log(error);
            this.setState({ isLoading: false, error: error });
            Toastr("error", "Error", "Something went wrong, please try again later.")
        });
    }

    _onDismiss = () => {
        const { onDismiss } = this.props;
        if (onDismiss) {
            onDismiss()
        }
    }

    _onAddComment = (comment) => {
        const { id, dominoUserUid, apiClient } = this.props;
        const userIdentifier = `yri://${apiClient?._tenant}.domino/memberships/${dominoUserUid}#user`

        const { data } = this.state;
        const key = moment().toISOString();
        const body = { comments: { [key]: { comment: comment, user: userIdentifier } } };

        this._assettoService.patchAsset(id, body).then((response) => {
            const comments = response?.comments;
            const newData = { ...data, comments: comments };
            this.setState({ isAddCommentModalOpen: false, data: newData });
        }).catch((error) => {
            Toastr('error', 'Something went wrong', 'Could not save comment');
        })
    }

    _onEditComment = (comment) => {
        const { id } = this.props;
        const { data, selectedComment } = this.state;
        const key = selectedComment
        const body = { comments: { [key]: { comment: comment } } }

        this._assettoService.patchAsset(id, body).then((response) => {
            const comments = response?.comments;
            const newData = { ...data, comments: comments };
            this.setState({ isEditCommentModalOpen: false, data: newData });
        }).catch((error) => {
            Toastr('error', 'Something went wrong', 'Could not save comment');
        })
    }

    _onFieldChange = (field, value) => {
        const { id, onSuccess } = this.props;
        const { data } = this.state;
        const body = { [field.name]: value }
        this._assettoService.patchAsset(id, body).then((response) => {
            const responseValue = response?.[field.name];
            const newData = { ...data, [field.name]: responseValue };
            this.setState({ data: newData });
            if (onSuccess) {
                onSuccess(data)
            }


        }).catch((error) => {
            Toastr('error', 'Something went wrong', 'Could not update service request');
        })
    }

    _onDeleteComment = () => {
        const { id } = this.props;
        const { selectedComment, data } = this.state;
        const body = { comments: { [selectedComment]: null } }
        this._assettoService.patchAsset(id, body).then((response) => {
            const comments = response?.comments;
            const newData = { ...data, "comments": comments };
            this.setState((prevState) => {
                return {
                    ...prevState,
                    data: newData,
                    isDeleteCommentModalOpen: false
                }
            })

        }).catch((error) => {
            Toastr('error', 'Something went wrong', 'Could not update service request');
        })
    }

    render() {
        const { facets } = this.props;
        const { isLoading, error, fields, data, isAddCommentModalOpen, isDeleteCommentModalOpen, isEditCommentModalOpen, selectedComment } = this.state;
        const comment = selectedComment && data?.comments?.[selectedComment]?.comment ? data?.comments?.[selectedComment]?.comment : '';
        return <>
            {isLoading && <Loader></Loader>}
            {error !== undefined && <Row className='p-2'>
                <Col><IconAlert color='danger' icon={faExclamationTriangle}>{error === 404 ? 'Service request does not exist.' : 'Something went wrong, please try again later'}</IconAlert>
                </Col>
            </Row> }
            {error === undefined &&
                <>
                    <Row className='border-bottom no-gutters px-0'>
                        <Col className='px-0'>
                            <Menu {...this.props} onAddComment={() => this.setState({ isAddCommentModalOpen: true })} onCancel={() => this._onDismiss()}></Menu>
                        </Col>
                    </Row>
                    {Array.isArray(fields) && fields.length > 0 &&
                        <Row className='mt-2 px-3 d-flex'>
                            <Col md="7" lg="8" className='flex-fill'>
                                <Card body color="white" className={"border shadow-none flex-fill"}>
                                    <CardTitle>Information</CardTitle>
                                    <Table striped size="sm" className="my-0">
                                        <tbody>
                                            {
                                                fields.filter((f) => f.type !== 'object').map((field, index) => {
                                                    return <Fragment key={`r_${index}`}>
                                                        {field.name !== "severity" && field.name !== "_assetIdentifier.name" && <tr><td><b>{field?.label}</b></td><td>{getProperty(field.name, data, '')}</td></tr>}
                                                        {field.name === "severity" && <tr><td><b>{field?.label}</b></td><td><Badge color={data?.severity}><span className='text-capitalize'>{data?.severity}</span></Badge></td></tr>}
                                                        {field.name === "_assetIdentifier.name" && <tr><td><b>{field?.label}</b></td><td><Link to={`/assets${getProperty('_assetIdentifier', data, undefined)?.['@identifier']?.replace(/yri:\/\/.+\.assetto/,'')}`}>{getProperty(field.name, data, '')}</Link></td></tr>}
                                                    </Fragment>
                                                }
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card>
                            </Col>
                            <AccessControl
                                facets={facets}
                                facet={'service-requests'}
                                actions={[ACTION.UPDATE]}
                                matcher={MATCHER.END}
                            >
                                <Col md="5" lg="4" className='d-flex'>
                                    <Card body className='border flex-fill'>
                                        <CardTitle>Actions</CardTitle>
                                        <Table striped size="sm" className="my-0">
                                            <tbody>
                                                {
                                                    fields.filter((f) => f.type !== 'object').map((field, index) => {
                                                        return <Fragment key={`r_${index}`}>
                                                            {(field.name === "status" || field.name === 'severity' || field.name === 'priority') && <tr><td><b>{field?.label}:</b></td><td className='text-right'><SplitButton onChange={(field, value) => this._onFieldChange(field, value)} field={field} value={data?.[field.name]}></SplitButton></td></tr>}
                                                        </Fragment>
                                                    }
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Card>
                                </Col>
                            </AccessControl>
                        </Row>
                    }
                    <Row className='px-3'>
                        <Col>
                            {data?.title && <h3>{data?.title}</h3>}
                            {data?.description && <div className='mb-2'><mark className={'bg-light font-italic'}>"{data.description}"</mark></div>}
                        </Col>
                    </Row>
                    {
                        Array.isArray(data?.images) && data?.images.length > 0 && <Row className='no-gutters px-3'>
                            {
                                data?.images.map((img, index) => {
                                    return <Col key={`col_${index}`} xs={{ size: 12 }} md={{ size: 'auto' }} className={'text-left'}><PreviewImg {...this.props} src={`assetto/${img?.path}`} /></Col>
                                })
                            }
                        </Row>
                    }
                    {
                        Object.keys(data?.comments || {}).length > 0 && <Row className='px-3'>
                            <Col>
                                <hr></hr>
                                <h4>Comments</h4>
                                {Object.keys(data?.comments || {}).map((key, index) => {
                                    const user = getProperty(data?.comments?.[key]?.user?.split('#').shift(), data?._resources || {})
                                    return <div key={`key_${index}`} className='mb-2 px-3'>
                                        <Row>
                                            <Col className='bg-light rounded p-2'>
                                                <b> {user?.firstName} {user?.lastName}</b>
                                                <div>
                                                    <mark className={'bg-light font-italic'}>"{data?.comments?.[key]?.comment}"</mark>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='p-0'><div className='text-left pt-1'>
                                                <i>{moment(key).fromNow()}</i>
                                            </div></Col>
                                            <Col className='text-right p-0'>
                                                {(hasAccess('service-requests.comment', [ACTION.UPDATE], facets, MATCHER.END) || user?.userUid === this.props?.dominoUserUid) && <Button color='link' onClick={() => { this.setState({ selectedComment: key, isEditCommentModalOpen: true }) }} className='shadow-none px-0 mr-2'><FontAwesomeIcon icon={faEdit}></FontAwesomeIcon></Button>}
                                                {(hasAccess('service-requests.comment', [ACTION.DELETE], facets, MATCHER.END) || user?.userUid === this.props?.dominoUserUid) && <Button color='link' onClick={() => this.setState({ selectedComment: key, isDeleteCommentModalOpen: true })} className='shadow-none px-0'><FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon></Button>}
                                            </Col>
                                        </Row>
                                    </div>
                                })}
                            </Col>
                        </Row>
                    }

                    {isAddCommentModalOpen && <CommentModal isOpen={isAddCommentModalOpen} onDismiss={() => this.setState({ isAddCommentModalOpen: false })} onSave={(comment) => this._onAddComment(comment)}></CommentModal>}
                    {comment && isEditCommentModalOpen && <EditCommentModal isOpen={isEditCommentModalOpen && comment} comment={comment} onDismiss={() => this.setState({ isEditCommentModalOpen: false, selectedComment: undefined })} onSave={(comment) => this._onEditComment(comment)}></EditCommentModal>}
                    {isDeleteCommentModalOpen && <DeleteCommentModal isOpen={isDeleteCommentModalOpen} onDismiss={() => this.setState({ isDeleteCommentModalOpen: false, selectedComment: undefined })} onDelete={() => this._onDeleteComment()}></DeleteCommentModal>}
                </>
            }

        </>
    }
}

export default DisplayServiceRequest;