import { Allotment } from "allotment";
import React, { useMemo, useRef, useState } from "react";
import { Badge, Card, CardTitle, Col, Nav, NavItem, NavLink, Row } from "reactstrap";
import { EchoService } from "../../../services/echoService";
import { GizmoService } from "../../../services/gizmoService";
import { useWindowSize } from "../../../utils/windowSize";
import PerfectScrollbar from "react-perfect-scrollbar";
import DashboardNotification from "../Notifications/DashboardNotification";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import IconAlert from "../../../components/Youmoni/IconAlert";
import { faBellSlash, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { getProperty } from "../../../utils/uiHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Toastr from "../../../components/Youmoni/Toastr";
import DisplayNotification from "../Notifications/DisplayNotification";
import Loader from "../../../components/Loader";
import { take } from "lodash";
import moment from "moment";
import { LastSeen } from "../../../components/Youmoni/DataTable/TableColumn";
import { ModalPanel } from "../../../components/Youmoni/Panels/ModalPanel";
const _ = { take: take };

const DeviceNotification = (props) => {
    const { name, uid, status, asset, resources } = props;

    const deviceId = uid.split('/').pop();
    const timestampIso = getProperty('_recording.timestampIso', props, undefined)    

    const connectedAsset = useMemo(() => {
        const assetYri = asset?.replace(/#.+/g, '');
        const currentAsset = getProperty(assetYri, resources, undefined);
        return currentAsset ? { path: `assets${currentAsset?.["@identifier"]?.replace(/yri:\/\/.+\.assetto/,'')}`, name: currentAsset?.name } : undefined;
    },[asset,resources])


    const color = status || 'dark';
    return <IconAlert color={color} icon={faExclamationTriangle}>
        <div>
            <div>
            <Link to={{ pathname: `/devices/${deviceId}` }} className={'text-dark'}><h4>{name}</h4></Link>
            </div>
            <div>Last Seen : <LastSeen uid={uid} data={timestampIso}></LastSeen></div>
            {connectedAsset && <div><Link to={connectedAsset?.path}>{connectedAsset?.name || connectedAsset?.alias }</Link></div>}
        </div>
    </IconAlert>
}

const NotificationPanels = (props) => {

    const { echoData, gizmoData, onAcknowledge, onUpdate, isEchoLoading, isGizmoLoading } = props;

    const echoItems = Array.isArray(echoData?.entities) ?  echoData.entities : [];
    const echoResources = echoData?.resources || {};

    const gizmoItems = Array.isArray(gizmoData?.entities) ? gizmoData.entities : [];
    const gizmoResources = gizmoData?.resources || {};

    const panelHeights = { 1: 185, 2: 310, 3: 450, 4: 580, 5: 710 }
    const echoSize = echoItems.length === 0 ? 200 : echoItems.length <= 5 ? panelHeights[echoItems.length] : 710;
    const { width, height } = useWindowSize();

    const defaultSizes = echoSize !== 0 ? [echoSize, height - echoSize] : undefined
    const ref = useRef();

    const [currentHeight, setHeight] = useState([]);
    return <>
        {width < 1200 &&
            <Row>
                <Col sm="12">
                    {isEchoLoading === false && <EchoNotifications {...props} items={echoItems} resources={echoResources} onAcknowledge={onAcknowledge} onUpdate={onUpdate} />}
                </Col>
                <Col sm="12">
                    {isGizmoLoading === false && <GizmoNotifications items={gizmoItems} resources={gizmoResources} />}
                </Col>
            </Row>
        }
        {width >= 1200 && isEchoLoading === false && isGizmoLoading === false &&
            <Allotment ref={ref} vertical={true} defaultSizes={defaultSizes} onChange={(e) => setHeight(e)} separator={true}>
                <Allotment.Pane minSize={echoItems !== undefined && Array.isArray(echoItems) && echoItems.length === 0 ? 180 : undefined} snap={false} style={{ height: currentHeight[0] }} className="d-flex flex-fill">
                    {isEchoLoading === false && <EchoNotifications {...props} height={currentHeight[0]} resources={echoResources} items={echoItems} onAcknowledge={onAcknowledge} onUpdate={onUpdate} />}
                </Allotment.Pane>
                <Allotment.Pane snap={false} minSize={55} className="d-flex flex-fill pt-4 rounded-bottom overflow-visible">
                    {isGizmoLoading === false && <GizmoNotifications height={currentHeight[1]} items={gizmoItems} resources={gizmoResources} />}
                </Allotment.Pane>
            </Allotment>
        }
    </>
}

const GizmoNotifications = (props) => {
    const [activeTab, setActiveTab] = useState(0)
    const { height ,resources} = props;
    const items = props?.items && Array.isArray(props.items) ? props.items : [];

    const rules = {missing :  7200 , warning: 120}

    const _getLastSeenColor = (timestamp) => {    
        const color = timestamp === undefined ? 'danger' : moment(timestamp).isBefore(moment().add(-rules?.missing, 'minutes')) ? 'missing' : moment(timestamp).isBefore(moment().add(-rules?.warning, 'minutes')) ? 'warning' : 'success';
        return color;
    }

    const style = height ? { style: { height: height - 70 } } : {};

    const statusItems = items.map((item) => {

        const recordingYri = item?.recording?.replace(/#.+/g, '');
        const recording = getProperty(recordingYri, resources, {});

        return {...item, _recording: recording, status: recording?.timestampIso ? _getLastSeenColor(recording?.timestampIso): 'missing'};
    })

    const tab0 = statusItems.filter((n) => n?.status === 'warning');
    const tab1 =  statusItems.filter((d) => d?.status === 'missing' || d?.status === undefined || d?.status === 'danger');

    const filteredItems = activeTab === 0 ? tab0: activeTab === 1 ? tab1 : [];

    const warningIndicator = tab0.length;
    const dangerIndicator = tab1.length;

    return <div className="tab w-100">
        <Nav id='nav-tabs' justified pills className="d-flex flex-fill pb-2">
            <NavItem onClick={() => setActiveTab(0)}>
                <NavLink className="position-relative" active={activeTab === 0}>
                    <div className="d-inline position-relative">
                        {warningIndicator !== 0 && <Badge pill className="text-indicator p-1 bg-warning d-flex justify-content-center"><span className="align-self-center">{warningIndicator}</span></Badge>}
                        Device Notifications
                    </div>
                </NavLink>
            </NavItem>
            <NavItem onClick={() => setActiveTab(1)}>
                <NavLink className="position-relative" active={activeTab === 1}>
                    <div className="d-inline position-relative">
                        {dangerIndicator !== 0 && <Badge pill className="text-indicator p-1 bg-dark">{dangerIndicator}</Badge>}
                        Missing Devices
                    </div>
                </NavLink>
            </NavItem>
        </Nav>
        <div className={`flex-fill`}>
            {filteredItems && Array.isArray(filteredItems) && filteredItems.length > 0 &&
                <PerfectScrollbar>
                    <div {...style}>
                        {
                            filteredItems.map((d, index) => {
                                return <div className="px-0 py-1" key={`device_notification:${index}`}>
                                    <DeviceNotification {...d} resources={resources} />
                                </div>
                            })
                        }
                    </div>
                </PerfectScrollbar>}
            {filteredItems && Array.isArray(filteredItems) && filteredItems.length === 0 &&
                <Card body className="border border-1">
                    <div className="d-flex">
                        <div className="align-self-center w-100 justify-content-center">
                            <div className="text-center">
                                <FontAwesomeIcon className="text-primary" size="2x" icon={faBellSlash}></FontAwesomeIcon>
                            </div>
                            <div className="text-center pt-2"><h5>No Current Notifications </h5></div>
                        </div>
                    </div>
                </Card>
            }
        </div>
    </div>

}

const EchoNotifications = (props) => {
    const { height } = props;
    const [isLoading, setIsLoading] = useState(false)
    const [items, setItems] = useState(props?.items && Array.isArray(props.items) ? props.items : [])
    const style = height ? { style: { height: height - 100 } } : {};

    const _onUpdate = (uid, request) => {
        setIsLoading(true)
        const tempItems = [...items]
        const service = new EchoService(props.apiClient);
        service.updateNotification(uid, request).then((response) => {
            setItems(tempItems.filter((d) => d.uid !== uid))
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            Toastr('error', 'Something went wrong', `Try again later.`)
            console.log(error);
        })
    }

    const _onReload = (response) => {
        const tempItems = [...items]
        const index = tempItems.findIndex(x => x.uid === response.uid);
        if (index > -1) {
            tempItems[index] = response;
            setItems(tempItems.filter((d) => d.status === 'pending'))

        }
    }

    const filteredItems = _.take(items, 5);

    return <>
        {isLoading && <Loader></Loader>}
        {filteredItems.length > 0 && <Card className="mb-2 border-0 pt-2 shadow-none w-100 bg-transparent">
            <CardTitle className="mb-0">Notifications</CardTitle>
            <PerfectScrollbar options={{ suppressScrollX: true }} className="scrollbar-dark">
                <div {...style}>
                    {filteredItems.map((notification, index) => {
                        return <div className="px-0 py-1" key={`device_notification:${index}`}>
                            <DashboardNotification {...props} {...notification} resources={props?.resources} editable={true}  onUpdate={_onUpdate} />
                        </div>
                    })}
                </div>
            </PerfectScrollbar>
        </Card>}
        {filteredItems.length === 0 &&
            <Card body className="border border-1">
                <CardTitle>Notifications</CardTitle>
                <div className="d-flex flex-fill">
                    <div className="align-self-center w-100 justify-content-center">
                        <div className="text-center">
                            <FontAwesomeIcon className="text-primary" size="2x" icon={faBellSlash}></FontAwesomeIcon>
                        </div>
                        <div className="text-center pt-2"><h5>No Current Notifications </h5></div>
                    </div>
                </div>
            </Card>
        }
        <QueryStringViewPanel {...props} onReload={_onReload}></QueryStringViewPanel>
    </>
}

const QueryStringViewPanel = (props) => {
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const uid = query.get("notification");
    return <>
        {uid && <ModalPanel {...props}  isOpen={uid !== null} onDismiss={() => props.history.goBack()} size="xl" title={'Notification'} className="mx-0">
            <DisplayNotification {...props} id={uid} onDismiss={() => props.history.goBack()} ></DisplayNotification>
        </ModalPanel>}
    </>
}

export default class DashboardNotifications extends React.Component {
    constructor(props) {
        super();
        this.state = {
            isEchoLoading: true,
            isGizmoLoading: true,
            echoData: [],
            gizmoData: [],
        }
        this._echoService = new EchoService(props.apiClient);
        this._gizmoService = new GizmoService(props.apiClient);
    }

    componentDidMount() {
        this._initComponent();
    }

    _initComponent = () => {
        this._getEchoNotifications().then((data) => {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    echoData: data,
                    isEchoLoading: false,
                }
            })
        })

        this._getGizmoNotifications().then((data) => {
                
            this.setState((prevState) => {
                return {
                    ...prevState,
                    gizmoData: data,
                    isGizmoLoading: false,
                }
            })
        })
    }

    _getEchoNotifications = () => {
        const {_tenant} = this?.props?.apiClient;
        return new Promise((resolve) => {
            this._echoService.getNotifications({status:'pending',contextual:true, focus: `yri://${_tenant}.assetto/root/${_tenant}`, context:['navigation','origin']}).then((response) => {
                const entities = response?.entities || [];
                const resources = response?.resources || {};
                resolve({entities: entities, resources:resources})
            }).catch((error) => {
                resolve([]);
            })
        })
    }

    _getGizmoNotifications = () => {
        return new Promise((resolve) => {
            this._gizmoService.getDashboardNotifications().then((response) => {

                const entities = response?.entities || [];
                const resources = response?.resources || {};
                resolve({entities: entities, resources:resources})
            }).catch((error) => {
                resolve([])
            });
        })
    }

    render() {
        const { isEchoLoading, isGizmoLoading, echoData, gizmoData } = this.state;
        
        return <> 
        <NotificationPanels {...this.props} isEchoLoading={isEchoLoading} isGizmoLoading={isGizmoLoading} echoData={echoData} gizmoData={gizmoData} />
        </>
    }
}