import React, { Fragment } from 'react';
import { Card, Col, Row, Table, Badge, UncontrolledDropdown, Button, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, Nav, NavItem, NavLink } from 'reactstrap';
import { EchoService } from '../../../services/echoService';
import Loader from '../../../components/Loader';
import NotificationContent from './NotificationContent';
import moment from 'moment';
import Toastr from '../../../components/Youmoni/Toastr';
import AccessControl, { ACTION, MATCHER } from '../../../utils/accessControl';
import { faFileCirclePlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getProperty } from '../../../utils/uiHelper';
import { ModalPanel } from '../../../components/Youmoni/Panels/ModalPanel';
import { DisplayServiceRequest } from '../ServiceRequests/DisplayServiceRequest';
import { NotificationToServiceRequest } from './NotificationToServiceRequest';
import { Link } from 'react-router-dom';

const actionFields = [
    { label: "Status", name: "status", options: [{ label: 'New', value: "pending" }, { label: "Acknowleged", value: 'acknowledged' }, { label: "Archive", value: 'archived' }] },
    { label: "Severity", name: "severity", options: [{ label: 'Info', value: "info" }, { label: "Warning", value: 'warning' }, { label: "Severe", value: 'severe' }, { label: "Critical", value: 'critical' }] }
];

const NotificationDetails = (props) => {
    const { onViewServiceRequest } = props;
    const fields = [
        { name: 'title', label: "Title" },
        { name: 'description', label: "Description" },
        { name: 'grouping', label: "Type", formatter: (value) => <span className='text-capitalize'>{value}</span> },
        {
            name: '_origin', label: "Origin", formatter: (value) => {
                const assetto = /yri:\/\/.+\.assetto\//;
                const gizmo = /yri:\/\/.+\.gizmo\//;
                return <>
                    {assetto.test(value?.['@identifier']) && <><Link onClick={(e) => e.stopPropagation()} to={`/assets/${value?.['@identifier']?.replace(assetto, '')}/`}>{value?.name || value?.alias}</Link></>}
                    {gizmo.test(value?.['@identifier']) && <></>}
                </>
            }
        },
        { name: 'severity', label: "Severity", formatter: (value) => <Badge color={value}><span className='text-capitalize'>{value}</span></Badge> },
        { name: 'status', label: "Status", formatter: (value) => <span className='text-capitalize'>{value}</span> },
        { name: 'creation', label: "Created", formatter: (value) => (<span>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>) },
        { name: 'modification', label: "Modified", formatter: (value) => (<span>{moment(value).format('YYYY-MM-DD HH:mm:ss')}</span>) },
    ]

    const serviceRequest = getProperty('timeline.serviceRequest', props?.data);


    return <>
        {props?.data &&
            <>
                <Row>
                    <Col sm="12" lg>
                        <Card body className="border border-1">
                            <CardTitle>{props?.data?.title}</CardTitle>
                            <Table size="sm" striped={true}>
                                <tbody>
                                    {fields.map((field, i) => {
                                        return <tr key={`tr_${i}`}>
                                            <td>{field.label}</td>
                                            <td>
                                                <>
                                                    {field.formatter === undefined && <span>{props?.data?.[field.name]}</span>}
                                                    {field.formatter !== undefined && <span>{field.formatter(props?.data?.[field.name])}</span>}
                                                </>
                                            </td>
                                        </tr>
                                    })}
                                    {
                                        serviceRequest !== undefined && <tr>
                                            <td>Service Request</td>
                                            <td><Button color={'primary'} outline={true} className='shadow-none' onClick={() => onViewServiceRequest(serviceRequest?.alias)}>{serviceRequest?.title}</Button></td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </Card>
                        <NotificationContent {...props} data={props?.data}></NotificationContent>
                    </Col>
                </Row>
            </>
        }
    </>
}

const SplitButton = (props) => {
    const { field, value, color, onChange } = props;
    const defaultColor = color || 'primary'
    const options = field.options || []

    const selectedValue = value ? value : field?.options?.[0] ? field?.options[0]?.value : "N/A";

    return <UncontrolledDropdown group>
        <Button outline={true} block={true} className='shadow-none text-capitalize' color={defaultColor}>
            {
                selectedValue
            }
        </Button>
        {options.length > 0 && <>
            <DropdownToggle
                split={false}
                className='shadow-none'
                outline={true}
                caret
                color={defaultColor}
            />
            <DropdownMenu>
                {
                    options.map((option, index) => {
                        return <DropdownItem onClick={() => onChange(field, option?.value)} key={`ddi_${index}`}>
                            {option.label}
                        </DropdownItem>
                    })
                }
            </DropdownMenu>
        </>}
    </UncontrolledDropdown>

}

const Menu = (props) => {
    const {qualifier, onCancel, onCreateServiceRequest } = props;
    const canCreateServiceRequest = qualifier?.indexOf('servicerequest') === -1;


    return <Nav className='px-0 nav-buttons'>
        {canCreateServiceRequest && <NavItem color={'primary'}>
            <NavLink onClick={() => { onCreateServiceRequest() }}>
                <FontAwesomeIcon className="mr-2" icon={faFileCirclePlus}></FontAwesomeIcon> <span></span> Create Service Request
            </NavLink>
        </NavItem>}


        {onCancel && <NavItem color={'primary'}>
            <NavLink onClick={() => { onCancel() }}>
                <FontAwesomeIcon className="mr-2" icon={faTimes}></FontAwesomeIcon> <span></span> Close
            </NavLink>
        </NavItem>}
    </Nav>
}

const DisplayServiceRequestPanel = (props) => {
    return <ModalPanel {...props} title={'Service Request'} className={'mx-0'} backdropClassName={'bg-transparent'} size="xl shadow-none">
        <DisplayServiceRequest {...props} params={{ contextual: false }} ></DisplayServiceRequest>
    </ModalPanel>
}

const NotificationToServiceRequestPanel = (props) => {
     return <ModalPanel {...props} title={'Create Service Request'} size='xl shadow-none' backdropClassName={'bg-transparent'}>
        <NotificationToServiceRequest {...props} ></NotificationToServiceRequest>
     </ModalPanel>
}

export default class DisplayNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: undefined,
            serviceRequestId: undefined,
            isNotificationToServiceRequestPanelOpen :false
        }
        this._echoService = new EchoService(props.apiClient);
    }

    componentDidMount() {
        this._initComponent();
    }

    _initComponent = () => {
        const { id } = this.props;
        this._echoService.getNotification(id).then((response) => {
            this.setState({ isLoading: false, data: response, });

        }).catch((error) => {
            console.error(error);
            this.setState({ isLoading: false });
            Toastr('error', 'Something went wrong', 'Please try again later.')
        })
    }


    _onFieldChange = (field, value) => {
        const { id, onSuccess } = this.props;
        const { data } = this.state;
        const body = { [field.name]: value }
        this.setState({ isLoading: true });
        this._echoService.updateNotification(id, body).then((response) => {
            const responseValue = response?.[field.name];
            const newData = { ...data, [field.name]: responseValue };
            this.setState({ data: newData, isLoading: false });
            if (onSuccess) {
                onSuccess()
            }


        }).catch((error) => {
            this.setState({ isLoading: false });
            Toastr('error', 'Something went wrong', 'Could not update service request');
        })
    }

    _onDismiss = () => {
        const { onDismiss } = this.props;
        if (onDismiss) {
            onDismiss();
        }
    }

    _onViewServiceRequest = (id) => {
        this.setState((prevState) => {
            return { ...prevState, serviceRequestId: id }
        })
    }

    _onCreateServiceRequest = () => {
        this.setState((prevState) => {
            return { ...prevState, isNotificationToServiceRequestPanelOpen: true }
        })
    }

    render() {
        const { facets } = this.props;
        const { isLoading, data, serviceRequestId ,isNotificationToServiceRequestPanelOpen} = this.state;
        return (<>
            {isLoading && <Loader></Loader>}
            {data && <>
                <Row className='border-bottom no-gutters px-0'>
                    <Col className='px-0'>
                        <Menu {...this.props} qualifier={data?.qualifier} onCreateServiceRequest={() => this._onCreateServiceRequest()} onCancel={() => this._onDismiss()}></Menu>
                    </Col>
                </Row>
                <Row className="pt-3 px-3">
                    <Col>
                        <NotificationDetails {...this.props} data={data} onViewServiceRequest={(id) => this._onViewServiceRequest(id)}></NotificationDetails>
                    </Col>
                    <AccessControl
                        facets={facets}
                        facet={'notifications'}
                        actions={[ACTION.UPDATE]}
                        matcher={MATCHER.END}
                    >
                        <Col md="5" lg="4">
                            <Card body className='border'>
                                <CardTitle>Actions</CardTitle>
                                <Table striped size="sm" className="my-0">
                                    <tbody>
                                        {
                                            actionFields.map((field, index) => {
                                                let tempField = {...field};
                                                if (field?.name === 'status') {
                                                    const currentIndex = field.options.findIndex((opt) => opt.value === data?.["status"]);
                                                    if (currentIndex > -1) {
                                                        const options = [...field.options].slice(currentIndex + 1);
                                                        tempField = {...tempField,options:options}
                                                    }
                                                }
                                                return <Fragment key={`r_${index}`}>
                                                    {<tr><td><b>{field?.label}:</b></td><td className='text-right'><SplitButton onChange={(tempField, value) => this._onFieldChange(tempField, value)} field={tempField} value={data?.[tempField.name]}></SplitButton></td></tr>}
                                                </Fragment>
                                            }
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Card>
                        </Col>
                    </AccessControl>
                </Row>
                {serviceRequestId && <DisplayServiceRequestPanel {...this.props} id={serviceRequestId} isOpen={serviceRequestId !== undefined} onDismiss={() => { this.setState({ serviceRequestId: undefined }) }}></DisplayServiceRequestPanel>}
                {isNotificationToServiceRequestPanelOpen && <NotificationToServiceRequestPanel {...this.props} notification={data} isOpen={isNotificationToServiceRequestPanelOpen} onDismiss={() => this.setState((prevState) => {return { ...prevState, isNotificationToServiceRequestPanelOpen: false }})} ></NotificationToServiceRequestPanel>}
            </>}
        </>);
    }
}