import React, { useState } from 'react';
import { Tooltip } from 'reactstrap'

const ColumnToolTip = (props) => {
    let placement = props.placement !== undefined ? props.placement : 'top'
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return <Tooltip placement={placement} isOpen={tooltipOpen} target={`${props.id}`} toggle={toggle}>
        {props.children}
    </Tooltip>
}
export default ColumnToolTip;