import React from 'react';
import ad from "../../assets/img/flags/ad.png";
import ae from "../../assets/img/flags/ae.png";
import af from "../../assets/img/flags/af.png";
import ag from "../../assets/img/flags/ag.png";
import ai from "../../assets/img/flags/ai.png";
import al from "../../assets/img/flags/al.png";
import am from "../../assets/img/flags/am.png";
import an from "../../assets/img/flags/an.png";
import ao from "../../assets/img/flags/ao.png";
import aq from "../../assets/img/flags/aq.png";
import ar from "../../assets/img/flags/ar.png";
import as from "../../assets/img/flags/as.png";
import at from "../../assets/img/flags/at.png";
import au from "../../assets/img/flags/au.png";
import aw from "../../assets/img/flags/aw.png";
import ax from "../../assets/img/flags/ax.png";
import az from "../../assets/img/flags/az.png";
import ba from "../../assets/img/flags/ba.png";
import bb from "../../assets/img/flags/bb.png";
import bd from "../../assets/img/flags/bd.png";
import be from "../../assets/img/flags/be.png";
import bf from "../../assets/img/flags/bf.png";
import bg from "../../assets/img/flags/bg.png";
import bh from "../../assets/img/flags/bh.png";
import bi from "../../assets/img/flags/bi.png";
import bj from "../../assets/img/flags/bj.png";
import bl from "../../assets/img/flags/bl.png";
import bm from "../../assets/img/flags/bm.png";
import bn from "../../assets/img/flags/bn.png";
import bo from "../../assets/img/flags/bo.png";
import bq from "../../assets/img/flags/bq.png";
import br from "../../assets/img/flags/br.png";
import bs from "../../assets/img/flags/bs.png";
import bt from "../../assets/img/flags/bt.png";
import bv from "../../assets/img/flags/bv.png";
import bw from "../../assets/img/flags/bw.png";
import by from "../../assets/img/flags/by.png";
import bz from "../../assets/img/flags/bz.png";
import ca from "../../assets/img/flags/ca.png";
import cc from "../../assets/img/flags/cc.png";
import cd from "../../assets/img/flags/cd.png";
import cf from "../../assets/img/flags/cf.png";
import cg from "../../assets/img/flags/cg.png";
import ch from "../../assets/img/flags/ch.png";
import ci from "../../assets/img/flags/ci.png";
import ck from "../../assets/img/flags/ck.png";
import cl from "../../assets/img/flags/cl.png";
import cm from "../../assets/img/flags/cm.png";
import cn from "../../assets/img/flags/cn.png";
import co from "../../assets/img/flags/co.png";
import cr from "../../assets/img/flags/cr.png";
import cu from "../../assets/img/flags/cu.png";
import cv from "../../assets/img/flags/cv.png";
import cw from "../../assets/img/flags/cw.png";
import cx from "../../assets/img/flags/cx.png";
import cy from "../../assets/img/flags/cy.png";
import cz from "../../assets/img/flags/cz.png";
import de from "../../assets/img/flags/de.png";
import dj from "../../assets/img/flags/dj.png";
import dk from "../../assets/img/flags/dk.png";
import dm from "../../assets/img/flags/dm.png";
import doX from "../../assets/img/flags/do.png";
import dz from "../../assets/img/flags/dz.png";
import ec from "../../assets/img/flags/ec.png";
import ee from "../../assets/img/flags/ee.png";
import eg from "../../assets/img/flags/eg.png";
import eh from "../../assets/img/flags/eh.png";
import er from "../../assets/img/flags/er.png";
import es from "../../assets/img/flags/es.png";
import et from "../../assets/img/flags/et.png";
import eu from "../../assets/img/flags/eu.png";
import fi from "../../assets/img/flags/fi.png";
import fj from "../../assets/img/flags/fj.png";
import fk from "../../assets/img/flags/fk.png";
import fm from "../../assets/img/flags/fm.png";
import fo from "../../assets/img/flags/fo.png";
import fr from "../../assets/img/flags/fr.png";
import ga from "../../assets/img/flags/ga.png";
import gb from "../../assets/img/flags/gb.png";
import gd from "../../assets/img/flags/gd.png";
import ge from "../../assets/img/flags/ge.png";
import gf from "../../assets/img/flags/gf.png";
import gg from "../../assets/img/flags/gg.png";
import gh from "../../assets/img/flags/gh.png";
import gi from "../../assets/img/flags/gi.png";
import gl from "../../assets/img/flags/gl.png";
import gm from "../../assets/img/flags/gm.png";
import gn from "../../assets/img/flags/gn.png";
import gp from "../../assets/img/flags/gp.png";
import gq from "../../assets/img/flags/gq.png";
import gr from "../../assets/img/flags/gr.png";
import gs from "../../assets/img/flags/gs.png";
import gt from "../../assets/img/flags/gt.png";
import gu from "../../assets/img/flags/gu.png";
import gw from "../../assets/img/flags/gw.png";
import gy from "../../assets/img/flags/gy.png";
import hk from "../../assets/img/flags/hk.png";
import hm from "../../assets/img/flags/hm.png";
import hn from "../../assets/img/flags/hn.png";
import hr from "../../assets/img/flags/hr.png";
import ht from "../../assets/img/flags/ht.png";
import hu from "../../assets/img/flags/hu.png";
import id from "../../assets/img/flags/id.png";
import ie from "../../assets/img/flags/ie.png";
import il from "../../assets/img/flags/il.png";
import im from "../../assets/img/flags/im.png";
import inX from "../../assets/img/flags/in.png";
import io from "../../assets/img/flags/io.png";
import iq from "../../assets/img/flags/iq.png";
import ir from "../../assets/img/flags/ir.png";
import is from "../../assets/img/flags/is.png";
import it from "../../assets/img/flags/it.png";
import je from "../../assets/img/flags/je.png";
import jm from "../../assets/img/flags/jm.png";
import jo from "../../assets/img/flags/jo.png";
import jp from "../../assets/img/flags/jp.png";
import ke from "../../assets/img/flags/ke.png";
import kg from "../../assets/img/flags/kg.png";
import kh from "../../assets/img/flags/kh.png";
import ki from "../../assets/img/flags/ki.png";
import km from "../../assets/img/flags/km.png";
import kn from "../../assets/img/flags/kn.png";
import kp from "../../assets/img/flags/kp.png";
import kr from "../../assets/img/flags/kr.png";
import kw from "../../assets/img/flags/kw.png";
import ky from "../../assets/img/flags/ky.png";
import kz from "../../assets/img/flags/kz.png";
import la from "../../assets/img/flags/la.png";
import lb from "../../assets/img/flags/lb.png";
import lc from "../../assets/img/flags/lc.png";
import li from "../../assets/img/flags/li.png";
import lk from "../../assets/img/flags/lk.png";
import lr from "../../assets/img/flags/lr.png";
import ls from "../../assets/img/flags/ls.png";
import lt from "../../assets/img/flags/lt.png";
import lu from "../../assets/img/flags/lu.png";
import lv from "../../assets/img/flags/lv.png";
import ly from "../../assets/img/flags/ly.png";
import ma from "../../assets/img/flags/ma.png";
import mc from "../../assets/img/flags/mc.png";
import md from "../../assets/img/flags/md.png";
import me from "../../assets/img/flags/me.png";
import mf from "../../assets/img/flags/mf.png";
import mg from "../../assets/img/flags/mg.png";
import mh from "../../assets/img/flags/mh.png";
import mk from "../../assets/img/flags/mk.png";
import ml from "../../assets/img/flags/ml.png";
import mm from "../../assets/img/flags/mm.png";
import mn from "../../assets/img/flags/mn.png";
import mo from "../../assets/img/flags/mo.png";
import mp from "../../assets/img/flags/mp.png";
import mq from "../../assets/img/flags/mq.png";
import mr from "../../assets/img/flags/mr.png";
import ms from "../../assets/img/flags/ms.png";
import mt from "../../assets/img/flags/mt.png";
import mu from "../../assets/img/flags/mu.png";
import mv from "../../assets/img/flags/mv.png";
import mw from "../../assets/img/flags/mw.png";
import mx from "../../assets/img/flags/mx.png";
import my from "../../assets/img/flags/my.png";
import mz from "../../assets/img/flags/mz.png";
import na from "../../assets/img/flags/na.png";
import nc from "../../assets/img/flags/nc.png";
import ne from "../../assets/img/flags/ne.png";
import nf from "../../assets/img/flags/nf.png";
import ng from "../../assets/img/flags/ng.png";
import ni from "../../assets/img/flags/ni.png";
import nl from "../../assets/img/flags/nl.png";
import no from "../../assets/img/flags/no.png";
import np from "../../assets/img/flags/np.png";
import nr from "../../assets/img/flags/nr.png";
import nu from "../../assets/img/flags/nu.png";
import nz from "../../assets/img/flags/nz.png";
import om from "../../assets/img/flags/om.png";
import pa from "../../assets/img/flags/pa.png";
import pe from "../../assets/img/flags/pe.png";
import pf from "../../assets/img/flags/pf.png";
import pg from "../../assets/img/flags/pg.png";
import ph from "../../assets/img/flags/ph.png";
import pk from "../../assets/img/flags/pk.png";
import pl from "../../assets/img/flags/pl.png";
import pm from "../../assets/img/flags/pm.png";
import pn from "../../assets/img/flags/pn.png";
import pr from "../../assets/img/flags/pr.png";
import ps from "../../assets/img/flags/ps.png";
import pt from "../../assets/img/flags/pt.png";
import pw from "../../assets/img/flags/pw.png";
import py from "../../assets/img/flags/py.png";
import qa from "../../assets/img/flags/qa.png";
import re from "../../assets/img/flags/re.png";
import ro from "../../assets/img/flags/ro.png";
import rs from "../../assets/img/flags/rs.png";
import ru from "../../assets/img/flags/ru.png";
import rw from "../../assets/img/flags/rw.png";
import sa from "../../assets/img/flags/sa.png";
import sb from "../../assets/img/flags/sb.png";
import sc from "../../assets/img/flags/sc.png";
import sd from "../../assets/img/flags/sd.png";
import se from "../../assets/img/flags/se.png";
import sg from "../../assets/img/flags/sg.png";
import sh from "../../assets/img/flags/sh.png";
import si from "../../assets/img/flags/si.png";
import sj from "../../assets/img/flags/sj.png";
import sk from "../../assets/img/flags/sk.png";
import sl from "../../assets/img/flags/sl.png";
import sm from "../../assets/img/flags/sm.png";
import sn from "../../assets/img/flags/sn.png";
import so from "../../assets/img/flags/so.png";
import sr from "../../assets/img/flags/sr.png";
import ss from "../../assets/img/flags/ss.png";
import st from "../../assets/img/flags/st.png";
import sv from "../../assets/img/flags/sv.png";
import sx from "../../assets/img/flags/sx.png";
import sy from "../../assets/img/flags/sy.png";
import sz from "../../assets/img/flags/sz.png";
import tc from "../../assets/img/flags/tc.png";
import td from "../../assets/img/flags/td.png";
import tf from "../../assets/img/flags/tf.png";
import tg from "../../assets/img/flags/tg.png";
import th from "../../assets/img/flags/th.png";
import tj from "../../assets/img/flags/tj.png";
import tk from "../../assets/img/flags/tk.png";
import tl from "../../assets/img/flags/tl.png";
import tm from "../../assets/img/flags/tm.png";
import tn from "../../assets/img/flags/tn.png";
import to from "../../assets/img/flags/to.png";
import tr from "../../assets/img/flags/tr.png";
import tt from "../../assets/img/flags/tt.png";
import tv from "../../assets/img/flags/tv.png";
import tw from "../../assets/img/flags/tw.png";
import tz from "../../assets/img/flags/tz.png";
import ua from "../../assets/img/flags/ua.png";
import ug from "../../assets/img/flags/ug.png";
import um from "../../assets/img/flags/um.png";
import us from "../../assets/img/flags/us.png";
import uy from "../../assets/img/flags/uy.png";
import uz from "../../assets/img/flags/uz.png";
import va from "../../assets/img/flags/va.png";
import vc from "../../assets/img/flags/vc.png";
import ve from "../../assets/img/flags/ve.png";
import vg from "../../assets/img/flags/vg.png";
import vi from "../../assets/img/flags/vi.png";
import vn from "../../assets/img/flags/vn.png";
import vu from "../../assets/img/flags/vu.png";
import wf from "../../assets/img/flags/wf.png";
import ws from "../../assets/img/flags/ws.png";
import xk from "../../assets/img/flags/xk.png";
import ye from "../../assets/img/flags/ye.png";
import yt from "../../assets/img/flags/yt.png";
import za from "../../assets/img/flags/za.png";
import zm from "../../assets/img/flags/zm.png";
import zw from "../../assets/img/flags/zw.png";


export const ComponentTypeMapping = {
    "AD": ad,
    "AE": ae,
    "AF": af,
    "AG": ag,
    "AI": ai,
    "AL": al,
    "AM": am,
    "AN": an,
    "AO": ao,
    "AQ": aq,
    "AR": ar,
    "AS": as,
    "AT": at,
    "AU": au,
    "AW": aw,
    "AX": ax,
    "AZ": az,
    "BA": ba,
    "BB": bb,
    "BD": bd,
    "BE": be,
    "BF": bf,
    "BG": bg,
    "BH": bh,
    "BI": bi,
    "BJ": bj,
    "BL": bl,
    "BM": bm,
    "BN": bn,
    "BO": bo,
    "BQ": bq,
    "BR": br,
    "BS": bs,
    "BT": bt,
    "BV": bv,
    "BW": bw,
    "BY": by,
    "BZ": bz,
    "CA": ca,
    "CC": cc,
    "CD": cd,
    "CF": cf,
    "CG": cg,
    "CH": ch,
    "CI": ci,
    "CK": ck,
    "CL": cl,
    "CM": cm,
    "CN": cn,
    "CO": co,
    "CR": cr,
    "CU": cu,
    "CV": cv,
    "CW": cw,
    "CX": cx,
    "CY": cy,
    "CZ": cz,
    "DE": de,
    "DJ": dj,
    "DK": dk,
    "DM": dm,
    "DO":doX,
    "DZ": dz,
    "EC": ec,
    "EE": ee,
    "EG": eg,
    "EH": eh,
    "ER": er,
    "ES": es,
    "ET": et,
    "EU": eu,
    "FI": fi,
    "FJ": fj,
    "FK": fk,
    "FM": fm,
    "FO": fo,
    "FR": fr,
    "GA": ga,
    "GB": gb,
    "GD": gd,
    "GE": ge,
    "GF": gf,
    "GG": gg,
    "GH": gh,
    "GI": gi,
    "GL": gl,
    "GM": gm,
    "GN": gn,
    "GP": gp,
    "GQ": gq,
    "GR": gr,
    "GS": gs,
    "GT": gt,
    "GU": gu,
    "GW": gw,
    "GY": gy,
    "HK": hk,
    "HM": hm,
    "HN": hn,
    "HR": hr,
    "HT": ht,
    "HU": hu,
    "ID": id,
    "IE": ie,
    "IL": il,
    "IM": im,
    "IN":inX,
    "IO": io,
    "IQ": iq,
    "IR": ir,
    "IS": is,
    "IT": it,
    "JE": je,
    "JM": jm,
    "JO": jo,
    "JP": jp,
    "KE": ke,
    "KG": kg,
    "KH": kh,
    "KI": ki,
    "KM": km,
    "KN": kn,
    "KP": kp,
    "KR": kr,
    "KW": kw,
    "KY": ky,
    "KZ": kz,
    "LA": la,
    "LB": lb,
    "LC": lc,
    "LI": li,
    "LK": lk,
    "LR": lr,
    "LS": ls,
    "LT": lt,
    "LU": lu,
    "LV": lv,
    "LY": ly,
    "MA": ma,
    "MC": mc,
    "MD": md,
    "ME": me,
    "MF": mf,
    "MG": mg,
    "MH": mh,
    "MK": mk,
    "ML": ml,
    "MM": mm,
    "MN": mn,
    "MO": mo,
    "MP": mp,
    "MQ": mq,
    "MR": mr,
    "MS": ms,
    "MT": mt,
    "MU": mu,
    "MV": mv,
    "MW": mw,
    "MX": mx,
    "MY": my,
    "MZ": mz,
    "NA": na,
    "NC": nc,
    "NE": ne,
    "NF": nf,
    "NG": ng,
    "NI": ni,
    "NL": nl,
    "NO": no,
    "NP": np,
    "NR": nr,
    "NU": nu,
    "NZ": nz,
    "OM": om,
    "PA": pa,
    "PE": pe,
    "PF": pf,
    "PG": pg,
    "PH": ph,
    "PK": pk,
    "PL": pl,
    "PM": pm,
    "PN": pn,
    "PR": pr,
    "PS": ps,
    "PT": pt,
    "PW": pw,
    "PY": py,
    "QA": qa,
    "RE": re,
    "RO": ro,
    "RS": rs,
    "RU": ru,
    "RW": rw,
    "SA": sa,
    "SB": sb,
    "SC": sc,
    "SD": sd,
    "SE": se,
    "SG": sg,
    "SH": sh,
    "SI": si,
    "SJ": sj,
    "SK": sk,
    "SL": sl,
    "SM": sm,
    "SN": sn,
    "SO": so,
    "SR": sr,
    "SS": ss,
    "ST": st,
    "SV": sv,
    "SX": sx,
    "SY": sy,
    "SZ": sz,
    "TC": tc,
    "TD": td,
    "TF": tf,
    "TG": tg,
    "TH": th,
    "TJ": tj,
    "TK": tk,
    "TL": tl,
    "TM": tm,
    "TN": tn,
    "TO": to,
    "TR": tr,
    "TT": tt,
    "TV": tv,
    "TW": tw,
    "TZ": tz,
    "UA": ua,
    "UG": ug,
    "UM": um,
    "US": us,
    "UY": uy,
    "UZ": uz,
    "VA": va,
    "VC": vc,
    "VE": ve,
    "VG": vg,
    "VI": vi,
    "VN": vn,
    "VU": vu,
    "WF": wf,
    "WS": ws,
    "XK": xk,
    "YE": ye,
    "YT": yt,
    "ZA": za,
    "ZM": zm,
    "ZW": zw,
}

export const Flag = (props) => {
    const { name, code, style } = props;
    const upperCaseCode = code !== undefined ? code.toUpperCase() : undefined;
    const icon = upperCaseCode !== undefined && ComponentTypeMapping.hasOwnProperty(upperCaseCode) ? ComponentTypeMapping[upperCaseCode] : undefined;
    const imgStyle = style ? {style: {...style}} : {};
    return <span style={{ lineHeight: .1 }} className="nav-flag p-0 align-middle float-left mr-2">
        { icon &&  <img {...imgStyle} src={icon} alt={name} />}
        </span>
}