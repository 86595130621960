import React from 'react';
import {some, uniq, filter} from 'lodash';

const _ = {
  some:some,
  uniq: uniq,
  filter:filter
}


export const hasAccess = (facet, op, facets, matcher) => {
  const tempFacets = facets !== undefined ? { ...facets } : {};
  
  if (tempFacets.hasOwnProperty('all')) {
    const currentFacet = tempFacets['all'];
    const exists = _.some(op, (o) => currentFacet.indexOf(o) > -1)
    return exists;
  }
  
  if (matcher === MATCHER.EXACT) {
    if (tempFacets.hasOwnProperty(facet)) {
      const currentFacet = tempFacets[facet];
      const exists = _.some(op, (o) => currentFacet.indexOf(o) > -1)
      return exists;
    }

    return false;
  }

  else {
    const regex = matcher === MATCHER.START ? new RegExp(`^${facet}`) : matcher === MATCHER.END ? new RegExp(`${facet}$`) : new RegExp(facet);
    const keys = _.filter(Object.keys(facets || {}), (key) => key.match(regex))
    if (keys) {

      let ops = [];
      keys.forEach((key) => {
        ops = [...ops, ...facets[key]]
      })

      const uniqueOperators = _.uniq(ops);
      const exists = _.some(op, (o) => uniqueOperators.indexOf(o) > -1);

      return exists
    }
  }

  return false;
}


const AccessControl = (props) => {
  const { facet, facets, actions, matcher, children } = props;

  const has = facet && actions ? hasAccess(facet, actions, facets || {}, matcher) : false;
  return <>
  {has && <>{children}</>}
  {!has && props.deny && <>{props.deny()}</>}
  </>
}

AccessControl.defaultProps = {
  allow: () => null,
  deny: () => null,
};

export const ACTION = {
  CREATE :'create',
  READ : 'read',
  UPDATE : 'update',
  DELETE : 'delete',
  ANY: ["create", "read", "update", 'delete']
}

export const MATCHER = {
  EXACT :'exact',
  START : 'start',
  END : 'end',
}

export default AccessControl;