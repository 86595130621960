import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { SearchBar } = Search;

const getRowStyle = (rowEvents, style) => {
    if (rowEvents) {
        return Object.assign({}, style, { cursor: 'pointer' });
    }
    else {
        return Object.assign({}, style);
    }
}

const DataTable = (props) => {
    const { hasSearch, tableHeader, keyField, columns, data, buttons, rowStyle, rowEvents, selectRow, condensed, sort, name ,hasPagination, hasFooter} = props
    let tableRowStyle = getRowStyle(rowEvents, rowStyle);

    const tableRowEvents = rowEvents ? rowEvents : {}
    const tableSelectRow = selectRow ? selectRow : { clickToSelect: false, mode: 'radio', hideSelectColumn: true }
    const isCondensed = condensed !== undefined ? condensed : false;

    const footerClasses = hasFooter ? 'visible' : 'invisible';

    const tableColumns = columns.map((column) => {
        let newColumn = Object.assign(column);
        if (!newColumn.hidden && column.text !== '') {
            newColumn.attrs = { "data-label": `${column.text}:`};
        }
        return newColumn;
    })

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
      }) => (
        <ul className="pagination">
          {
            options.map((option,index) => (
                <li key={`li_${index}`} className={`page-item ${currSizePerPage === `${option.page}` ? 'active' : ''}`}>
                    <button className='page-link' onClick={() => onSizePerPageChange(option.page)}>{ option.text }</button>
                </li>
             ))
          }
        </ul>
      );

      const withPagination = hasPagination === undefined || hasPagination === true ? Object.assign({}, {
        pagination: paginationFactory({
            sizePerPage: 10,
            sizePerPageList: [5, 10, 25, 50],
            sizePerPageRenderer: sizePerPageRenderer
        })
    }) : {}


    return <ToolkitProvider keyField={keyField} columns={tableColumns} data={data} search>
        {
            props => (
                <>
                    <Row>
                        {name && <Col sm={{ size: 'auto' }}><h3>{name}</h3></Col>}
                        {buttons && buttons.length > 0 && <Col xs="12" sm className="text-right pr-auto pr-sm-1 mb-2">
                            {buttons && buttons.map((button, index) => {
                                return <span key={`main_button_${index}`} className="mr-1">{button}</span>
                            })}
                        </Col>}
                        {
                            hasSearch &&
                            <Col xs="12" sm={{ size: 'auto' }} className="text-right mb-1 pl-1 ml-auto">
                                <SearchBar  {...props.searchProps} tableId="main-table" style={{ width: '100%' }} className={'w-100'} id="search-bar" />
                            </Col>
                        }
                    </Row>
                    {tableHeader && <>
                            {React.cloneElement(tableHeader, { ...props.searchProps })}
                    </>}
                    <Row>
                        <Col>
                            <div key={`data_table`} className="ym-datatable">
                                <BootstrapTable
                                    {...props.baseProps}
                                    {...withPagination}
                                    bootstrap4={true}
                                    bordered={false}
                                    striped={true}
                                    rowEvents={tableRowEvents}
                                    selectRow={tableSelectRow}
                                    rowStyle={tableRowStyle}
                                    condensed={isCondensed}
                                    sort={sort}
                                    hover
                                    filter={filterFactory({})}
                                    footerClasses={`${footerClasses}`}
                                    wrapperClasses="table-responsive"
                                    noDataIndication={() => <div>No result</div>}>
                                </BootstrapTable>
                            </div>
                        </Col>
                    </Row>
                </>
            )
        }
    </ToolkitProvider>
}

export const IconButton = (column, icon, onClick) => {
    const baseProps =
    {
        text: '',
        sort: false,
        align: 'center',
    }

    const columnProps = Object.assign({}, baseProps, column);
    return {
        ...columnProps,
        headerStyle: () => { return { width: "50px" }; },
        classes: 'button-class left',
        formatter: (cell, row, rowIndex, formatExtraData) => {
            return <span>
                <Button outline={true} className="btn btn-outline-primary mb-1" color="primary" onClick={(e) => { e.stopPropagation(); onClick(row) }}>
                    <FontAwesomeIcon icon={icon} />
                </Button>
            </span>
        }
    }
}

export default DataTable;