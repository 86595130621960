import React from "react";
import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";

import store from "./redux/store/index";
import { useAuth0 } from "./utils/auth0";
import Loader from "./components/Loader";
import Console from "./routes/Routes";
import { ApiProvider } from "./utils/api";


const App = () => {
  const { loading, isAuthenticated } = useAuth0();
  
  return (
    loading ? <></> :
      <Provider store={store}>
        <ApiProvider>
         <Console isAuthenticated={isAuthenticated}></Console>
        </ApiProvider>
        <ReduxToastr
          timeOut={5000}
          newestOnTop={true}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </Provider>
  );
}

export default App;
