import React from 'react';
import { FormsService } from '../../../services/formsService';
import { AssettoService } from '../../../services/assettoService';
import AddServiceRequest from '../ServiceRequests/AddServiceRequest';
import Toastr from '../../../components/Youmoni/Toastr';

export class NotificationToServiceRequest extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading:true,
      currentAsset: {},
    }

    this._formsService = new FormsService(props.apiClient);
  }

  componentDidMount(){
    const {notification} = this.props;
    const origin = notification?.origin;
    const isAsseto = this._isAssetto(origin)

  
      if(isAsseto){
        const path = origin?.replace(/yri:\/\/.+\.assetto\//,'')?.split('/');
        if(path?.length === 2)
        {
          const assetto = new AssettoService(this.props?.apiClient,path[0]);
          assetto.getAsset(path[1]).then((assettoResponse) => {
            this.setState({currentAsset:assettoResponse})     
          }).catch((error) => {
            Toastr('error','Something went wrong. Please try again later');
          })
        }
      }
  }


  _isAssetto = (yri) => {
    const regexp = /yri:\/\/.+\.assetto\/.+/
    return regexp.test(yri)
  }

  _isGizmo = (yri) => {
    const regexp = /yri:\/\/.+\.gizmo\/.+/
    return regexp.test(yri)
  }


  render(){
    const{currentAsset} = this.state;
    return <>
    <AddServiceRequest {...this.props} currentAsset={currentAsset} notification={this.props?.notification}></AddServiceRequest>
    </>
  }

}