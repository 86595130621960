import React from "react";
import { Button, Col, Row, Modal, ModalBody, Input, InputGroup, InputGroupText, FormFeedback, FormGroup, Label, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faExclamationTriangle, faEyeSlash, faKey, faRedo } from "@fortawesome/free-solid-svg-icons";

import googleBadge from '../../../assets/img/brands/google-play-badge.png'
import appleBadge from '../../../assets/img/brands/apple-appstore-badge.svg'
import { v4 as uuidv4 } from 'uuid';
import Toastr from "../../../components/Youmoni/Toastr";


const Service = (tenant) => {
    const uuid = uuidv4();
    return {

        get: (url) => {
            let promise = new Promise((resolve, reject) => {
                const requestOptions = {
                    headers: {
                        'x-tenant': tenant,
                        "x-authorization": 'anonymous',
                        "x-device": uuid,
                    }
                };
                fetch(url, requestOptions).then((response) => {
                    if (response.ok)
                        return response.json();
                    else {
                        reject(response.status)
                    }
                }).then((result) => {
                    resolve(result);
                }).catch((ex) => {
                    reject(ex);
                })
            });
            return promise;
        },

        post: (url, body) => {
            let promise = new Promise((resolve, reject) => {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'x-tenant': tenant,
                        "x-authorization": 'anonymous',
                        "x-device": uuid,
                    },
                    body: body
                };
                fetch(url, requestOptions).then((response) => {
                    if (response.ok)
                        return response.json();
                    else {
                        reject(response.status)
                    }
                }).then((result) => {
                    resolve(result);
                }).catch((ex) => {
                    reject(ex);
                })
            });
            return promise;
        }
    }
}

class Logo extends React.Component {
    constructor(props) {
        super(props)
        this.state = { path: undefined }
    }

    componentDidMount() {
        const { tenant, landlord } = this.props;

        const promises = []
        if (tenant) {
            promises.push(this._getTenantLogo(`/logos/${tenant}.png`));
        }

        if (landlord) {
            promises.push(this._getTenantLogo(`/logos/${landlord}.png`));
        }

        Promise.allSettled(promises).then(([tenantResponse, landlordResponse]) => {
            const isTennatSuccessfull = tenantResponse && tenantResponse.status === 'fulfilled';
            const isLandlordSuccessFull = landlordResponse && landlordResponse.status === 'fulfilled';
            const path = isTennatSuccessfull ? tenantResponse.value.path : isLandlordSuccessFull ? landlordResponse.value.path : undefined;
            if (path) {
                this.setState({ path: path });
            }
        });
    }

    _getTenantLogo = (path) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve({ path, ok: true })
            };
            img.onerror = () => {
                reject({ path, ok: false });
            }
            img.src = path;
        });
    }

    render() {
        const { path } = this.state;
        return <Row>
            <Col className="py-5">
                {path && <div style={{ height: '50px', width: '100%', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', backgroundImage: `url(${path})` }}></div>}
            </Col>
        </Row>

    }
}

const Intro = (props) => {
    const { tenant, invite } = props;
    return <>
        <Row>
            <Col>
                <h3>{invite?.firstName ? `Välkommen ${invite.firstName}!` : `Välkommen`}</h3>
                <div className="pb-4 mx-auto">
                    <div>Du har blivit inbjuden till <span className="text-capitalize">{tenant}</span> med <strong className="text-primary">{invite?.principal}</strong>. Fyll i ditt nya lösenord i fälten nedanför: </div>
                </div>
            </Col>
        </Row>

    </>
}

const SucsessText = (props) => {
    const { tenant, successResponse } = props;
    return <>
        <Row className="p-4">
            <Col xs={{ size: 'auto' }}>
                <FontAwesomeIcon icon={faCheck} size={'3x'} className="text-success"></FontAwesomeIcon>
            </Col>
            <Col>
                <h3>Tack för att du har accepterat inbjudan!</h3>
                <div>Du har fått ett mail med instruktioner om hur du kan ladda ner appen till din mobil.</div>
            </Col>
        </Row>
        <Row className="py-4">
            <Col className="text-center">
                {successResponse?.ios?.map((href, index) => {
                    return <div key={`ios_${index}`}> <a href={`${href}`}><img style={{ height: '42px' }} src={appleBadge} alt="apple-appstore" /></a></div>
                })}
                {successResponse?.android?.map((href, index) => {
                    return <div key={`android_${index}`}> <a href={`${href}`}><img style={{ height: '56px' }} src={googleBadge} alt="google-play" /></a></div>
                })}
            </Col>
        </Row>
    </>
}

class Invite extends React.Component {

    constructor(props) {
        super()
        this.state = {
            isLoading: true,
            isChecked: false,
            invite: undefined,
            data: { password: '', repeat: '' },
            fieldErrors: {},
            showPassword: false,
            showRepeat: false,
            error: undefined,
            inviteSuccessResponse: undefined
        }
        this._service = Service(props?.tenant);
    }

    componentDidMount() {
        this._initComponent();
    }

    _initComponent = () => {
        const query = new URLSearchParams(this.props?.location?.search);
        const token = query.get('token')
        if (token === null) {
            this.setState({ isLoading: false })
        }
        else {
            
            this._service.get(`/api/domino/invite?token=${token}`).then((response) => {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        invite: response,
                        isLoading:false,
                        error: undefined
                    }
                })
            }).catch((error) => {
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        isLoading:false,
                        error: { ...prevState.error, title: 'Någonting blev fel!', description: 'Försök igen om en stund. Om problemet fortsätter, kontakta systemadministratören.' }
                    }
                })
            })
        }
    }

    _preventCopyPaste = (e) => {
        e.preventDefault()
        return false;
    }

    _onValueChange = (fieldName, newValue) => {
        const fieldError = newValue === '' ? { invalid: true, message: 'Fältet måste fyllas i.' } : { invalid: false }
        this.setState((prevState) => {
            return {
                ...prevState,
                data: { ...prevState.data, [fieldName]: newValue },
                fieldErrors: { ...prevState.fieldErrors, [fieldName]: fieldError }
            }
        });
    }

    _isValid = () => {
        const { data } = this.state;
        const fieldErrors = Object.assign({},
            data?.password === '' ? { password: { invalid: true, message: 'Fältet måste fyllas i.' } } : {},
            data?.repeat === '' ? { repeat: { invalid: true, message: 'Fältet måste fyllas i.' } } : {}
        );

        if (Object.keys(fieldErrors).length > 0) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    fieldErrors: fieldErrors
                }
            })
            return;
        }

        const regex = /(?=^.{12,}$)/;
        const formatErrors = Object.assign({},
            !regex.test(data?.password) ? { password: { invalid: true, message: 'Lösenordet måste vara minst 12 tecken.' } } : {})


        if (Object.keys(formatErrors).length > 0) {
            this.setState((prevState) => {
                return {
                    ...prevState,
                    fieldErrors: formatErrors
                }
            })
            return;
        }

        if (data?.password !== undefined && data.repeat !== undefined && data?.password !== data?.repeat) {
            const matchError = { repeat: { invalid: true, message: 'Lösenorden stämmer inte överens.' } }
            this.setState((prevState) => {
                return {
                    ...prevState,
                    fieldErrors: matchError
                }
            })
            return;
        }

        return true
    }

    _onAcceptInvite = () => {
        if (this._isValid()) {
            const { history } = this.props;
            const { data } = this.state;
            const query = new URLSearchParams(this.props?.location?.search);
            const token = query.get('token');
            this._service.post(`api/domino/invite/${token}`, data?.password).then((response) => {
                history.replace({ pathname: `${window.location.pathname}`, search: `?status=success` })
                this.setState({ inviteSuccessResponse: response })
            }).catch((error) => {
                Toastr('error', 'Någonting blev fel!', "Försök igen om en stund. Om problemet fortsätter, kontakta systemadministratören.");
                console.log(error);
            })
        }
    }

    render() {
        const { isLoading, showPassword, showRepeat, fieldErrors, error, isChecked, invite, inviteSuccessResponse } = this.state;
        return (
            <React.Fragment>
                <div style={{ minHeight: '50vh' }} className="landing-intro">
                </div>
                <Modal centered fade={false} isOpen={true} backdropClassName={'bg-transparent'}>
                    <ModalBody className="border rounded bg-white">
                        <Logo {...this.props}></Logo>
                        {isLoading && <Row><Col className="text-center">
                            <span className="mx-auto"><Spinner color="primary" /></span>
                        </Col></Row>
                        }   
                        {
                            invite && inviteSuccessResponse === undefined && <>
                                <Intro {...this.props} invite={invite}></Intro>
                                <Row className="mb-2">
                                    <Col>
                                        <InputGroup className={`rounded border overflow-hidden ${fieldErrors?.password?.invalid === true ? 'border-danger' : ''} `}>
                                            <InputGroupText style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} className="border-0">
                                                <FontAwesomeIcon icon={faKey}></FontAwesomeIcon>
                                            </InputGroupText>
                                            <Input invalid={fieldErrors?.password?.invalid} onChange={(e) => this._onValueChange('password', e.target.value)} className="shadow-none border-0" placeholder="Lösenord" type={showPassword ? 'text' : 'password'} onCut={(e) => { this._preventCopyPaste(e); }} onPaste={(e) => { this._preventCopyPaste(e); }} onCopy={(e) => { this._preventCopyPaste(e); }} autoComplete="off" />
                                            <Button tabIndex="-1" onClick={() => this.setState({ showPassword: !showPassword })} className="shadow-none" color={'white'} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}></FontAwesomeIcon>
                                            </Button>
                                        </InputGroup>
                                        <FormFeedback className="d-block">
                                            {fieldErrors?.password?.message ? fieldErrors?.password?.message : ''}
                                        </FormFeedback>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <InputGroup className={`rounded border overflow-hidden ${fieldErrors?.repeat?.invalid === true ? 'border-danger' : ''} `}>
                                            <InputGroupText style={{ borderTopRightRadius: 0, borderRadius: 0 }} className="border-0">
                                                <FontAwesomeIcon icon={faRedo}></FontAwesomeIcon>
                                            </InputGroupText>
                                            <Input onChange={(e) => this._onValueChange('repeat', e.target.value)} placeholder="Bekräfta lösenord" className="border-0 shadow-none" type={showRepeat ? 'text' : 'password'} onCut={(e) => { this._preventCopyPaste(e); }} onPaste={(e) => { this._preventCopyPaste(e); }} onCopy={(e) => { this._preventCopyPaste(e); }} autoComplete="off" />
                                            <Button tabIndex="-1" onClick={() => this.setState({ showRepeat: !showRepeat })} className="shadow-none" color={'white'} style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                                                <FontAwesomeIcon icon={showRepeat ? faEyeSlash : faEye}></FontAwesomeIcon>
                                            </Button>
                                        </InputGroup>
                                        <FormFeedback className="d-block">
                                            {fieldErrors?.repeat?.message ? fieldErrors?.repeat?.message : ''}
                                        </FormFeedback>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="mb-4">
                                        <FormGroup check>
                                            <Input onChange={() => this.setState(({ isChecked: !isChecked }))} type="checkbox" />
                                            <Label check> Jag har tagit del av {invite?.termsUri ? <a href={invite?.termsUri} rel="noreferrer" target={'_blank'}>användarvillkoren.</a> : <span>användarvillkoren.</span>}
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <Button disabled={!isChecked} onClick={() => this._onAcceptInvite()} block color={'primary'}>Acceptera inbjudan</Button>
                                    </Col>
                                </Row>
                            </>
                        }

                        {inviteSuccessResponse !== undefined &&
                            <>
                                <SucsessText {...this.props} successResponse={inviteSuccessResponse}></SucsessText>
                            </>}

                        {!isLoading && invite === undefined && <Row>
                            <Col xs={{ size: 10, offset: 1 }} className={"text-center"}>
                            </Col>
                        </Row>}
                        {
                            error && <Row className="p-4">
                                <Col xs={{ size: 'auto' }}>
                                    <FontAwesomeIcon className="text-danger mb-2" size={'3x'} icon={faExclamationTriangle}></FontAwesomeIcon>
                                </Col>
                                <Col>
                                    <h3>{error?.title}</h3>
                                    <p>{error?.description}</p>
                                </Col>
                            </Row>
                        }
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Invite
