import React from "react";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Settings from "../components/Settings"

const Dashboard = ({ children, isAuthenticated, tenant, landlord, navigation, isAdmin }) => {

  return (<React.Fragment>
    {
      isAuthenticated &&
      <Wrapper>
        <Sidebar tenant={tenant} landlord={landlord} routes={navigation} />
        <Main>
          <Navbar isAdmin={isAdmin} />
          <Content>{children}</Content>
        </Main>
        {isAdmin &&
          <Settings></Settings>
        }
      </Wrapper>
    }
  </React.Fragment>
  );
}

export default Dashboard;
