import React, { useState } from 'react';
import { Row, Col, Button, ListGroup, ListGroupItem, Badge, Label, Modal, ModalBody, ModalHeader, ModalFooter, Table, ButtonGroup } from 'reactstrap';
import { FormField } from '../../../../../../components/Youmoni/FormFields/FormField';
import { DominoService } from '../../../../../../services/dominoService';
import { FormValidator, validateFormInputs } from '../../../../../../utils/formValidator';
import Loader from '../../../../../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faPlus, faSubtract } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { Flag } from '../../../../../../components/Youmoni/Flag';
import _ from 'lodash';
import Toastr from '../../../../../../components/Youmoni/Toastr';
import { AssettoService } from '../../../../../../services/assettoService';
import { FaIcon } from '../../../../../../components/Youmoni/FaIcon';
import IconAlert from '../../../../../../components/Youmoni/IconAlert';
import { getProperty } from '../../../../../../utils/uiHelper';


export const formFields = [
    {
        type: "text",
        name: "email",
        label: 'Email',
        placeholder: "Email",
        required: true,
        regex: /^\w+([.-]?\w+)*@\w+([.-]?w+)*(.\w{2,3})+$/,
        formatMessage: 'This is not a valid email address',
        requiredMessage: "Field is required"
    },
    {
        type: "text",
        name: "firstName",
        label: 'First Name',
        placeholder: "First Name",
        required: true,
        requiredMessage: "Field is required"
    },
    {
        type: "text",
        name: "lastName",
        label: 'Last Name',
        placeholder: "Last Name",
        required: true,
        requiredMessage: "Field is required"
    },
];

const NodeIcon = (props) => {
    const { collection, icon, nodeKey } = props;
    switch (collection) {
        case 'countries':
            return <Flag name={nodeKey} code={nodeKey}></Flag>
        default:
            return <FaIcon className={'mr-2'} icon={icon}></FaIcon>
    }
}

const Tree = ({ data = {}, ...rest }) => {
    return (<ListGroup className="border-top-0">
    {Object.keys(data).map((key, index) => {
      const node = Object.assign({}, data[key], { nodeKey: key })
      return (<TreeNode key={`key_${index}`} node={node} {...rest} />
      )
    })}
  </ListGroup>)
};

const TreeNode = ({ node, ...rest }) => {
    const { onClick, selectedNode, isExpanded, option, onSelect } = rest
    const [childVisible, setChildVisiblity] = useState(isExpanded !== undefined ? isExpanded : false);
    const hasChild = node.children ? true : false;

    const _onClick = (node) => {
        if (onClick) {
            onClick(node)
        }
    }

    const isSelected = selectedNode !== undefined && _.isEqual(node, selectedNode) ? true : false;

    return (
        <ListGroupItem className="p-0 pl-2 justify-content-center border-0">
            <ButtonGroup className='pt-2'>
                <Button color={'link'} style={{ width: 30 }} className={'shadow-none p-0 mx-2'} onClick={(e) => { setChildVisiblity((v) => !v) }}>
                    <span className={`d-inline d-tree-toggler ${childVisible ? "active" : ""}`}>
                        {hasChild && (<FontAwesomeIcon icon={childVisible ? faSubtract : faPlus} />)}
                        {!hasChild && <span className="invisible"><FontAwesomeIcon size='lg' icon={childVisible ? faPlus : faSubtract} /></span>}
                    </span>
                </Button>
                <Button color={'white'} className={'shadow-none p-0'} onClick={() => _onClick(node)}>
                    <span className='text-nowrap'>
                        <NodeIcon {...node} {...rest}></NodeIcon>
                        {onSelect && option === node?.collection && <span color='white' className='pr-2 shadow-none' onClick={(e) => { e.stopPropagation(); onSelect(isSelected ? null : node) }}><FontAwesomeIcon icon={isSelected ? faCheckSquare : faSquare}></FontAwesomeIcon></span>}
                        {isSelected && <Badge  style={{ fontSize: 14 }} color={'dark'}>{node?.name}</Badge>}
                        {!isSelected && <span style={{ fontSize: 16 }}>{node?.name}</span>}
                    </span>
                </Button>
            </ButtonGroup>

            {hasChild && childVisible && (
                <>
                    <Tree data={node.children} {...rest} className={'p-0'} />
                </>
            )}
        </ListGroupItem>
    );
};

export const PreViewModal = (props) => {
    const { isOpen, onDismiss, data, onSave, selectedTemplate, templateResources } = props
    const assignments = [];
    const templateAssignments = Array.isArray(selectedTemplate?.assignments) ? selectedTemplate?.assignments : [];
    let noOfassignments = 0;
    templateAssignments.forEach((assignment) => {
        assignment.forEach((item) => {
            const yri = item?.yri;
            const propertyName = yri?.split('/').pop();
            const roleUrl = new URL(item?.role);
            const roleYri = `${roleUrl?.protocol}${roleUrl?.pathname}`;
            const roleName = templateResources?.[roleYri]?.name || '';
            const assetName = data?.[propertyName]?.name || '';
            noOfassignments +=1;
            if(assetName !== '')
            {
                assignments.push(`${roleName}@${assetName}`);
            }
            
        })
    })

    const _onDismiss = () => {
        if (onDismiss) {
            onDismiss()
        }
    }

    const _onSave = () => {
        if (onSave) {
            onSave()
        }
    }

    return <Modal centered isOpen={isOpen} toggle={() => _onDismiss()}>
        <ModalHeader>Send Invite</ModalHeader>
        <ModalBody>
            {(assignments.length === 0 || noOfassignments !== assignments.length) && <Row><Col><IconAlert icon={faExclamationTriangle} color={'warning'}> Some assignments was not found, can not create invite.</IconAlert></Col></Row>}
            <Row>
                {assignments.length > 0 && noOfassignments === assignments.length && <Col>Are you sure you want to invite {data?.firstName} {data?.lastName} <strong>({data?.email}) </strong> as
                    <Table size='sm' className='mt-2'>
                        <tbody>
                            {assignments.map((assignment, index) => {
                                return <tr key={index}>
                                    <td className='px-0 border-0'>
                                        {assignment}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </Col>}
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button onClick={() => _onDismiss()}>Cancel</Button>
            {(assignments.length > 0 && noOfassignments === assignments.length) && <Button onClick={() => _onSave()} color='primary'>Send Invite</Button>}
        </ModalFooter>
    </Modal>
}

export const _filterByAssignments = (tenant, assignments, collection) => {
    if (Object.keys(collection || {}).length === 0) {
        return true;
    }

    const possibleOptions = [];
    const templateAssignments = Array.isArray(assignments) ? assignments : [];
    templateAssignments.forEach((assignment) => {
        assignment.forEach((item, index) => {

            if(index === 0 && item?.yri?.indexOf(`@${tenant}.assetto/${collection}`) > -1)
            {
                possibleOptions.push(item?.yri)
            }
            
        })
    })

    return _.some(possibleOptions, (opt) => opt.indexOf(`@${tenant}.assetto/${collection}`) > -1)
}

export class AddContextualMembership extends React.Component {

    constructor(props) {
        super();
        this.state = {
            data: {},
            templateField: undefined,
            fields: [],
            fieldErrors: {},
            selectedNode: undefined,
            selectedTemplate: undefined,
            isPreviewModalOpen: false,
            tree: {},
            resources: {}
        }

        this._dominoService = new DominoService(props.apiClient);
    }

    componentDidMount() {
        const { data, settings, apiClient, collection, queryParams } = this.props;
        const promises = [];
        const collections = Array.isArray(settings?.collections) ? settings.collections : [];
        

        if (collection) {
            const query = queryParams !== undefined ? queryParams : {}
            const service = new AssettoService(apiClient, collection);
            promises.push(service.getAssetNavigation(query, collections))
        }

        Promise.allSettled(promises).then(([navigationResponse]) => {
            const templates = this.props?.templates || []
            const templateOptions = templates.filter((item) => _filterByAssignments(this.props?.tenant, item?.assignments, data?.collection)).map((template) => {
                return { value: template?.alias, label: template?.name }
            })

            const navigation = navigationResponse?.status === 'fulfilled' ? navigationResponse?.value : {};
            const resources = navigation?.resources;
            const tree = navigation?.tree;
            const templateField = {
                type: "select",
                name: "type",
                label: 'Role',
                options: templateOptions,
                placeholder: "Template",
                required: true,
                requiredMessage: "Field is required"
            }

            this.setState((prevState) => { return { ...prevState, tree: tree, resources: resources, isLoading: false, templateField: templateField } });


        }).catch((error) => {
            Toastr("error", "Something went wrong", "Could not fetch memberships, Please try again later.")
            this.setState((prevState) => { return { ...prevState, isLoading: false} });
        })
    }


    _onTemplateChange = (newValue) => {
        const {data } = this.state;
        const templates = this.props?.templates || []

        const tempData = { ...data };
        let resetedData = {};
        formFields.forEach((field) => {
            resetedData[field.name] = tempData.hasOwnProperty(field.name) ? tempData[field.name] : '';
        })

        this.setState((prevState) => { return { ...prevState, data: resetedData } });
        const selectedTemplate = _.find(templates, (template) => template?.alias === newValue);
        const tempFields = [...formFields];
        let initialData = {};
        const _capitalized = (text) => `${text?.charAt(0)?.toUpperCase()}${text.slice(1)}`

        const assignments = Array.isArray(selectedTemplate?.assignments) ? selectedTemplate?.assignments : [];
        assignments.forEach((assignment) => {
            assignment.forEach((item, index) => {
                if(index === 0)
                {
                    if (item?.yri?.indexOf(`@${this.props?.tenant}.assetto/root/${this.props?.tenant}`) > -1) {
                        initialData[this?.props?.tenant] = { alias: this.props?.tenant, name: _capitalized(this?.props?.tenant) };
                    }
                    else if (item?.yri?.indexOf('assetto') > -1)
                    {
                        const collection = item?.yri?.split('/').pop();
                        const treeField =
                        {
                            type: "tree",
                            name: collection,
                            label: collection,
                            placeholder: "Location",
                            required: true,
                            option: collection,
                            requiredMessage: "Field is required"
                        }
                        tempFields.push(treeField);
                        initialData[collection] = null;
                    }
                }
            })
        })
        this.setState((prevState) => {
            return {
                ...prevState,
                selectedTemplate: selectedTemplate,
                fields: tempFields,
                data: { ...prevState.data, type: newValue, ...initialData }
            }
        });


    }

    _onValueChanged(fieldName, newValue) {
        const { fields } = this.state;
        const currentField = fields.filter((fieldItem) => fieldItem.name === fieldName)[0];
        const fieldError = currentField.required && !newValue ? { invalid: true, message: currentField.requiredMessage ? currentField.requiredMessage : 'Field is required' } : { invalid: false, message: '' };
        this.setState((prevState, props) => {
            return {
                ...prevState,
                data: { ...prevState.data, [fieldName]: newValue },
                fieldErrors: {
                    ...prevState.fieldErrors, [fieldName]: fieldError
                }
            }
        });
    }

    _onSave = () => {
        const { onDismiss, onReload, tenant } = this.props;
        const { data, fields, selectedTemplate } = this.state;
        const formValidator = new FormValidator();
        const form = formValidator.validate(data, fields);

        if (!form.isValid) {
            this.setState((prevState) => {
                return { ...prevState, fieldErrors: form.errors }
            });
            return;
        }

        const assignments = [];
        const templateAssignments = Array.isArray(selectedTemplate?.assignments) ? selectedTemplate?.assignments : [];
        templateAssignments.forEach((assignment) => {
            assignment.forEach((item) => {
                if (item?.yri?.indexOf(`@${tenant}.assetto/root/${tenant}`) > -1) {
                    assignments.push(item?.yri)
                }
                else if (item?.yri?.indexOf('assetto') > -1) {
                    const property = item?.yri?.split('/').pop();
                    const value = data.hasOwnProperty(property) ? data[property] : undefined;
                    if (value?.alias)
                        assignments.push(`${item?.yri}/${value?.alias}`)
                }
            })

        })

        const request = {
            "principal": data?.email,
            "assignments": assignments,
            "firstName": data?.firstName,
            "lastName": data?.lastName
        }

        if(request?.assignments?.length === 0)
        {
            return;
        }

        this.setState({ isLoading: true });
        this._dominoService.addMembershipInvitation(data?.type, request).then((response) => {
            this.setState({ isLoading: false, isPreviewModalOpen: false });

            if (onDismiss) {
                onDismiss()
            }
            if (onReload) {
                onReload()
            }

        }).catch((error) => {
            Toastr('error', 'Something went wrong', 'Could not send invite, please try again later')
            this.setState({ isLoading: false, isPreviewModalOpen: false });
        })
    }

    _onCancel = () => {
        const { onDismiss } = this.props;
        if (onDismiss) {
            onDismiss()
        }
    }

    _onPreview = () => {
        const { data, fields } = this.state;

        const form = validateFormInputs(data, fields);
        if (!form.isValid) {
            this.setState((prevState) => {
                return { ...prevState, fieldErrors: form.errors }
            });
            return;
        }

        this.setState({ isPreviewModalOpen: true })
    }

    _onSelectNode = (fieldName, node) => {
        const { fields ,resources} = this.state;
        const currentField = fields.filter((fieldItem) => fieldItem.name === fieldName)[0];
        const fieldError = currentField.required && !node ? { invalid: true, message: currentField.requiredMessage ? currentField.requiredMessage : 'Field is required' } : { invalid: false, message: '' };

        const hierarchy = node?.hierarchy;
        const hierarchyArray = hierarchy?.split(('|')) || [];

        let assets = {}
        hierarchyArray.forEach((item) => {
            const asset = getProperty(item,resources,undefined);
            if(asset)
            {
                assets[asset?.collection] = {alias: asset.alias, name: asset.name};
            }
        })
        this.setState((prevState) => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    ...assets,
                    [fieldName]: node,
                    
                },
                fieldErrors: {
                    ...prevState.fieldErrors, [fieldName]: fieldError
                }
            }
        })
    }

    render() {
        const { collection } = this.props;
        const { isLoading, templateField, data, selectedTemplate, fieldErrors, isPreviewModalOpen, fields, tree } = this.state;
        return (<div className='py-2'>
            {isLoading && <Loader></Loader>}
            <Row>
                {templateField && <Col className='pb-2' sm="12"><FormField
                    {...templateField}
                    value={data[templateField.name]}
                    onChange={(value) => this._onTemplateChange(value)}
                    invalid={fieldErrors[templateField.name] !== undefined ? fieldErrors[templateField.name].invalid : false}
                    errorMessage={fieldErrors[templateField.name] !== undefined ? fieldErrors[templateField.name].message : ''}
                ></FormField></Col>}
                {
                    fields.map((field, index) => {
                        return <Col sm="12" className='pb-2' key={`input_${index}`}>
                            {field?.type !== 'tree' && <FormField
                                key={`field_${index}`}
                                {...field}
                                value={data[field.name]}
                                onChange={(value) => this._onValueChanged(field.name, value)}
                                invalid={fieldErrors[field.name] !== undefined ? fieldErrors[field.name].invalid : false}
                                errorMessage={fieldErrors[field.name] !== undefined ? fieldErrors[field.name].message : ''}
                            ></FormField>}
                            {
                                field?.type === 'tree' && collection && <>
                                    <Label><strong className='text-capitalize'>{field.label}</strong> {data?.[field.name]?.name && <b>(Selected <strong>{data?.[field.name]?.name}</strong>)</b>}</Label>
                                    <span className="invalid-feedback d-flex mb-2">{fieldErrors?.[field.name] !== undefined ? fieldErrors[field.name]?.message : ''}</span>
                                    <Tree data={tree} option={field?.option} selectedNode={data?.[field?.name]} onSelect={(node) => this._onSelectNode(field?.name, node)} />
                                </>
                            }
                        </Col>
                    })
                }
            </Row>
            <Row><Col>
            </Col></Row>
            <Row>
                <Col sm="12" className={'text-right'}>
                    <Button className='mr-2' onClick={() => this._onCancel()}>Cancel</Button>
                    <Button onClick={() => this._onPreview()} color="primary">Next</Button>
                </Col>
            </Row>
            {
                isPreviewModalOpen && <PreViewModal isOpen={isPreviewModalOpen} data={data} templateResources={this?.props?.templateResources} selectedTemplate={selectedTemplate} onSave={() => this._onSave()} onDismiss={() => this.setState({ isPreviewModalOpen: false })}></PreViewModal>
            }
        </div>)
    }
}
    
export class AddMembership extends React.Component {
    constructor(props) {
        super();
        this.state = {
            data: {},
            templateField: undefined,
            templates: [],
            fields: [],
            fieldErrors: {},
            selectedNode: undefined,
            selectedTemplate: undefined,
            isPreviewModalOpen: false,
            templateResources: {},
            tree: {},
            resources: {}
        }

        this._dominoService = new DominoService(props.apiClient);
    }

    componentDidMount() {
        const { data, settings, apiClient } = this.props;
        const promises = [];
        const collections = Array.isArray(settings?.collections) ? settings.collections : [];
        promises.push(this._dominoService.getMembershipTemplates());

        const navigationSettings = settings?.navigation ? settings?.navigation : undefined
        const rootCollection = navigationSettings?.root;
        const queryParams = navigationSettings?.query ? navigationSettings?.query : { "inwards": 10, "linkage": "navigation", "outwards": 0 };

        if (rootCollection) {
            const service = new AssettoService(apiClient, rootCollection);
            promises.push(service.getAssetNavigation(queryParams, collections))
        }

        Promise.allSettled(promises).then(([memberShipTemplatesResponse, navigationResponse]) => {
            const templates = memberShipTemplatesResponse?.status === 'fulfilled' && Array.isArray(memberShipTemplatesResponse.value?.entities) ? memberShipTemplatesResponse.value?.entities.filter((item) => item?.accountId === this?.props?.apiClient?._tenant ) : [];
            const templateResources = memberShipTemplatesResponse?.status === 'fulfilled' && Object.keys(memberShipTemplatesResponse.value?.resources || {}).length > 0 ? memberShipTemplatesResponse.value?.resources : {};
            const templateOptions = templates.filter((item) => _filterByAssignments(this.props?.tenant, item?.assignments, data?.collection)).map((template) => {
                return { value: template?.alias, label: template?.name }
            })

            const navigation = navigationResponse?.status === 'fulfilled' ? navigationResponse?.value : {};
            const resources = navigation?.resources;
            const tree = navigation?.tree;
            const templateField = {
                type: "select",
                name: "type",
                label: 'Role',
                options: templateOptions,
                placeholder: "Template",
                required: true,
                requiredMessage: "Field is required"
            }

            this.setState((prevState) => { return { ...prevState, tree: tree, resources: resources, isLoading: false, templates: templates, templateField: templateField, templateResources: templateResources } });


        }).catch((error) => {
            Toastr("error", "Something went wrong", "Could not fetch memberships, Please try again later.")
            this.setState((prevState) => { return { ...prevState, isLoading: false, templates: [] } });
        })
    }


    _onTemplateChange = (newValue) => {
        const { templates, data } = this.state;

        const tempData = { ...data };
        let resetedData = {};
        formFields.forEach((field) => {
            resetedData[field.name] = tempData.hasOwnProperty(field.name) ? tempData[field.name] : '';
        })

        this.setState((prevState) => { return { ...prevState, data: resetedData } });
        const selectedTemplate = _.find(templates, (template) => template?.alias === newValue);
        const tempFields = [...formFields];
        let initialData = {};
        const _capitalized = (text) => `${text?.charAt(0)?.toUpperCase()}${text.slice(1)}`

        const assignments = Array.isArray(selectedTemplate?.assignments) ? selectedTemplate?.assignments : [];
        assignments.forEach((assignment) => {
            assignment.forEach((item, index) => {
                if(index === 0)
                {
                    if (item?.yri?.indexOf(`@${this.props?.tenant}.assetto/root/${this.props?.tenant}`) > -1) {
                        initialData[this?.props?.tenant] = { alias: this.props?.tenant, name: _capitalized(this?.props?.tenant) };
                    }
                    else if (item?.yri?.indexOf('assetto') > -1)
                    {
                        const collection = item?.yri?.split('/').pop();
                        const treeField =
                        {
                            type: "tree",
                            name: collection,
                            label: collection,
                            placeholder: "Location",
                            required: true,
                            option: collection,
                            requiredMessage: "Field is required"
                        }
                        tempFields.push(treeField);
                        initialData[collection] = null;
                    }
                }
            })
        })

        this.setState((prevState) => {
            return {
                ...prevState,
                selectedTemplate: selectedTemplate,
                fields: tempFields,
                data: { ...prevState.data, type: newValue, ...initialData }
            }
        });


    }

    _onValueChanged(fieldName, newValue) {
        const { fields } = this.state;
        const currentField = fields.filter((fieldItem) => fieldItem.name === fieldName)[0];
        const fieldError = currentField.required && !newValue ? { invalid: true, message: currentField.requiredMessage ? currentField.requiredMessage : 'Field is required' } : { invalid: false, message: '' };
        this.setState((prevState, props) => {
            return {
                ...prevState,
                data: { ...prevState.data, [fieldName]: newValue },
                fieldErrors: {
                    ...prevState.fieldErrors, [fieldName]: fieldError
                }
            }
        });
    }

    _onSave = () => {
        const { onDismiss, onReload, tenant } = this.props;
        const { data, fields, selectedTemplate } = this.state;
        const formValidator = new FormValidator();
        const form = formValidator.validate(data, fields);

        if (!form.isValid) {
            this.setState((prevState) => {
                return { ...prevState, fieldErrors: form.errors }
            });
            return;
        }

        const assignments = [];
        const templateAssignments = Array.isArray(selectedTemplate?.assignments) ? selectedTemplate?.assignments : [];
        templateAssignments.forEach((assignment) => {
            assignment.forEach((item) => {
                if (item?.yri?.indexOf(`@${tenant}.assetto/root/${tenant}`) > -1) {
                    assignments.push(item?.yri)
                }
                else if (item?.yri?.indexOf('assetto') > -1) {
                    const property = item?.yri?.split('/').pop();
                    const value = data.hasOwnProperty(property) ? data[property] : undefined;
                    if (value?.alias)
                        assignments.push(`${item?.yri}/${value?.alias}`)
                }
            })

        })

        const request = {
            "principal": data?.email,
            "assignments": assignments,
            "firstName": data?.firstName,
            "lastName": data?.lastName
        }

        if(request?.assignments?.length === 0)
        {
            return;
        }

        this.setState({ isLoading: true });
        this._dominoService.addMembershipInvitation(data?.type, request).then((response) => {
            this.setState({ isLoading: false, isPreviewModalOpen: false });

            if (onDismiss) {
                onDismiss()
            }
            if (onReload) {
                onReload()
            }

        }).catch((error) => {
            Toastr('error', 'Something went wrong', 'Could not send invite, please try again later')
            this.setState({ isLoading: false, isPreviewModalOpen: false });
        })
    }

    _onCancel = () => {
        const { onDismiss } = this.props;
        if (onDismiss) {
            onDismiss()
        }
    }

    _onPreview = () => {
        const { data, fields } = this.state;

        const form = validateFormInputs(data, fields);
        if (!form.isValid) {
            this.setState((prevState) => {
                return { ...prevState, fieldErrors: form.errors }
            });
            return;
        }

        this.setState({ isPreviewModalOpen: true })
    }

    _onSelectNode = (fieldName, node) => {
        const { fields ,resources} = this.state;
        const currentField = fields.filter((fieldItem) => fieldItem.name === fieldName)[0];
        const fieldError = currentField.required && !node ? { invalid: true, message: currentField.requiredMessage ? currentField.requiredMessage : 'Field is required' } : { invalid: false, message: '' };

        const hierarchy = node?.hierarchy;
        const hierarchyArray = hierarchy?.split(('|')) || [];

        let assets = {}
        hierarchyArray.forEach((item) => {
            const asset = getProperty(item,resources,undefined);
            if(asset)
            {
                assets[asset?.collection] = {alias: asset.alias, name: asset.name};
            }
        })
        this.setState((prevState) => {
            return {
                ...prevState,
                data: {
                    ...prevState.data,
                    ...assets,
                    [fieldName]: node,
                    
                },
                fieldErrors: {
                    ...prevState.fieldErrors, [fieldName]: fieldError
                }
            }
        })
    }

    render() {
        const { collection } = this.props;
        const { isLoading, templateField, data, selectedTemplate, templateResources, fieldErrors, isPreviewModalOpen, fields, tree } = this.state;
        return (<div className='py-2'>
            {isLoading && <Loader></Loader>}
            <Row>
                {templateField && <Col className='pb-2' sm="12"><FormField
                    {...templateField}
                    value={data[templateField.name]}
                    onChange={(value) => this._onTemplateChange(value)}
                    invalid={fieldErrors[templateField.name] !== undefined ? fieldErrors[templateField.name].invalid : false}
                    errorMessage={fieldErrors[templateField.name] !== undefined ? fieldErrors[templateField.name].message : ''}
                ></FormField></Col>}
                {
                    fields.map((field, index) => {
                        return <Col sm="12" className='pb-2' key={`input_${index}`}>
                            {field?.type !== 'tree' && <FormField
                                key={`field_${index}`}
                                {...field}
                                value={data[field.name]}
                                onChange={(value) => this._onValueChanged(field.name, value)}
                                invalid={fieldErrors[field.name] !== undefined ? fieldErrors[field.name].invalid : false}
                                errorMessage={fieldErrors[field.name] !== undefined ? fieldErrors[field.name].message : ''}
                            ></FormField>}
                            {
                                field?.type === 'tree' && collection === undefined && <>
                                    <Label><strong className='text-capitalize'>{field.label}</strong> {data?.[field.name]?.name && <b>(Selected <strong>{data?.[field.name]?.name}</strong>)</b>}</Label>
                                    <span className="invalid-feedback d-flex mb-2">{fieldErrors?.[field.name] !== undefined ? fieldErrors[field.name]?.message : ''}</span>
                                    <Tree data={tree} option={field?.option} selectedNode={data?.[field?.name]} onSelect={(node) => this._onSelectNode(field?.name, node)} />
                                </>
                            }
                        </Col>
                    })
                }
            </Row>
            <Row><Col>
            </Col></Row>
            <Row>
                <Col sm="12" className={'text-right'}>
                    <Button className='mr-2' onClick={() => this._onCancel()}>Cancel</Button>
                    <Button onClick={() => this._onPreview()} color="primary">Next</Button>
                </Col>
            </Row>
            {
                isPreviewModalOpen && <PreViewModal isOpen={isPreviewModalOpen} data={data} templateResources={templateResources} selectedTemplate={selectedTemplate} onSave={() => this._onSave()} onDismiss={() => this.setState({ isPreviewModalOpen: false })}></PreViewModal>
            }
        </div>)
    }
}

export default AddMembership;