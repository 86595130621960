import React, { useEffect, useRef} from 'react'
import  { default as ReactMapGL, GeolocateControl, NavigationControl, FullscreenControl } from 'react-map-gl'
// eslint-disable-next-line
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import mapboxgl from 'mapbox-gl';
mapboxgl.workerClass = MapboxWorker;

const MAP_CONFIG = {
    maxZoom: 20,
    mapStyle: "mapbox://styles/mapbox/light-v9",
    mapboxAccessToken: "pk.eyJ1Ijoib2RkZSIsImEiOiJjamplZnEwYmw0b2llM3ZsZmpvaG9zam53In0.D4zjizzS4qgVnfBmwioadA"
};

export const DashboardMap = (props) => {
    const { children } = props;
    return <Map {...props} height={'100%'} >{children}</Map>
}

const Map = (props) => {
    const { children, height} = props;
    const mapRef = useRef();
    const containerRef = useRef(null);

    useEffect(() => {
        if (Array.isArray(props.bounds) && props.bounds.length === 2) {
            let padding = 100;
            const width = containerRef.current ? containerRef.current.clientWidth : undefined
            const height = containerRef.current ? containerRef.current.clientHeight : undefined;
            padding = width !== undefined && height !== undefined && width > 200 && height > 200 ? padding : 0;

            mapRef?.current?.fitBounds(
                props.bounds,
                {maxZoom:12, padding: padding, duration: 1000 }
            );
        }
    }, [props.bounds]);


    const _onMapClick = (evt) => {
        const { onMapClick } = props;
        if (onMapClick) {
            onMapClick(evt);
        }
    }


    const _onLoad = (evt) => {
        const { onMapLoaded,mapButtons } = props;
        const buttons = mapButtons !== undefined && Array.isArray(mapButtons) ? mapButtons : [];
        const map = mapRef?.current?.getMap();
        if (onMapLoaded) {
            onMapLoaded();
        }
        if (map) {
            buttons.forEach((btn) => {
                map.addControl(btn.button, btn.position);
            })
        }
    }

    return (
        <div ref={containerRef} className="dashboard-map">
       <ReactMapGL ref={mapRef}
                {...MAP_CONFIG} 
                style={{width:'100%',height:height}}
                interactiveLayerIds={Array.isArray(props.layerIds) ? props.layerIds : []}
                onLoad={(evt) => _onLoad(evt)}
                onClick={(evt) => _onMapClick(evt)}
                >
                    <FullscreenControl position='top-left' className="map-ctrl position-relative" container={document.querySelector('map')} />
                    <NavigationControl position='top-left' className="mt-2 map-ctrl position-relative" />
                    <GeolocateControl position='top-left' className="mt-2 map-ctrl position-relative" positionOptions={{ enableHighAccuracy: true }} trackUserLocation={true} />
                <>
                    {children}
                </>
            </ReactMapGL>
        </div>
    );
}