import React from 'react';
import { FormGroup, Label, Col, InputGroup, InputGroupText } from 'reactstrap';
import { TextField, BooleanField, UploadField, SelectField, MultiSelectField, DateField, JsonField, ResourceField, KeyValueField, ArrayValueField, NumberField, ColorField, BBoxField, PositionField, ObjectField, LinkListField, MemberField, ResourcesField, LinkField, StringArrayField } from './InputFields';

export const InputField = (props) => {
  const { children, label, name, invalid, errorMessage, required, display, type } = props;
  const reuiredClass = required ? 'text-danger ml-1' : 'd-none'

  if (type === 'boolean') {
    return <div className='mt-2'><BooleanField {...props}></BooleanField></div>
  }

  switch (display) {
    case 'horizontal':
      return (<FormGroup row >
        {label !== '' && <Label sm="2" md="3" lg="4" for={name}><div className="font-weight-bold text-capitalize">{label}<span className={reuiredClass}>*</span></div></Label>}
        <Col sm="10" md="9" lg="8">
          {children}
          {
            invalid &&
            <span className="invalid-feedback d-block">{errorMessage}</span>
          }
        </Col>

      </FormGroup>);
      case 'group' :
           return (<FormGroup >
               <InputGroup>
                 <InputGroupText style={{borderTopRightRadius:0, borderBottomRightRadius:0}} className='border-right-0'>
                   {label}
                 </InputGroupText>
                 {children}
               </InputGroup>
              {
                invalid &&
                <span className="invalid-feedback d-block">{errorMessage}</span>
              }
      </FormGroup>);
    default:
      return (<FormGroup className={'mb-0'}>
        {label !== '' && <Label for={name}><div className="font-weight-bold text-capitalize">{label}<span className={reuiredClass}>*</span></div></Label>}
        {children}
        {
          invalid &&
          <span className="invalid-feedback d-block">{errorMessage}</span>
        }
      </FormGroup>);
  }
}

export const FieldTypeMappings = {
  text: TextField,
  number: NumberField,
  textarea: TextField,
  password: TextField,
  boolean: BooleanField,
  file: UploadField,
  select: SelectField,
  multiselect: MultiSelectField,
  date: DateField,
  json: JsonField,
  resource: ResourceField,
  keyvalue: KeyValueField,
  arrayValue: ArrayValueField,
  color: ColorField,
  bbox: BBoxField,
  position: PositionField,
  object: ObjectField,
  linklist: LinkListField,
  member: MemberField,
  resources: ResourcesField,
  link: LinkField,
  stringarray: StringArrayField
};



export const FormField = (props) => {
  let fieldControl = null;
  const fieldType = props.type;
  const hidden = props.hidden ? props.hidden : false;
  if (FieldTypeMappings.hasOwnProperty(fieldType)) {
    if (!hidden)
      fieldControl = React.createElement(FieldTypeMappings[fieldType], props);
  }

  return (fieldControl)
    ? <InputField
      className={'px-2'}
      {...props}
      description={props.description}
      required={props.required}
      errorMessage={props.errorMessage}
      disabled={props.disabled}
      accept={props.accept}
      multi={props.multi}
      invalid={props.invalid}
    >
      {fieldControl}
    </InputField>
    : null;
};

export default FormField;