import React from "react";
import { faClock, faExclamationTriangle, faSignal } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from 'uuid';
import moment from "moment";
import ColumnToolTip from "../../../components/Youmoni/ColumnToolTip";
import { getResourceData } from "./TableComponent";
import { Badge } from "reactstrap";
import ErrorBoundary from "../ErrorBoundary";

const rules = {missing :  7200 , warning: 120}

export const getLastSeenColor = (timestamp) => {
    
    const color = timestamp === undefined ? 'danger' : moment(timestamp).isBefore(moment().add(-rules?.missing, 'minutes')) ? 'missing' : moment(timestamp).isBefore(moment().add(-rules?.warning, 'minutes')) ? 'warning' : 'success';
    return color;
}

export const LastSeen = (props) => {
    const { data, uid } = props;
    const severity = data === undefined ? 'danger' : moment(data).isBefore(moment().add(-rules?.missing, 'minutes')) ? 'missing' : moment(data).isBefore(moment().add(-rules?.warning, 'minutes')) ? 'warning' : 'success';
    const key = uuidv4();
    const cssClass = `text-${severity} align-middle`;
    const value = data !== undefined ? moment(data).fromNow() : 'no-data';

    const locale = window.navigator.userLanguage || window.navigator.language
    const tooltip = data !== undefined ? moment(data).locale(locale).format('YYYY-MM-DD HH:mm:ss') : '';

    return <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }} id={`cell_${key}_${uid}`}>
        <FontAwesomeIcon className={`${cssClass}`} icon={faClock} />
        <Badge color={severity} className={`ml-2`}> {value} </Badge>
        {
            value !== 'no-data' && uid !== undefined &&
            <ColumnToolTip id={`cell_${key}_${uid}`}>
                {tooltip}
            </ColumnToolTip>
        }
    </span>;
}

export const SystemRSSI = (props) => {
    const { data, uid } = props;
    const overrideSverity = data?.timestampIso === undefined ? 'danger' : moment(data?.timestampIso).isBefore(moment().add(-rules?.missing, 'minutes')) ? 'missing' : moment(data?.timestampIso).isBefore(moment().add(-rules?.warning, 'minutes')) ? 'warning' : 'success';
    const severity = overrideSverity !== 'success' ? overrideSverity : data?.value !== undefined && data?.value > -80 ? 'success' : data?.value > -99 && data?.value !== undefined ? 'warning' : 'danger';
    const cssClass = `text-${severity} align-middle`;
    const key = uuidv4();
    const locale = window.navigator.userLanguage || window.navigator.language
    const tooltip = data?.timestampIso !== undefined ? moment(data?.timestampIso).locale(locale).format('YYYY-MM-DD HH:mm:ss') : '';


    return <span style={{ whiteSpace: 'nowrap', display: 'inline-block' }} id={`rssi_${key}_${uid}`}>
        <FontAwesomeIcon className={`${cssClass}`} icon={faSignal} />
        <Badge color={severity} className={`ml-2`}> {data?.value ? data?.value : 'no-data'}</Badge>
        {
            data?.value &&
            <ColumnToolTip id={`rssi_${key}_${uid}`}>
                {tooltip}
            </ColumnToolTip>
        }
    </span>
}

const ColumnTypeMappings = {
    timestampIso: LastSeen,
    "System RSSI": SystemRSSI,
}


export const RecordingColumn = (props) => {
    const type = props?.id?.split('.').pop();
    const component = ColumnTypeMappings.hasOwnProperty(type) ? React.createElement(ColumnTypeMappings[type], props) : <>{props?.value}</>
    return <>{component}</>
}

export const getRecordingColumn = (inputColumn, resources) => {
    const column = {
      id: inputColumn.accessor,
      Header: inputColumn.Header,
      accessor: (props) => {
        const data = getResourceData(inputColumn.accessor, props, resources);
        return <ErrorBoundary component={<FontAwesomeIcon className="text-warning" icon={faExclamationTriangle} />}><RecordingColumn {...props} id={inputColumn?.accessor} data={data}></RecordingColumn></ErrorBoundary> 
      }
    };
    return column;
  }
