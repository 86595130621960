import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { NavLink, withRouter,Link } from "react-router-dom";
import { Badge, Button, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { translate } from "react-polyglot";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ym from '../assets/img/brands/youmoni_symbol_final_colour.svg';
import { faHeadset } from "@fortawesome/free-solid-svg-icons";


const SidebarCategory = withRouter(
  ({
    name,
    badgeColor,
    badgeText,
    icon: Icon,
    isOpen,
    children,
    onClick,
    location,
    to
  }) => {
    const getSidebarItemClass = path => {
      return location.pathname.startsWith(path) ||
        (location.pathname === "/" && path === "/dashboard")
        ? "active"
        : "";
    };

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
          data-toggle="collapse"
          className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
          onClick={onClick}
          aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3" />
          <span className="align-middle">{name}</span>
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </span>
        <Collapse isOpen={isOpen}>
          <ul id="item" className={"sidebar-dropdown list-unstyled"}>
            {children}
          </ul>
        </Collapse>
      </li>
    );
  }
);

const SidebarItem = withRouter(
  ({ name, badgeColor, badgeText, icon, location, to, onClick }) => {
    const getSidebarItemClass = path => {
      return location.pathname.startsWith(path) ? "active" : ""; 
    };

    const collapseOnClick = () => {
      onClick();
      // dispatch(toggleSidebar());
    }
   

    return (
      <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <NavLink to={to} className="sidebar-link text-capitalize" onClick={() => collapseOnClick()} activeClassName="active">
          {icon && <span style={{width:25}} className="d-inline-block align-middle mr-3">{icon}</span>}
          {name}
          {badgeColor && badgeText ? (
            <Badge color={badgeColor} size={18} className="sidebar-badge">
              {badgeText}
            </Badge>
          ) : null}
        </NavLink>
      </li>
    );
  }
);

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: ''
    };
  }

  toggle = index => {
    // Collapse all elements
    Object.keys(this.state).forEach(
      item =>
        this.state[index] ||
        this.setState(() => ({
          [item]: false
        }))
    );

    // Toggle selected element
    this.setState(state => ({
      [index]: !state[index]
    }));
  };

  componentDidMount() {
    /* Open collapse element that matches current url */
    const pathName = this.props.location.pathname;

    //READING routs from Props instead of importing routes in top of page
    this.props.routes.forEach((route, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/" ? true : false;

      this.setState(() => ({
        [index]: isActive || isOpen || isHome
      }));
    });

    //Get Logo for current Tenant
    const { tenant, landlord } = this.props;
    const promises = [];
    promises.push(this.getTenantLogo(`/logos/${tenant}.png`))
    if(landlord && landlord !== ".")
    {
      promises.push(this.getTenantLogo(`/logos/${landlord}.png`))
    }
    Promise.allSettled(promises).then(([tenantResponse, landlordResponse]) => {
      const isTennatSuccessfull = tenantResponse?.status === 'fulfilled';
      const isLandlordSuccessFull = landlordResponse?.status === 'fulfilled';
      const path = isTennatSuccessfull ? tenantResponse.value.path : isLandlordSuccessFull ? landlordResponse.value.path : undefined;
      if (path) {
        this.setState({ logo: path });
      }
    });
  }

  componentWillUnmount() {
    this.setState = () => { return; };
  }

  collapseOnClick = () => {
    if (window.innerWidth <= 992) {
      let { dispatch } = this.props;
      dispatch(toggleSidebar());
    }
  }

  getTenantLogo = (path) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve({ path, ok: true })
      };
      img.onerror = () => {
        reject({ path, ok: false });
      }
      img.src = path;
    });
  }

  render() {
    const { sidebar} = this.props;
    const { logo} = this.state;
    return (
      <nav
        className={
          "sidebar" +
          (!sidebar.isOpen ? " toggled" : "") +
          (sidebar.isSticky ? " sidebar-sticky" : "")
        }
      >
        <div className="sidebar-content">
          <PerfectScrollbar>
            <Link className="sidebar-brand" to="/">
              {logo !== '' && <span style={{height:'35px', width:'100%', display:'inline-block',backgroundRepeat:'no-repeat',backgroundSize:'contain', backgroundImage:`url(${logo})`}}></span>}
              
              {/* <span style={{height:'24'}}>
                <FontAwesomeIcon className="float-right align-middle text-primary " size={'2x'} icon={faHome}></FontAwesomeIcon>
              </span> */}

              
              {/* <img style={{maxWidth:'100%'}} src={this.props.logo} alt={'brand'}/> */}
              {/** CHANGED DEFAULT TO SET BRAND LOGO */}
              {/* <Box className="align-middle text-primary" size={24} />{" "} 
              <span className="align-middle">AppStack</span> */}
            </Link>

            <ul className="sidebar-nav">
              {this.props.routes.map((category, index) => {
                return (
                  <React.Fragment key={index}>
                    {category.header && !category.hidden? (
                      <li className="sidebar-header">{category.header}</li>
                    ) : null}
                    {category.children && !category.hidden ? (
                      <SidebarCategory
                        name={category.name}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        icon={category.icon}
                        to={category.path}
                        isOpen={this.state[index]}
                        onClick={() => this.toggle(index)}
                      >
                        {category.children.map((route, index) => ( 
                          (route.path.indexOf(':') === -1 || !route.hidden) && //ADDED FIX NOT TO SHOW ID PATHS Or ADMIN
                          <SidebarItem
                            key={index}
                            name={route.name}
                            to={route.path}
                            badgeColor={route.badgeColor}
                            badgeText={route.badgeText} 
                            onClick={this.collapseOnClick}
                          />
                        )
                        )}
                      </SidebarCategory>
                    ) :  (
                    !category.hidden && //ADDED FIX NOT TO SHOW ID PATHS 
                      <SidebarItem
                        name={category.name}
                        to={category.path.indexOf(':collection') > -1 ? category.path.replace(':collection', category.collection?.toLowerCase()) : category.path}
                        icon={category.icon}
                        badgeColor={category.badgeColor}
                        badgeText={category.badgeText}
                        onClick={this.collapseOnClick}
                      />
                    )
                  }
                  </React.Fragment>
                );
              })}
            </ul>
            <Version></Version>
          </PerfectScrollbar>
        </div>
      </nav>
    );
  }
}

const Version = () => {
  const { REACT_APP_VERSION } = process.env;
  return <> { <div style={{maxWidth:255, minWidth:255}} className="sidebar-bottom bg-transparent p-0 d-none d-lg-block">
       <ul className="sidebar-nav p-0">

        <li className="sidebar-item">
        <a className="sidebar-link" target='_blank' rel="noopener noreferrer" href="https://youmoni.atlassian.net/servicedesk/">
          <FontAwesomeIcon className="align-self-center mr-3" size="lg" icon={faHeadset}></FontAwesomeIcon>
          Service Desk</a>
        </li>
       {REACT_APP_VERSION && REACT_APP_VERSION !== '' && <li className="sidebar-item mt-1 d-flex">
       <span className="px-4 py-0">
       <img
          className="align-self-center mr-3"
        src={ym}
        alt="ym"
        width="17"
        height="17"
      />
        {REACT_APP_VERSION}
       </span>
        </li>}
       </ul>
       
  </div>
  }
  </>
}

export default translate() (withRouter(
  connect(store => ({
    sidebar: store.sidebar,
    layout: store.layout,
  }))(Sidebar)
));
