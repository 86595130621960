export class PermissionService {
    
    constructor(apiClient) {
        this._apiClient = apiClient;
    }

    getPermissions() {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`permissions`).then((response) => {
                if(response.ok)
                {
                    return response.json()
                }
                reject(response.status);
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getConfig = (id) => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`config/${id}`).then((response) => {
                if (response.ok) {
                    return response.json()
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getAlias = () => {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.head(`alias`).then((response) => {
                if (response.ok) {
                    let alias = response.headers.get('x-alias');
                    return alias;
                }
                reject(response.status);
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }
}