import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorClosed, faDoorOpen, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { Card, ListGroup, ListGroupItem, Table, Badge, Spinner } from 'reactstrap';
import ReactJson from 'react-json-view'
import Viewer from 'react-viewer';
import { ResourceService } from '../../../services/resourceService';

export const NotificationContent = (props) => {
  let component = null;
  const { qualifier } = props;
  if (qualifier !== undefined && QualifierTypeMappings.hasOwnProperty(qualifier)) {
    const control = QualifierTypeMappings[qualifier];
    if (control) {
      component = React.createElement(QualifierTypeMappings[qualifier], props);
    }
  }
  return <>
    {component && <Card body className="bg-white border border-1">{component}</Card>}
    {component === null && <DefaultContent {...props}></DefaultContent>}
  </>
}

const ImgViewer = (props) => {
  const { activeIndex, visible, onClose, images } = props;
  return <Viewer
      zIndex={1055}
      visible={visible}
      activeIndex={activeIndex ? activeIndex : 0}
      noImgDetails={true}
      rotatable={false}
      attribute={false}
      changeable={true}
      noFooter={true}
      onClose={() => { onClose(); }}
      onMaskClick={() => { onClose(); }}
      images={images.map((img, index) => {
          return { src: img, alt: `image_${index}` }
      })}
  />
}

const KeyValueTable = (data) => {
  return Object.keys(data) && <Table size={'sm'} striped={true} responsive={true}>
    <tbody>
      {Object.keys(data).map((key, index) => {
        return <tr key={`tr_${index}`}>
          <td><span className="text-capitalize">{key}</span></td>
          <td><span className="text-break">{data[key]}</span></td>
        </tr>
      })}
    </tbody>
  </Table>
}

const DefaultContent = (props) => {
  const { data } = props;
  return <> {
    data && Object.keys(data).length > 0 &&
    <Card body className="bg-white border border-1">
      <ReactJson collapsed={true} src={props.data} name={false} displayObjectSize={false} displayDataTypes={false} enableClipboard={false} />
    </Card>
  }
  </>
}

const CabinetInventoryDiscrepancy = (props) => {
  const { timeline } = props;
  return <>
    {timeline && timeline.data && timeline.data.manualCount && timeline.data.manualCount.length > 0 &&
      <div className="py-2">
        <h4>Manual Count</h4>
        <Table striped={true} size={'sm'}>
          <thead>
            <tr>
              <th>Article</th>
              <th>Full Cylinders</th>
              <th>Empty Cylinders</th>
            </tr>
          </thead>
          <tbody>
            {
              timeline.data.manualCount.map((c, index) => {
                return <tr key={`manual_${index}`}>
                  <td>{c.article}</td>
                  <td>{c.fullCylinders}</td>
                  <td>{c.emptyCylinders}</td>
                </tr>
              })
            }
          </tbody>
        </Table>
      </div>
    }
    {timeline && timeline.data && timeline.data.sensorCount && timeline.data.sensorCount.length > 0 &&
      <div className="py-2">
        <h4>Sensor Count</h4>
        <Table striped={true} size={'sm'}>
          <thead>
            <tr>
              <th>Article</th>
              <th>Full Cylinders</th>
              <th>Empty Cylinders</th>
            </tr>
          </thead>
          <tbody>
            {
              timeline.data.sensorCount.map((c, index) => {
                return <tr key={`manual_${index}`}>
                  <td>{c.article}</td>
                  <td>{c.fullCylinders}</td>
                  <td>{c.emptyCylinders}</td>
                </tr>
              })
            }
          </tbody>
        </Table>
      </div>}
  </>
}

const CabinetAlarm = (props) => {
  const { title, timeline } = props;
  const cabinetDoors = timeline && timeline.doors && timeline.doors.length > 0 ? timeline.doors : []
  const cabinetLocks = timeline && timeline.locks && timeline.locks.length > 0 ? timeline.locks : []
  return <>
    {title && <h4>{title}</h4>}
    <Table size='sm' striped={true}>
      <tbody>
        <tr>
          <td>Doors</td>
          {cabinetDoors.map((door, index) => {
            return <td align={'center'} key={`td_door_${index}`}><FontAwesomeIcon size={'lg'} icon={door === 'opened' ? faDoorOpen : faDoorClosed}></FontAwesomeIcon></td>
          })}
        </tr>
        <tr>
          <td>Locks</td>
          {cabinetLocks.map((lock, index) => {
            return <td align={'center'} key={`td_lock_${index}`}><FontAwesomeIcon size={'lg'} icon={lock === 'locked' ? faLock : faLockOpen}></FontAwesomeIcon></td>
          })}
        </tr>
      </tbody>
    </Table>

  </>
}

const CabinetSafteyCheck = (props) => {
  const { timeline } = props;
  const selfChecks = timeline && timeline.checks ? timeline.checks : [];
  return <>
    <h4>Cabinet Check</h4>
    <div>
      <ListGroup flush>
        {
          selfChecks.map((check, index) => {
            return <ListGroupItem className="px-0" key={`li_${index}`}>
              <span><strong>{check.query}:</strong> {check.answer} </span>
              {check.comment && <div><i>{check.comment}</i></div>}
            </ListGroupItem>
          })
        }
      </ListGroup>
    </div>
  </>
}

const PurshaseStarted = (props) => {
  const { title, timeline } = props;
  const order = timeline && timeline.order ? timeline.order : {};
  const tranaction = timeline && timeline.transactionId ? { transaction: timeline.transactionId } : {};
  const data = Object.assign({}, order, tranaction);
  return <>
    <h4>{title}</h4>
    <KeyValueTable {...data}></KeyValueTable>
  </>
}

const FulfillmentStarted = (props) => {
  const { title, timeline } = props;
  const tranaction = timeline && timeline.transactionId ? { transaction: timeline.transactionId } : {};
  const data = Object.assign({}, tranaction);
  return <>
    {title && <h4>{title}</h4>}
    <KeyValueTable {...data}></KeyValueTable>
  </>
}

const FulfillmentRejoined = (props) => {
  const { title, timeline } = props;
  const tranaction = timeline && timeline.transactionId ? { transaction: timeline.transactionId } : {};
  const data = Object.assign({}, tranaction);
  return <>
    {title && <h4>{title}</h4>}
    <KeyValueTable {...data}></KeyValueTable>
  </>
}

const FulfillmentFinished = (props) => {
  const { title, timeline } = props;
  const successful = timeline && timeline.successful ? { successful: timeline.successful ? 'yes' : 'no' } : {};
  const tranaction = timeline && timeline.transactionId ? { transaction: timeline.transactionId } : {};
  const data = Object.assign({}, successful, tranaction);
  return <>
    {title && <h4>{title}</h4>}
    <KeyValueTable {...data}></KeyValueTable>
  </>
}

const PaymentFinished = (props) => {
  const { title, timeline } = props;
  const response = timeline && timeline.body ? { response: timeline.body } : {};
  const successful = timeline && timeline.successful ? { successful: timeline.successful ? 'yes' : 'no' } : {};
  const tranaction = timeline && timeline.transactionId ? { transaction: timeline.transactionId } : {};

  const data = Object.assign({}, response, successful, tranaction);
  return <>
    {title && <h4>{title}</h4>}
    <KeyValueTable {...data}></KeyValueTable>
  </>
}

const PaymentStarted = (props) => {
  const { title, timeline } = props;
  const amount = timeline && timeline.amount && timeline.amount.cents && timeline.amount.currency ? { amount: `${timeline.amount.cents / 100} ${timeline.amount.currency}` } : {};
  const status = timeline && timeline.payment && timeline.payment.status ? { status: timeline.payment.status } : {};
  const id = timeline && timeline.payment && timeline.payment.id ? { id: timeline.payment.id } : {};
  const message = timeline && timeline.payment && timeline.payment.message ? { message: timeline.payment.message } : {};
  const transaction = timeline && timeline.transactionId ? { transaction: timeline.transactionId } : {};

  const data = Object.assign({}, amount, status, id, message, transaction);
  return <>
    {title && <h4>{title}</h4>}
    <KeyValueTable {...data}></KeyValueTable>
  </>
}

const CabinetRefillNeeded = (props) => {
  const { title, timeline } = props;
  const shortages = timeline && timeline.shortages ? timeline.shortages : {};
  const items = [];
  Object.keys(shortages).forEach((key) => {
    const item = { 
      article: key, 
      count: shortages[key].hasOwnProperty('count') ? shortages[key].count : '-' ,
      severity: shortages[key].hasOwnProperty('severity') ? shortages[key].severity : '-' 
    }
    items.push(item);
  })
  return <>
    {title && <h4>{title}</h4>}
    <Table size='sm' responsive={true} striped={true}>
      <thead>
        <tr>
          <th>Article</th>
          <th>Count</th>
          <th>Severity</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item,index) => {
          return <tr key={`refill_needed_tr_${index}`}>
            <td>{item.article}</td>
            <td>{item.count}</td>
            <td><Badge color={item.severity}>{item.severity}</Badge></td>
          </tr>
        })}
        <tr>

        </tr>
      </tbody>
    </Table>
  </>
}

const CabinetGasDetected = (props) => {
  const {title,timeline} = props;
  const detector = timeline && timeline.hasOwnProperty('detectorIndex') ? { detector: timeline.detectorIndex } : { detector: 'unknown' };
  const level = timeline && timeline.level ? { level: timeline.level } : { level: 'unknown' };
  const data = Object.assign({}, detector, level);
  return <>
    {title && <h4>{title}</h4>}
    <KeyValueTable {...data}></KeyValueTable>
  </>
}

const TruckServiceReport = (props) => {
  const { title, timeline } = props;
  const type = timeline && timeline.hasOwnProperty('type') ? { type: timeline.type } : { type: '-' };
  const description = timeline && timeline.hasOwnProperty('description') ? timeline.description : '';
  const images = timeline.hasOwnProperty('images') && Array.isArray(timeline.images) ? timeline.images : [];
  const [resources,setResources] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(images && images.length > 0 ? true : false);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  useEffect(() => {
    const getResources = (images) => {
      if(images && images.length > 0)
      {
        const service = new ResourceService(props.apiClient);
        const promises = []
        const resourcesList = [];
        images.forEach((image) => {
          promises.push(service.getResource(`assetto/${image}`));
        })
        Promise.allSettled(promises).then((responses) => {
          responses.forEach((response) => {
            if(response.status === 'fulfilled')
            {
              resourcesList.push((response.value.src));
            }
          })
          setResources(resourcesList);
          setIsLoading(false);
        })
      }
    };
    getResources(images);
    // eslint-disable-next-line
  }, []);

  const openImageViewer = useCallback((index) => {
    setActiveIndex(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setActiveIndex(0);
    setIsViewerOpen(false);
  };

  const data = Object.assign({}, type);
  return <>
    {title && <h4>{title}</h4>}
    <KeyValueTable {...data}></KeyValueTable>
    {description && <div className="py-2"><mark className={'bg-light font-italic'}>"{description}"</mark></div>}
    <div>
    {resources.map((image, index) => {
      return <img alt="notification" className="img-fluid img-thumbnail mr-2 cursor-pointer" onClick={() => openImageViewer(index)} style={{ width: '200px', height: '200px', objectFit: 'cover' }} key={`img_${index}`} src={`${image}`}></img>
    })
    }
    </div>
    <div className="py-2">
    {isLoading && <div className="justify-content-center align-self-center w-100 text-center bg-transparent">
      <Spinner color='primary' />
    </div>}
    {isViewerOpen && ( <ImgViewer visible={isViewerOpen} activeIndex={activeIndex} images={resources} onClose={closeImageViewer}></ImgViewer>)}
    </div>
  </>
}

export default NotificationContent;

const QualifierTypeMappings = {
  "cabinet.inventory.discrepancy": CabinetInventoryDiscrepancy,
  "cabinet.check": CabinetSafteyCheck,
  "invento.cabinet.purchase.started": PurshaseStarted,
  "invento.cabinet.door.alarmed": CabinetAlarm,
  "invento.cabinet.lock.alarmed": CabinetAlarm,
  "invento.cabinet.payment.started": PaymentStarted,
  "invento.cabinet.payment.finished": PaymentFinished,

  "invento.cabinet.refill.needed": CabinetRefillNeeded,
  "invento.cabinet.gas.detected" : CabinetGasDetected,

  "invento.cabinet.fulfillment.started": FulfillmentStarted,
  "invento.cabinet.fulfillment.rejoined": FulfillmentRejoined,
  "invento.cabinet.fulfillment.finished": FulfillmentFinished,

  "assetto.truck.servicereport.front": TruckServiceReport,
  "assetto.truck.servicereport.rear": TruckServiceReport,
  "assetto.truck.servicereport.left": TruckServiceReport,
  "assetto.truck.servicereport.right": TruckServiceReport,
  "assetto.truck.servicereport.engine": TruckServiceReport,
  "assetto.truck.servicereport.tires": TruckServiceReport,
  "assetto.truck.servicereport.electrical": TruckServiceReport,
  "assetto.truck.servicereport.cabin": TruckServiceReport,
  "assetto.truck.servicereport.cooler": TruckServiceReport,
};