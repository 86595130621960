import * as types from "../constants";

import { toggleTheme,insertStyle } from "../../services/themeService";

export function enableClassicTheme() {
  return enableTheme("classic");
}

export function enableCorporateTheme() {
  return enableTheme("corporate");
}

export function enableModernTheme() {
  return enableTheme("modern");
}

export function enableTenantTheme(brand) {
  return enableTheme("tenants/" + brand);
}

export function enableCustomStyle(style) {
  return enableStyle(style);
}

function enableStyle(style) {
  insertStyle(style);

  return {
    type: types.THEME_CUSTOM,
    payload: style
  };
}

function enableTheme(name) {
  toggleTheme(name);

  return {
    type: types.THEME_TOGGLE,
    payload: name
  };
}
