import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useWindowSize } from "../../../utils/windowSize";
export const ModalPanel = (props) => {


  const { isOpen, isBlocked, onDismiss, children, title, borderless } = props;
  const size = props.size ? props.size : '';
  const className = props.className ? props.className : 'mx-3';
  const backdropClassName = props.backdropClassName ? props.backdropClassName : 'bg-white'

  const _toggleSidebar = () => {
    if (onDismiss) {
      onDismiss();
    }
  }

  const _toggle = () => {
    if (isBlocked === true)
      return

    _toggleSidebar();

  }

  const { height } = useWindowSize();
  const panelh = `${height - 80}px`;
  const headerStyle = borderless ? { padding: '20px 0px', borderBottom:0 } : { padding: '20px 0px', boxShadow: '0 0 2rem 0 rgb(55 53 53 / 5%)' }

  return <Modal fade={false} backdropClassName={backdropClassName} className={size} isOpen={isOpen} toggle={() => _toggle()}>
    {isOpen && <div className={`right-panel-wrapper`}>
      <div className={`panel ${isOpen ? 'open' : ''}`}>
        <div className={`panel-content ${size}`}>

          <ModalHeader tag={"h4"} toggle={() => _toggleSidebar()} style={headerStyle} className="px-3">
            {title}
          </ModalHeader>
          <PerfectScrollbar style={{ height: panelh }} options={{ suppressScrollX: true, scrollXMarginOffset: 200 }}>
            <div>

              <ModalBody className="pb-2 px-0 py-0">
                <div className={`${className}`}>
                  {children}
                </div>
              </ModalBody>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>}
  </Modal>
}