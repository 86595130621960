import qs from 'qs';
export class GizmoService {

    constructor(apiClient) {
        this._apiClient = apiClient;
    }
    
    getDevices() {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get('gizmo/devices').then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getDevice(deviceId) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`gizmo/devices/${deviceId}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getDashboardNotifications() {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get('gizmo/devices').then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    addDevice(body, queryObject,) {   
        const queryString = qs.stringify(queryObject, { skipEmptyStrings: true });
        let promise = new Promise((resolve, reject) => {
            let url = `gizmo/devices?${queryString}` 
            this._apiClient.post(url, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {
                reject(undefined);
            });
        })
        return promise;
    }

    reapplyDevice(id, body, queryObject,) {   
        const queryString = qs.stringify(queryObject, { skipEmptyStrings: true });
        let promise = new Promise((resolve, reject) => {
            let url = `gizmo/devices/${encodeURIComponent(id)}?${queryString}` 
            this._apiClient.put(url, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {
                reject(undefined);
            });
        })
        return promise;
    }

    updateDevice (id, requestBody) {
            let promise = new Promise((resolve, reject) => {
                let url = `gizmo/devices/${id}`;
                let body = requestBody;
                this._apiClient.patch(url, body).then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status)
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
            })
            return promise;
        }

    deleteDevice(id)
    {
        let promise = new Promise((resolve, reject) => {
            let url = `gizmo/devices/${id}`;
            this._apiClient.delete(url).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {
                reject(undefined);
            });
        })
        return promise;
    }

    getDeviceProperties(id) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`gizmo/devices/${id}/@/properties`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }


    getDeviceTimeline(id,queryObject = null) {
        const query = queryObject !== null && Object.keys(queryObject).length > 0  ? `?${qs.stringify(queryObject, { skipEmptyStrings: true,allowDots:true, arrayFormat:'comma' })}` : '';
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`gizmo/devices/${id}/@/timeline${query}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    //#region  Device Templates
    getTemplates() {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get('gizmo/device-templates').then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }
    
    getTemplate(id) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`gizmo/device-templates/${id}`).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    addTemplate(id,body) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.put(`gizmo/device-templates/${id}`,body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    updateTemplate(id, body) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.patch(`gizmo/device-templates/${id}`, body).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    deleteTemplate(id) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.delete(`gizmo/device-templates/${id}`).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    //#endregion

    //#region Resources
    getResources = (id, collection) => {
        let url = `gizmo/${collection}/${id}/@/resources`;
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(url).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response.status)
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    addResources(id, collection, data) {
        let promise = new Promise((resolve, reject) => {
            let url = `gizmo/${collection}/${id}/@/resources`;
            let body = data;
            this._apiClient.postMultiPart(url, body)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    deleteResource(id, collection, path) {
        let promise = new Promise((resolve, reject) => {
            let url = `gizmo/${collection}/${id}/@/resources/${path}`;
            this._apiClient.delete(url)
                .then((response) => {
                    if (response.ok)
                        return response.json();
                    else
                        reject(response.status);
                }).then((json) => {
                    resolve(json);
                }).catch((error) => {
                    reject(undefined);
                });
        })
        return promise;
    }

    //#endregion;

    getLastLocations(){
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`gizmo/locations`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }
    
    getLastLocationById(id){
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`gizmo/locations/${id}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    getSignalDefinitions(id) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`gizmo/devices/${id}/signals`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    sendSignal(id, signal, body, delay = 0) {
        const queryString = delay > 0 ? `?delay=${delay}` : ''
        let promise = new Promise((resolve, reject) => {
            this._apiClient.post(`gizmo/devices/${id}/signals/${signal}${queryString}`, body).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    //#region Links
    getLinks(id, params={}) {
        const query = qs.stringify(params, { skipEmptyStrings: true });
        const queryString = query !== '' ? `?${query}` : '';
        let promise = new Promise((resolve, reject) => {
            this._apiClient.get(`gizmo/devices/${id}/links${queryString}`).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }
    
    addLink(id,path,value) {
        let promise = new Promise((resolve, reject) => {
            this._apiClient.put(`gizmo/devices/${id}/links/${path}`,{value:value}).then((response) => {
                if (response.ok)
                    return response.json();
                else {
                    reject(response.status)
                }
            }).then((result) => {
                resolve(result);
            }).catch((ex) => {
                reject(ex);
            })
        });
        return promise;
    }

    deleteLink = (id, path) => {
        let promise = new Promise((resolve, reject) => {
            let url = `gizmo/devices/${id}/links/${path}`;
            this._apiClient.delete(url).then((response) => {
                if (response.ok)
                    return response.json();
                else
                    reject(response)
            }).then((json) => {
                resolve(json);
            }).catch((error) => {
                reject(error);
            });
        })
        return promise;
    }
    //#endregion Links
}