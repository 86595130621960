import React, { useState, useEffect, useContext } from "react";
import Loader from "../components/Loader";
import { PermissionService } from "../services/permissionService";
export const SettingsContext = React.createContext();
export const useSettings = () => useContext(SettingsContext);
export const SettingsProvider = ({
  children,
  ...props
}) => {
  const [settings, setTenantSettings] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const updateSettings = (key, data) => {
    const tempSettings = { ...settings };
    const newSettings = Object.assign({}, tempSettings, { [key]: data });
    setTenantSettings(newSettings)
  }

  useEffect(() => {
    const initSettings = async () => {
      if (props?.apiClient && props?.config) {
        const service = new PermissionService(props.apiClient);
        service.getConfig('config').then((response) => {
          setTenantSettings(response);
          setIsLoading(false);
        }).catch(() => {
          setIsLoading(false);
        })
      }
      else {
        setIsLoading(false);
      }
    };
    initSettings();
  }, [props.apiClient, props.config]);

  return (<SettingsContext.Provider
    value={{
      isLoading,
      settings,
      updateSettings
    }}>
    <>
      {isLoading && <Loader></Loader>}
      {!isLoading && children}
    </>
  </SettingsContext.Provider>
  );
};