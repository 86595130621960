import async from "../components/Async";
const Admin = async(() => import("../tenants/_base/Admin/"));
const AdminAccounts = async(() => import("../tenants/_base/Admin/Account"));
const AdminDevices = async(() => import("../tenants/_base/Admin/Devices"));
const AdminAssets = async(() => import("../tenants/_base/Admin/Assets"));
const AdminAsset = async (() => import("../tenants/_base/Admin/Assets/Asset"))
const AdminDocuments = async(() => import("../tenants/_base/Admin/Documents"));
const AdminBooking = async(() => import("../tenants/_base/Admin/Booking"));
const AdminConfigs = async(() => import("../tenants/_base/Admin/Assets/Configs"));
const AdminNotifications = async(() => import("../tenants/_base/Admin/Notification"));

const routes = [
    {
        hidden:true,
        path: '/admin',
        component : Admin,
    },
     {
        hidden:true,
        path: '/admin/accountmanagement',
        component : AdminAccounts,
      },
      {
        hidden:true,
        path: "/admin/accountmanagement/details",
        component: AdminAccounts
      },
      {
        hidden:true,
        path: "/admin/accountmanagement/users",
        component: AdminAccounts
      },
      {
        hidden:true,
        path: "/admin/accountmanagement/tenants",
        component: AdminAccounts
      },
      {
        hidden:true,
        path: "/admin/accountmanagement/groups",
        component: AdminAccounts
      },
      {
        hidden:true,
        path: "/admin/accountmanagement/roles",
        component: AdminAccounts
      },
      {
        hidden:true,
        path: "/admin/accountmanagement/memberships",
        component: AdminAccounts
      },
      {
        hidden:true,
        path: "/admin/accountmanagement/resources",
        component: AdminAccounts
      },
      {
        hidden:true,
        path: "/admin/devicemanagement",
        name: "Devices",
        component: AdminDevices
      },
      {
        hidden:true,
        path: "/admin/devicemanagement/devices",
        name: "Devicetypes",
        component: AdminDevices
      },
      {
        hidden:true,
        path: "/admin/devicemanagement/templates",
        name: "DeviceTemplates",
        component: AdminDevices
      },
      {
        hidden:true,
        path: "/admin/assetsmanagement",
        name: "Assets",
        component: AdminAssets
      },
      {
        hidden:true,
        path: "/admin/assetsmanagement/:collection",
        name: "Assets",
        component: AdminAssets
      },
      {
        hidden:true,
        path: "/admin/assetsmanagement/:collection/:id",
        name: "Assets",
        component: AdminAsset
      },
      {
        hidden:true,
        path: "/admin/documentmanagement",
        name: "Documents",
        component: AdminDocuments
      },
      {
        hidden:true,
        path: "/admin/documentmanagement/structures",
        name: "Structures",
        component: AdminDocuments
      },
      {
        hidden:true,
        path: "/admin/documentmanagement/documents",
        name: "Structures",
        component: AdminDocuments
      },
      {
        hidden:true,
        path: "/admin/bookingmanagement",
        name: "Booking",
        component: AdminBooking
      },
      {
        hidden:true,
        path: "/admin/bookingmanagement/pools",
        name: "Structures",
        component: AdminBooking
      },
      {
        hidden:true,
        path: "/admin/bookingmanagement/resources",
        name: "Structures",
        component: AdminBooking
      },
      {
        hidden:true,
        path: "/admin/configs",
        name: "Templates",
        component: AdminConfigs
      },
      {
        hidden:true,
        path: "/admin/configs/:collection",
        name: "Configure",
        component: AdminConfigs
      },
      {
        hidden:true,
        path: "/admin/notificationmanagement",
        name: "Notifications",
        component: AdminNotifications
      },
      {
        hidden:true,
        path: "/admin/notificationmanagement/channels",
        name: "Notifications",
        component: AdminNotifications
      },
]

export const adminRoutes = routes;  
export default adminRoutes;
  
