import React from 'react';
import { Row, Col, Button, Label, Nav, NavItem, NavLink, Card, Table, CardTitle } from 'reactstrap';
import { DominoService } from '../../../../../../services/dominoService';
import Loader from '../../../../../../components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorBoundary } from '../../../../../../components/Youmoni/ErrorBoundary';
import { faEdit, faEnvelope, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import Toastr from '../../../../../../components/Youmoni/Toastr';
import AccessControl, { ACTION, MATCHER, hasAccess } from '../../../../../../utils/accessControl';
import { Link } from 'react-router-dom';

import EditMembership from './EditMembership';
import { ModalPanel } from '../../../../../../components/Youmoni/Panels/ModalPanel';

const MembershipMenu = (props) => {

    const {facets, onResend, onDelete, onEdit } = props

    const _onResend = () => {

        if (onResend) {
            onResend()
        }
    }

    const _onDelete = () => {
        if (onDelete) {
            onDelete()
        }
    }

    const _onEdit = () => {
        if (onEdit) {
            onEdit()
        }
    }

    return <>
        <Nav className='bg-light px-0 nav-buttons'>
            {onResend && hasAccess('memberships', [ACTION.UPDATE], facets, MATCHER.END) && <NavItem>
                <NavLink onClick={() => { _onResend() }}>
                    <FontAwesomeIcon className="mr-2" icon={faEnvelope}></FontAwesomeIcon> Resend
                </NavLink>
            </NavItem>}
            {
                onEdit && hasAccess('memberships', [ACTION.UPDATE], facets, MATCHER.ANY) && <NavItem>
                    <NavLink onClick={(e) => { _onEdit() }}>
                        <FontAwesomeIcon className="mr-2" icon={faEdit}></FontAwesomeIcon> Edit
                    </NavLink>
                </NavItem>}
            {onDelete && hasAccess('memberships', [ACTION.DELETE], facets, MATCHER.END) && <NavItem>
                <NavLink onClick={(e) => { _onDelete() }}>
                    <FontAwesomeIcon className="mr-2" icon={faTrashAlt}></FontAwesomeIcon> Delete
                </NavLink>
            </NavItem>}
        </Nav>
    </>
}


const EditPanel = (props) => {
    
    return <ModalPanel {...props} title={'Edit Membership'} backdropClassName={'bg-transparent'} size={'md shadow-none'}>
        <EditMembership {...props}></EditMembership>
    </ModalPanel>
}

export class DisplayMembership extends React.Component {
    constructor(props) {
        super();
        this.state = {
            isLoading:true,
            data : undefined,
        }

        this._dominoService = new DominoService(props.apiClient);
    }

    componentDidMount() {
        const {data} = this.props;
        if(data?.userUid)
        {
            this._dominoService.getMembership(data?.userUid).then((response) => {
                this.setState({data:response,isLoading:false})
            }).catch((error) => {
                Toastr('error','Something went wrong', 'Could not fetch the membership')
                this.setState({data:undefined,isLoading:false})
            })
        }
    }


    _onCancel = () => {
        const { onDismiss } = this.props;
        if (onDismiss) {
            onDismiss()
        }
    }

    _onDelete = () => {
        const { onDelete } = this.props;
        const {data} = this.state;
        if (onDelete) {
            onDelete(data);
        }
    }

    _onEdit = () => {
        this.setState({isEditPanelOpen:true});
    }

    _onUpdated = (data) => {
        this.setState({isEditPanelOpen:false});
    }

    render() {
        const {tenant,facets, location} = this.props;

        const { data, isLoading, isEditPanelOpen } = this.state;
        const id = data?.userUid;
        const fields = [
            { key: 'userUid', label: "Id", formatter: (value) => { return <span>{value}</span> } },
            { key: 'accountId', label: "Account", formatter: (value) => { return <span>{value}</span> } },
            { key: 'active', label: "Active", formatter: (value) => { return <span>{value === true ? 'Yes' : 'No'}</span> } },
            { key: 'firstName', label: "Firstname", formatter: (value) => { return <span>{value}</span> } },
            { key: 'lastName', label: "Lastname", formatter: (value) => { return <span>{value}</span> } },
            { key: 'principal', label: "Principal", formatter: (value) => { return <span>{value}</span> } },
            
            { key: 'status', label: "Status", formatter: (value) => { return <>
            {value.hasOwnProperty('type') && <>
            <div className='text-capitalize'>{value?.type}</div>
            <div> Token:  {value?.token} </div>            
            </>}
            {!value.hasOwnProperty('type') && <>{value}</>}
            </> } },
            { key: 'assignments', label: "Assignments", formatter: (value) => { 
                const assignments = Array.isArray(value) ? value : []

                const assignmentText = (a) => {
                    const role =   a?.yri.match(/yri:\/\/(.+)@.+/)?.[1]
                    const asset = a?.yri.replace(/yri:\/\/.*?\/.*?/, '')?.replace(/-/g, ' ')?.replace(/\//, ' / ')
                    const name = a.name ? a.name :  `${role} @ ${asset}`
                    return <span className='text-capitalize'>{name}</span>
                }

                return assignments.map((d,index) => {
                    const regExp = new RegExp(`yri://.+@${tenant}.assetto/`);
                    const url = regExp.test(d.yri) && !d.yri?.endsWith(`/root/${tenant}`) ? d.yri.replace(regExp, '') : undefined;
                    const facet = url !== undefined ? `admin.assetsmanagement` : undefined
                    return <div key={`i_${index}`}>
                        <AccessControl
                            facet={facet}
                            facets={facets}
                            actions={ACTION.ANY}
                            matcher={MATCHER.START}
                            deny={() => (<>
                            {assignmentText(d)}
                            </>)}>
                                {url !== undefined && location.pathname !== `/admin/assetsmanagement/${url}` && <Link to={`/admin/assetsmanagement/${url}`}>{assignmentText(d)}</Link>}
                                {(url === undefined  || location.pathname === `/admin/assetsmanagement/${url}`) && <>{assignmentText(d)}</>}
                        </AccessControl>
                    </div>
                })
                } 
            },
            { key: 'createdIso', label: "Created", formatter: (value) => { return <span>{value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''}</span> } },
            { key: 'updatedIso', label: "Modified", formatter: (value) => { return <span>{value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''}</span> } },
        ]

        
        return (<div>
            {isLoading && <Loader></Loader>}
            <Row className='bg-light border-top border-bottom'>
                <Col className='px-0'>
                <MembershipMenu {...this.props} onEdit={() => this._onEdit()} onDelete={() => this._onDelete()} onClose={() => this._onCancel()}></MembershipMenu>
            </Col>
            </Row>
            <ErrorBoundary>
                <Card body className='border mt-3 mb-2 overflow-hidden'>
                    <CardTitle>{data?.principal}</CardTitle>
                   {data && <Table size='sm' striped={true} className={'overflow-hidden'}>
                        <tbody>
                            {
                                fields.map((field, index) => {
                                    return <tr key={`${index}`}>
                                        <td>
                                            <Row>
                                                <Col className='mb-0 mr-auto' ><Label className='mb-0'> <strong className='text-capitalize'>{field.label}</strong></Label></Col>
                                                <Col>{field?.formatter(data[field.key])}</Col>
                                            </Row>
                                        </td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>}
                </Card>
            </ErrorBoundary>
            <Row>
                <Col sm="12" className={'text-right'}>
                    <Button onClick={() => this._onCancel()}>Close</Button>
                </Col>
            </Row>
            {isEditPanelOpen && <EditPanel {...this.props} id={id} isOpen={isEditPanelOpen} onSuccess={(data) => this._onUpdated(data)} onDismiss={() => this.setState({ isEditPanelOpen: false })}></EditPanel>}

        </div>)
    }
}

export default DisplayMembership;