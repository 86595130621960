import React from 'react';
import { NavItem,NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Settings as SettingsIcon } from "react-feather";

const AdminNav = () => {

    return (
        <NavItem className="d-inline-block d-md-none">
        <NavLink  className={"mx-2 pt-2 pb-2"} tag={Link} to="/admin">
        <SettingsIcon />
        </NavLink>
      </NavItem>
    )
}

export default AdminNav;