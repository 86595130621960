import React from 'react';
import Toastr from '../../../../components/Youmoni/Toastr';
import { AssettoService } from '../../../../services/assettoService';
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Loader from '../../../../components/Loader'
import ErrorBoundary from '../../../../components/Youmoni/ErrorBoundary';
import TableComponent from '../../../../components/Youmoni/DataTable/TableComponent';
import { ThumbImg } from '../../../../components/Youmoni/Img';
import { faEllipsisH, faPlus } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledDropdown } from 'reactstrap/lib';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash'

const _ = {
    isEqual: isEqual
}

const columns = [
    { accessor: 'alias', Header: 'Id' },
    { accessor: 'name', Header: 'Name' }
]

const MainImage = (props) => {
    const url = props && props.src !== undefined ? `assetto/${props.src}` : undefined;
    return <div style={{ textAlign: 'center' }}><ThumbImg {...props} className="img-fluid" src={url} alt='alt'></ThumbImg></div>
}

export class AddAssetLinks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            tableData: [],
            tableColumns: [],
        }

        this._assettoService = new AssettoService(props.apiClient, props.collection);
    }
    componentDidMount() {
        this._initComponent();
    }

    componentDidUpdate(prevProps) {

        if (this.props?.assetIds && !_.isEqual(prevProps?.assetIds, this.props.assetIds)) {
            const { tableData } = this.state;
            const tempData = [...tableData]
            const ids = Array.isArray(this.props.assetIds) ? this.props.assetIds : [];
            const newTableData =
                this.setState((prevState) => {
                    return {
                        ...prevState,
                        tableData: tempData.filter((asset) => ids.indexOf(asset.alias) === -1)
                    }
                })
        }
    }

    _initComponent = () => {
        const { onAdd, apiClient, assetIds } = this.props;
        this._assettoService.getAssets({contextual:false}).then((response) => {
            const entities = Array.isArray(response.entities) ? response.entities : [];

            const tableColumns = [...columns];
            tableColumns.unshift({
                disableSortBy: true,
                accessor: 'mainImage',
                Header: 'Image',
                style: { width: 60 },
                Cell: ({ value }) =>
                    <>
                        <MainImage apiClient={apiClient} src={value} />
                    </>
            });

            tableColumns.push({
                accessor: 'controls', headerClassName: 'd-none', disableSortBy: true, Cell: ({ row }) => {
                    const { original } = row;

                    return <div style={{ textAlign: "right" }} >
                        <UncontrolledDropdown className='d-md-none float-right'>
                            <DropdownToggle size='sm' color='link' className='shadow-none' onClick={(e) => e.stopPropagation()}><FontAwesomeIcon icon={faEllipsisH}></FontAwesomeIcon></DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem onClick={(e) => { this._onSelectRow(original); e.stopPropagation() }}><FontAwesomeIcon className='mr-2' icon={faPlus} />Add</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <ButtonGroup>
                            <Button className="mr-2 rounded d-none d-md-inline" outline={true} color={'primary'} onClick={(e) => { e.stopPropagation(); this._onSelectRow(original) }}>
                                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                            </Button>
                        </ButtonGroup>
                    </div>
                }
            });

            const ids = Array.isArray(assetIds) ? assetIds : [];
            const tableData = entities.filter((asset) => ids.indexOf(asset.alias) === -1);
            this.setState({ tableData: tableData, isLoading: false, tableColumns: tableColumns })
        }).catch((error) => {
            console.log(error);
            this.setState({ isLoading: false, tableColumns: columns });
            Toastr('error', 'Something went wrong', 'Please try again later.')
        })
    }


    _onSelectRow = (row) => {
        const { onSelect } = this.props;
        if (onSelect) {
            onSelect(row)
        }
    }
    render() {
        const { isLoading, tableColumns, tableData } = this.state;
        return <>
            {isLoading && <Loader></Loader>}

            <ErrorBoundary>
                <>
                    {
                        tableColumns && tableColumns.length > 0 &&
                        <TableComponent
                            title=""
                            hasSearch={true}
                            columns={tableColumns}
                            rowEvents={{ onClick: (row) => { } }}
                            data={tableData}>
                        </TableComponent>
                    }
                </>
            </ErrorBoundary>
        </>
    }
}
export default AddAssetLinks;