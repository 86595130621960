import * as _ from 'lodash';
export class ComponentPropsHelper {
    map = (props) => {

        const fields = []
            fields.push( "Positional Longitude" );
            fields.push("Positional Latitude" );

        return { fields: fields }
    }

    sensors = (props) => {
        const { settings } = props;
        const collection = getCollectionFromProps(props);
        const key = `${collection}.graphs.assetinfo`;
        const charts = getObjectValue(settings, key, []);
        return { charts: charts };
    }

    engine = (props) => {
        const { settings } = props;
        const collection = getCollectionFromProps(props);
        const key = `${collection}.graphs.engineinfo`;
        const charts = getObjectValue(settings, key, []);
        return { charts: charts };
    }

    timeline = (props) => {
        return { params: {} };
    }

    servicetimeline = (props) => {
        return { params: {} };
    }

    info = (props) => {
        return { params: {} };
    }

    notifications = (props) => {
        return { params: { status: 'pending' } };
    }

    deviceinfo = (props) => {
        const { settings } = props;
        const collection = getCollectionFromProps(props);
        const key = `${collection}.graphs.deviceinfo`;
        const charts = getObjectValue(settings, key, []);
        return { charts: charts };
    }
}

const getObjectValue = (obj, key, defaultValue) => {
    let everyObj = obj;
    const keys = key.split('.');
    const exists = _.every(keys, (key) => {
        if (everyObj && everyObj.hasOwnProperty(key)) {
            if(everyObj === null || everyObj === undefined)
            {
                return false;
            }

            everyObj = Array.isArray(everyObj[key]) ? [...everyObj[key]] : { ...everyObj[key] }
            return true;
        }
        return false;
    })
    return exists ? everyObj : defaultValue
}

const getCollectionFromProps = (props) => {
    const { match } = props;
    const { params } = match ? match : {};
    const { collection } = params ? params : {}
    return collection;
}