import React from "react";
import { Col, Row, Table, Container, Navbar } from "reactstrap";
import { find, filter, chunk, orderBy, findIndex } from 'lodash'
import { DominoService } from "../../../services/dominoService";
import ErrorBoundary from "../../../components/Youmoni/ErrorBoundary";
import { Loader } from "react-feather";

const _ = {
    find: find,
    filter: filter,
    chunk: chunk,
    orderBy: orderBy,
    findIndex: findIndex
}

class Logo extends React.Component {
    constructor(props) {
        super(props)
        this.state = { path: undefined }
    }

    componentDidMount() {
        const { tenant, landlord } = this.props;
        const promises = []
        if (tenant) {
            promises.push(this._getTenantLogo(`/logos/${tenant}.png`));
        }

        if (landlord) {
            promises.push(this._getTenantLogo(`/logos/${landlord}.png`));
        }

        Promise.allSettled(promises).then(([tenantResponse, landlordResponse]) => {
            const isTennatSuccessfull = tenantResponse && tenantResponse.status === 'fulfilled';

            const isLandlordSuccessFull = landlordResponse && landlordResponse.status === 'fulfilled';
            const path = isTennatSuccessfull ? tenantResponse.value.path : isLandlordSuccessFull ? landlordResponse.value.path : undefined;
            if (path) {
                this.setState({ path: path });
            }
        });
    }

    _getTenantLogo = (path) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve({ path, ok: true })
            };
            img.onerror = () => {
                reject({ path, ok: false });
            }
            img.src = path;
        });
    }

    render() {
        const { path } = this.state;
        return <>
            {path && <div style={{ height: '40px', width: '100px', backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', backgroundImage: `url(${path})` }}></div>}
        </>


    }
}

export class Screen extends React.Component {
    constructor(props) {
        super(props)
        this.state = { items: [], name: '',isLoading:true }
        this._dominoService = new DominoService(props?.apiClient)
    }


    componentDidMount() {
        const { location, apiClient } = this.props;
        const tenant = apiClient?._tenant;
        const yri = `yri://owner@${tenant}.assetto/apartments/`

        const params = new URLSearchParams(location?.search)
        const id = params.get('id');
        const name = params.get('name');
        const columns = params.get('columns');

        if(id !== null )
        {
            this._dominoService.getMemberships().then((response) => {
                const entities = Array.isArray(response?.entities) ? response?.entities : []
                const resources = response?.resources || {};
                const ownerEntities = _.filter(entities, (entity) => _.findIndex(entity?.assignments, (item) => item?.yri?.startsWith(yri)) > -1) 

                const data = ownerEntities.map((item) => {
                    const assignment = _.find(item.assignments, item => item?.yri?.startsWith(`yri://owner@${tenant}.assetto/apartments/`))
                    const resource = assignment?.resource?.replace('#assignment', '');
                    const apartment = resources?.[resource];
                    return { fullName: `${item?.firstName} ${item?.lastName}`, apartmentNumber: apartment?.apartmentNumber, entrance: apartment?.entrance?.replace('#navigation', '') };
                })
    
                const filteredData = _.filter(data, (item) => item?.entrance === `yri://${tenant}.assetto/entrances/${id}`)
                const items = _.orderBy(filteredData, 'apartmentNumber')
                this.setState({ items: items, name: name, columns: columns, isLoading:false })
    
            }).catch((error) => {
                console.log('Failed Request')
                this.setState({isLoading:false });
            })
        }
        else{
            this.setState({isLoading:false });
        }
    }

    render() {
        const { location, tenant } = this.props;
        const params = new URLSearchParams(location?.search);
        const name = params.get('name');
        const columns = params.get('columns') || 4;
        const thead = params.get('th') === "true";
        const logo = params.get('logo') === "true";

        const { isLoading, items } = this.state;
        const chunks = _.chunk(items, Math.ceil(items.length / columns));

        return <section className="main w-100 bg-white">
            {isLoading && <Loader></Loader>}
            <ErrorBoundary>

                {(name || logo) && tenant && <Navbar color="white" light>
                    <div className="display-inline">{logo && <Logo {...this.props}></Logo>}</div>
                    {name && <h3 className="display-inline text-dark mb-0">{name}</h3>}

                </Navbar>}
                <Container fluid className="bg-white pt-3">
                    <Row>
                        {chunks?.map((chunk, index) => {
                            return <Col key={`${index}`} className="bg-white">
                                <Table size="sm" striped={true}>
                                    {thead && <thead>
                                        <tr>
                                            <th>Namn</th>
                                            <th>Lägenhet</th>
                                        </tr>
                                    </thead>}
                                    <tbody>
                                        {chunk.map((item, i) => {
                                            return <tr key={`tr${i}`}>
                                                <td>{item?.fullName}</td>
                                                <td>{item?.apartmentNumber}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        })}
                    </Row>
                </Container>
            </ErrorBoundary>
        </section>
    }
}

export default Screen;