// Route Components
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";
import Landing from "../tenants/_base/Landing/Landing";
import Invite from '../tenants/_base/Landing/Invite';
import Screen from '../tenants/_base/Landing/Screen';

const landingRoutes = [{
  path: "/",
  name: "Start",
  component: Landing,
},
{
  path: "/invite",
  name: "Invite",
  component: Invite,
}
];

const authRoutes = {
  path: "/auth",
  name: "Auth",
  badgeColor: "secondary",
  badgeText: "12/24",
  children: [
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

// Landing specific routes
export const landing = landingRoutes;

export const tempRoutes = [{
  path: "/screen",
  name: "Screen",
  component: Screen,
}]

// Auth specific routes
export const auth = [authRoutes];

